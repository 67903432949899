import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import IconComponent from "./IconComponent";
import Dot from "@/common/images/icons/dot.svg";
import HorizontalLine from "@/common/images/icons/horizontal-line.svg";
import "./bottom_navbar.scss";

const BottomNavBar = () => {
    const { progress, steps, status, error } = useSelector(
        (state) => state.progress
    );

    const isLoading = useMemo(
        () => status == "pending" || status == "idle",
        [status]
    );

    return (
        <aside id="bottomNavBar" className="scrollable">
            {isLoading ? (
                <div
                    className="loading-spinner-container"
                    style={{ margin: "1rem" }}
                >
                    <div className="loader"></div>
                </div>
            ) : (
                <div className="steps-container">
                    {/* TODO: Replace with react-router NavLink*/}
                    {steps.map((step, index) => (
                        <div key={step.id} className="step-container">
                            {step.react ? (
                                <NavLink
                                    to={step.path}
                                    className={`step ${step.status}`}
                                    onClick={(e) =>
                                        step.status === "locked" &&
                                        e.preventDefault()
                                    }
                                >
                                    <IconComponent
                                        iconKey={step.id}
                                        iconSize={18}
                                    />
                                    <Dot />
                                </NavLink>
                            ) : (
                                <a
                                    href={step.path}
                                    className={`step ${step.status}`}
                                >
                                    <IconComponent
                                        iconKey={step.id}
                                        iconSize={18}
                                    />

                                    <Dot className={`${step.status}`} />
                                </a>
                            )}
                            {index + 1 !== steps.length && (
                                <div className="horizontal-line-wrapper">
                                    <HorizontalLine />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </aside>
    );
};

export default BottomNavBar;
