import React, { useState } from "react";
import Eye from "@/common/images/icons/eye.svg";
import EyeSlash from "@/common/images/icons/eyeslash.svg";
import "./hiddenfield.scss";

const HiddenField = ({ fieldProps, isError }) => {
    const [showField, setShowField] = useState(false);

    return (
        <div className="hidden-field">
            <input
                {...fieldProps}
                type={showField && fieldProps.name !== "confirmPassword" ? "text" : "password"}
                className={isError ? "error" : ""}
            />
            {fieldProps.name !== "confirmPassword" && (
                <div className="float-button">
                    <button type="button" onClick={() => setShowField(!showField)}>
                        {showField ? (
                            <Eye width={24} height={24} />
                        ) : (
                            <EyeSlash width={24} height={24} />
                        )}
                    </button>
                </div>
            )}
        </div>
    );
};

export default HiddenField;