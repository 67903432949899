import { replaceSpecialChars } from "@/common/utils/helpers";
import { getCookie } from "@/common/utils/cookies";

export function createConditionPage(pageNumber, conditions) {
    `Function that helps with Initializing the first set of questions for the Add Conditions Survey
    Args: 
        pageNumber(int): a pageNumber dictated by how many conditions the client has already provided recently
        conditions([str]): a list of all conditions available, grabbed from Django backend API
    Returns:
        a Survey Model object used to instantiate the first page/panel of the Add Conditions Survey. 3 questions, Disability name, pain scale 1-10 and the impact it has on the client
        `;
    return {
        name: `Page${pageNumber}`,
        elements: [
            {
                type: "panel",
                name: `panel${pageNumber}`,
                elements: [
                    {
                        type: "dropdown",
                        name: `Condition #${pageNumber} Name`,
                        title: `Condition #${pageNumber} Name`,
                        isRequired: true,
                        choices: conditions,
                    },
                    {
                        type: "rating",
                        name: `Condition #${pageNumber} Pain Scale`,
                        title: `Condition #${pageNumber} Pain Scale`,
                        rateCount: 10,
                        rateMax: 10,
                        rateType: "labels",
                        isRequired: true,
                        displayMode: "buttons",
                    },
                    {
                        type: "comment",
                        name: `Condition #${pageNumber} Impact`,
                        title: `Condition #${pageNumber} Impact`,
                        description:
                            "Please share the ways in which this condition impacts your everyday life, such as work, social situations, mental and physical health issues and etc.",
                        isRequired: true,
                    },
                ],
            },
        ],
    };
}

export function structureSurveyJson(index, conditions) {
    `Helps structure the initial survey model. Sets title and other properties needed throughout the Survey like the preview page
    
    Args: 
        index(int): Disctates what condition number we are on. If client already provided on, then this is dedicate by the offSet variable
        conditions([str]): a list of all conditions available, grabbed from Django backend API
    
    Returns:
        a Survey Model object used to instantiate the entire Add Conditions Survey.

    `;

    let surveyData = {};
    surveyData.title = `Adding conditions`;
    surveyData.pages = [];
    let newPage = createConditionPage(index, conditions);
    surveyData.pages.push(newPage);
    surveyData.previewText = `Review Conditions`;
    surveyData.showPreviewBeforeComplete = "showAnsweredQuestions";
    surveyData.showPageNumbers = true;
    surveyData.widthMode = "responsive";
    return surveyData;
}

export function completeSurvey(sender) {
    `Function ran to complete survey and save results. Hits Django backend to save results
    
    Args: 
        Sender(survey): the survey itself so we can grab the data being saved and send to the back end
    Returns: 
        Promise: resolves with the response data on success, rejects with an error on failure

    `;
    //Remove special characters from the saved data
    const dataJSON = replaceSpecialChars(JSON.stringify(sender.data));
    const formData = new FormData();
    formData.append("csrfmiddlewaretoken", getCookie("csrftoken"));
    formData.append("resultData", dataJSON);
    //Sends to Django backend endpoint
    fetch(`/condition_exam/add_conditions/`, {
        method: "POST",
        body: formData,
        // fetch automatically sets 'Content-Type' to 'multipart/form-data' when using FormData
    })
        .then((response) => {
            if (!response.ok) {
                // If response is not OK, throw an error
                throw new Error("Network response was not ok");
            }
            return response.json(); // Parse JSON data from response
        })
        .then((data) => {
            //Handle errors
            if (data.error) {
                document.getElementById("result-container").innerHTML = `
              <h3>❌ Unable to create conditions.</h3>
              <h6 class="m-5">${data.error}</h6>
              <input class="sd-btn sd-btn--action sd-navigation__complete-btn" value="Go Back" title="Go Back" onclick="location.reload()" style="max-width: 170px" />
          `;
                //Redirect if successful
            } else {
                window.location.replace(`/condition_exam/`);
            }
        })
        .catch((error) => {
            alert("DATA NOT SAVED");
        });
}
