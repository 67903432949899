import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    getConditionExamDashboard,
    saveConditionExam,
} from "../../features/condition_exam/conditionExamSlice";
import MainUnlock from "../../components/MainUnlock";
import "./condition_exam.scss";

const ConditionExams = () => {
    const dispatch = useDispatch();
    const [showAddEditCondition, setShowAddEditCondition] = useState(false);

    const { exam_dashboard, status, error } = useSelector(
        (state) => state.conditionExam
    );

    useEffect(() => {
        dispatch(getConditionExamDashboard());
    }, [dispatch]);

    if (error) {
        return <div>Error: {error}</div>;
    }
    if (status === "pending" || !exam_dashboard) {
        return (
            <div className="loading-spinner-container">
                <div
                    style={{
                        height: "80px",
                        width: "80px",
                        borderWidth: "8px",
                    }}
                    className="loader"
                ></div>
            </div>
        );
    }

    if (exam_dashboard.status === "questionnaire_complete") {
        return (
            <MainUnlock
                step_title={"Great News!"}
                step_btn_text={"Next"}
                message={
                    "Your condition information has been saved. Please click “Next” to continue with the process and schedule an appointment."
                }
                btn_url={exam_dashboard.next_url}
            />
        );
    }

    if (exam_dashboard.status === "unlocked") {
        return (
            <MainUnlock
                step_title={"Conditions Self-Assessment"}
                step_btn_text={"Add Conditions"}
                message={
                    "Before your first condition exam with a medical provider, we will need to collect some information about your medical conditions. This information will be shared with your Doctor prior to your exam so they may better assist you with the conditions you provide. Please be as honest as possible when sharing any details so that your doctor can accurately assess your condition"
                }
                btn_url={"/condition_exam/add_conditions/"}
            />
        );
    }

    return (
        <div id="condition-exam-dashboard">
            <Link
                to="/condition_exam/condition-history/"
                className="link-condition-history"
            >
                <p>Condition History</p>
            </Link>

            <h2 className="blue-txt">Conditions</h2>
            {exam_dashboard.conditions.length > 0 ? (
                <>
                    {showAddEditCondition ? (
                        <div>
                            <h6 className="gray-txt">
                                Do you want to add new conditions or edit
                                current conditions?
                            </h6>
                            <div className="btn-container">
                                <Link
                                    to="/condition_exam/add_conditions"
                                    className="btn primary"
                                >
                                    Add New Conditions
                                </Link>
                                <Link
                                    to="/condition_exam/current_conditions"
                                    className="btn primary"
                                >
                                    {exam_dashboard.condition_exam_info
                                        .conditions_reviewed
                                        ? "Review Current Conditions"
                                        : "Edit Current Conditions"}
                                </Link>
                            </div>
                        </div>
                    ) : !exam_dashboard.condition_exam_info
                          .conditions_reviewed ? (
                        <div>
                            <h6 className="gray-txt">
                                Are these the conditions you want to discuss
                                with your Doctor?
                            </h6>
                            <div className="btn-container">
                                <button
                                    className="btn primary"
                                    onClick={() =>
                                        dispatch(saveConditionExam())
                                    }
                                    disabled={
                                        status === "pendingSaveConditionExam"
                                    }
                                >
                                    {status === "pendingSaveConditionExam" ? (
                                        <div className="loader"></div>
                                    ) : (
                                        "Yes"
                                    )}
                                </button>
                                <button
                                    className="btn primary"
                                    disabled={
                                        status === "pendingSaveConditionExam"
                                    }
                                    onClick={() =>
                                        setShowAddEditCondition(true)
                                    }
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    ) : null}

                    <p className="text-center">
                        This information is needed for condition exam number
                        {exam_dashboard.condition_exam_info.exam_one_complete
                            ? exam_dashboard.condition_exam_info
                                  .exam_two_complete
                                ? " three "
                                : " two "
                            : " one "}
                        of your three condition exams
                    </p>

                    <div className="card-list-container">
                        {exam_dashboard.conditions.map((cond, index) => (
                            <div key={index} className="condition-card">
                                <div className="condition-card-header">
                                    <CardSvg />
                                    <p className="pain-scale font-2xl">
                                        Pain: {cond.pain_scale}/10
                                    </p>
                                </div>
                                <p className="font-2xl clamp-txt">
                                    {cond.name}
                                </p>
                                <p>{cond.impact}</p>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <>
                    <div className="page-btn-container">
                        <Link
                            to="/condition_exam/add_conditions"
                            className="btn primary"
                        >
                            Add New Conditions
                        </Link>
                    </div>
                    <p className="gray-txt">No Conditions Exist Yet</p>
                </>
            )}
        </div>
    );
};

const CardSvg = () => {
    return (
        <svg
            viewBox="0 0 24 24"
            id="medical-record"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="5"
                y="3"
                width="14"
                height="18"
                rx="1"
                style={{
                    fill: "none",
                    stroke: "#000000",
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "2",
                }}
            />
            <path
                d="M9,6a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V3H9Zm3,10V12m2,2H10"
                style={{
                    fill: "none",
                    stroke: "#2ca9bc",
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "2",
                }}
            />
        </svg>
    );
};

export default ConditionExams;
