import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { cachedAxiosInstance } from "@/common/utils/axios";

export const getDisabilities = createAsyncThunk(
    "products/get_condition_names",
    async (_, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.get(
                "/products/api/get_conditions/"
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.error?.details ||
                        "Failed to get user conditions."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const disabilitySlice = createSlice({
    name: "product_disabilities",
    initialState: {
        disabilities: null,
        status: "idle",
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDisabilities.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getDisabilities.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.disabilities = action.payload ? action.payload : null;
                state.error = null;
            })
            .addCase(getDisabilities.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            });
    },
});

export default disabilitySlice.reducer;
