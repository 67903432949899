import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { cachedAxiosInstance } from "@/common/utils/axios";

export const getTicketDetails = createAsyncThunk(
    "products/refunds/tickets/details",
    async (id, thunkAPI) => {
        try {
            // TODO: add filter by status
            const response = await cachedAxiosInstance.get(
                `/products/api/refunds/tickets/${id}/`
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to get ticket details."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const ticketDetailSlice = createSlice({
    name: "ticket_details",
    initialState: {
        ticket: null,
        status: "idle",
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTicketDetails.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getTicketDetails.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.ticket = action.payload;
                state.error = null;
            })
            .addCase(getTicketDetails.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            });
    },
});

export default ticketDetailSlice.reducer;
