import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    getItfDocumentData,
    handleError,
    submitItfForm,
} from "../../features/itf/itfSlice";
import "./ITFSubmit.scss";
import { processITF } from "../../utils/generate_itf";
import Alert from "../../../common/components/Alert";
import { replaceSpecialChars } from "../../../common/utils/helpers";
import useAlert from "../../hooks/useAlert";
import Spinner from "../../components/Spinner";
import ITFSkipModal from "./ITFSkipModal";

const SubmitITF = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { currentData, isOptional, status, redirect } = useSelector(
        (state) => state.itf
    );
    const [step, setStep] = useState(1);
    const { alertType, alertMessage, alertVisible, showAlert } = useAlert();
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [isSkipModalOpen, setIsSkipModalOpen] = useState(false);

    useEffect(() => {
        dispatch(submitItfForm());
    }, []);

    useEffect(() => {
        if (redirect) {
            // Download if ITF doesn't exist, navigate to /itf/
            navigate(redirect);
        }
    }, [redirect]);

    const handleDownload = () => {
        setDownloadLoading(true);
        dispatch(getItfDocumentData(currentData.itf_id))
            .then(async (res) => {
                const data = res.payload;
                const success = await processITF({
                    pdfUrl: data.fileUrl,
                    data:
                        data.results_data &&
                        replaceSpecialChars(JSON.stringify(data.results_data)),
                    type: "download",
                });
                if (success) {
                    showAlert({
                        alertType: "info",
                        alertMessage:
                            "ITF Document has been downloaded successfully",
                    });
                }
            })
            .catch((error) => {
                showAlert({
                    alertType: "error",
                    alertMessage: handleError(error, "Error downloading ITF"),
                });
            })
            .finally(() => {
                setDownloadLoading(false);
            });
    };

    const handleUpload = () => {
        setUploadLoading(true);

        dispatch(getItfDocumentData(currentData.itf_id))
            .then(async (res) => {
                const data = res.payload;
                const result = await processITF({
                    pdfUrl: data.fileUrl,
                    data:
                        data.results_data &&
                        replaceSpecialChars(JSON.stringify(data.results_data)),
                    type: "upload",
                });
                if (result.error) {
                    throw new Error(result.error);
                }
                if (!result.error) {
                    showAlert({
                        alertType: "info",
                        alertMessage:
                            "ITF Document has been uploaded successfully",
                    });
                    navigate("/itf/success/");
                }
            })
            .catch((error) => {
                showAlert({
                    alertType: "error",
                    alertMessage: handleError(error, "Error uploading ITF"),
                });
            })
            .finally(() => {
                setUploadLoading(false);
            });
    };

    if (status === "loading") {
        return <Spinner />;
    }

    if (status === "failed" && error) {
        return <div>Error: {error}</div>;
    }
    const showDoLater = status === "loading" ? true : isOptional;

    return (
        <div id="itf-submit">
            <div className="content-container">
                {alertVisible && (
                    <Alert type={alertType} message={alertMessage} />
                )}
                {step === 1 && (
                    <>
                        <h2>Do You Intend to File for A Benefit Increase?</h2>
                        <p>
                            Informing the VA about your intent to file an
                            increase marks the date when your potential benefit
                            increase takes effect. Any potential back pay will
                            be calculated based on that date.
                        </p>
                        <div className="btn-container">
                            {showDoLater && (
                                <>
                                    <button
                                        to="/dashboard"
                                        className="btn outline"
                                        onClick={() => setIsSkipModalOpen(true)}
                                    >
                                        I'll Do It later
                                    </button>
                                    <ITFSkipModal
                                        isOpen={isSkipModalOpen}
                                        onClose={() =>
                                            setIsSkipModalOpen(false)
                                        }
                                        onDoLater={() => {
                                            window.location.href =
                                                "/condition_exam/";
                                        }}
                                        onStay={() => setIsSkipModalOpen(false)}
                                    />
                                </>
                            )}
                            <button
                                type="button"
                                className="btn primary"
                                onClick={() => setStep(2)}
                            >
                                Start ITF
                            </button>
                        </div>
                    </>
                )}
                {status === "loading" && (
                    <div
                        id="survey-loading-container"
                        className="loading-spinner-container"
                    >
                        <img
                            className="load-icon spinner-img"
                            src="/static/img/spinner.png"
                            alt="Loading spinner"
                        />
                    </div>
                )}
                {step === 2 && (
                    <>
                        <h2>Before You Start</h2>
                        <p>
                            By submitting an Intent To File, you will be
                            eligible to receive back pay if the VA awards you a
                            favorable decision. Back pay will span between
                            today's date and the date of awards.
                        </p>
                        <div>
                            <img
                                className="img-warning"
                                src="/static/itf/img/warning.png"
                                alt="Warning"
                            />
                        </div>
                        <p>
                            Once you upload an ITF you will{" "}
                            <span className="text-red">NOT</span> be able to
                            download it again, press the button below to
                            download a copy if needed.
                        </p>
                        <p>
                            Press Send ITF when you are ready to send your
                            Intent to File to the VA
                        </p>
                        <a href="#" className="itf-link">
                            <p>Read More</p>
                        </a>
                        <div className="btn-container">
                            <button
                                type="button"
                                onClick={handleDownload}
                                disabled={downloadLoading}
                                className="btn outline"
                            >
                                {downloadLoading ? (
                                    <>
                                        Downloading...
                                        <div className="loader"></div>
                                    </>
                                ) : (
                                    "Download Copy"
                                )}
                            </button>
                            <button
                                type="button"
                                className="btn primary"
                                onClick={handleUpload}
                                disabled={uploadLoading}
                            >
                                {uploadLoading ? (
                                    <>
                                        Sending...
                                        <div className="loader"></div>
                                    </>
                                ) : (
                                    "Send ITF"
                                )}
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default SubmitITF;
