import React from "react";
import { GoBack } from "../../components/MainUnlock";
import "./EligibilityRequirements.scss";

const EligibilityRequirements = () => {
    return (
        <>
            <div className="page-container">
                <div className="page-top">
                    <GoBack />
                </div>
                <div className="page-body">
                    <div className="page-title-container">
                        <h1 className="page-title">Eligibility Requirements</h1>
                    </div>

                    <div className="eligibility">
                        <p>
                            Wondering what you need for eligibility for VA disability benefits?
                        </p>
                        <br />
                        <p>
                            In order to qualify for VA benefits, you must have been honorably discharged.
                            Next, you must also meet a four-part test which includes:
                        </p>
                        <br />
                        <ol>
                            <li>
                                A medical diagnosis of disability or condition in a medical record. This can be proven
                                through service treatment records, VA medical records, or any private medical records.
                            </li>
                            <li>
                                Your disability was caused/made worse due to active duty or by another service-connected
                                disability rated at 0 percent or higher for secondary service connection.
                            </li>
                            <li>
                                Current persistent and reoccurring symptoms of the disability or condition.
                            </li>
                            <li>
                                No active claims or appeals.
                            </li>
                        </ol>

                        
                        <div className="page-title-container">
                            <h1 className="page-title">Most Common Conditions for Eligibility for VA Disability Benefits</h1>
                        </div>

                        <p>Here are some of the most common disabilities among Veterans include:</p>
                        <br />
                        <ul>
                            <li>PTSD/Other Mental Disorders</li>
                            <li>Scars</li>
                            <li>Hearing Loss</li>
                            <li>Tinnitus</li>
                            <li>Limitation of Flexion of the Knee</li>
                            <li>Paralysis of the Sciatic Nerve</li>
                            <li>Migraines</li>
                            <li>Lumbar and Cervical Strains</li>
                            <li>Limitation of Flexion of the Ankle</li>
                            <li>Arthritis</li>
                            <li>Musculoskeletal System</li>
                            <li>Organs of Special Sense</li>
                            <li>Impairment of Auditory Acuity</li>
                            <li>Infectious Diseases, Immune Disorders, and Nutritional Deficiencies</li>
                            <li>Respiratory System</li>
                            <li>Cardiovascular System</li>
                            <li>Digestive System</li>
                            <li>Genitourinary System</li>
                            <li>Gynecological Conditions and Disorders of the Breast</li>
                            <li>Hematologic and Lymphatic Systems</li>
                            <li>Skin</li>
                            <li>Endocrine System</li>
                            <li>Neurological Conditions and Convulsive Disorders</li>
                            <li>Dental and Oral Conditions</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EligibilityRequirements;
