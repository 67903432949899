import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "@mui/material";

import { toggleShow } from "../../features/modalSlice";

const AdminModal = ({ children }) => {
    const { is_open } = useSelector((state) => state.modal);
    const dispatch = useDispatch();
    return (
        <Modal
            open={is_open}
            onClose={() => dispatch(toggleShow())}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {children}
        </Modal>
    );
};

export default AdminModal;
