import React, { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardSummary } from "../../features/dashboardSlice";
import { convertUTCToLocalString, dateOptions } from "@/common/utils/datetime";
import Chevron from "@/common/images/icons/chevron.svg";
import "./dashboard.scss";
import IconComponent from "../../components/IconComponent";
import Alert from "../../../common/components/Alert";
import ApptDetailModal from "./ApptDetailModal";

const Dashboard = () => {
    const user = useSelector((state) => state.user.user);
    const [loadingButtons, setLoadingButtons] = useState({}); // Track loading per step ID
    const {
        status: dashboardStatus,
        nextSteps,
        estimatedFilingDate,
        apptDetails,
        steps,
        error,
    } = useSelector((state) => state.dashboard);
    const dispatch = useDispatch();
    const isLoading = useMemo(
        () => dashboardStatus === "pending" || dashboardStatus === "idle",
        [dashboardStatus]
    );
    const [stepDropdownStates, setStepDropdownStates] = useState([]);
    const stepDropdownRefs = useRef([]);

    const [isApptDetails, setIsApptDetails] = useState(false);
    const [openApptModal, setOpenApptModal] = useState(false);
    const [apptUrl, setApptUrl] = useState();

    const toggleStepDropdown = (index) => {
        setStepDropdownStates((prevStates) => {
            const newStates = [...prevStates];
            newStates[index] = {
                isOpen: !newStates[index].isOpen,
                height: newStates[index].isOpen
                    ? 0
                    : stepDropdownRefs.current[index].scrollHeight,
            };
            return newStates;
        });
    };

    useEffect(() => {
        dispatch(getDashboardSummary());
    }, []);

    useEffect(() => {
        if (nextSteps) {
            setIsApptDetails(nextSteps.action === "Appointment Details");
            setApptUrl(
                nextSteps.title.includes("Pending")
                    ? "/appointments/unlocked/"
                    : "/appointments/summary/"
            );
        }
    }, [nextSteps]);

    useEffect(() => {
        setStepDropdownStates(
            steps ? steps.map(() => ({ isOpen: false, height: 0 })) : []
        );
    }, [steps]);

    const handleClick = (stepId, path) => {
        setLoadingButtons((prev) => ({ ...prev, [stepId]: true }));
        // Simulate an API call or action
        setTimeout(() => {
            window.location.href = path;
            setLoadingButtons((prev) => ({ ...prev, [stepId]: false }));
        }, 2000); // Simulate a delay
    };

    if (isLoading) {
        return (
            <div className="loading-spinner-container">
                <div
                    style={{
                        height: "80px",
                        width: "80px",
                        borderWidth: "8px",
                    }}
                    className="loader"
                ></div>
            </div>
        );
    }

    if (error) {
        return <Alert type={"error"} message={error} />;
    }

    return (
        <div id="dashboard">
            <p className="welcome-header">
                <span>Welcome Back, </span>
                <span className="full-name">{user.full_name}!</span>
            </p>
            <section className="next-steps">
                <h6>Next Steps:</h6>
                <div className="inner">
                    <div className="info">
                        <div className="icon-wrapper">
                            <IconComponent
                                iconKey={nextSteps?.id}
                                iconSize={24}
                            />
                        </div>
                        <div>
                            <p className="title">{nextSteps.title}</p>
                            <p className="desc">{nextSteps.description}</p>
                        </div>
                    </div>
                    <a
                        id="next-steps-btn"
                        className="btn primary"
                        type="button"
                        style={isApptDetails ? { cursor: "pointer" } : {}}
                        {...(isApptDetails
                            ? { onClick: () => setOpenApptModal(true) }
                            : { href: nextSteps.path })}
                    >
                        {nextSteps.action}
                    </a>
                </div>
            </section>
            <section className="summary">
                <h6>Overview</h6>
                <p className="desc">
                    Complete all the following steps to file your Veterans
                    Affairs claim.
                </p>
                <p className="estimate">
                    Estimated Filing Date:{" "}
                    {convertUTCToLocalString(estimatedFilingDate, dateOptions)}
                </p>

                <div className="steps">
                    {steps.map((step, i) => (
                        <div
                            key={step.id}
                            className={`step ${step.status} ${
                                stepDropdownStates[i]?.isOpen ? "expanded" : ""
                            }`}
                        >
                            <div className="circle"></div>
                            <div className="step-card">
                                <div
                                    className="header"
                                    onClick={() => toggleStepDropdown(i)}
                                >
                                    <IconComponent
                                        iconKey={step.id}
                                        iconSize={24}
                                    />
                                    <span>{step.name}</span>
                                    <Chevron
                                        className="expand-trigger"
                                        height={24}
                                        width={24}
                                    />
                                </div>
                                <div
                                    className="inner"
                                    style={{
                                        height:
                                            stepDropdownStates[i]?.height ?? 0,
                                    }}
                                >
                                    <div
                                        ref={(ref) =>
                                            (stepDropdownRefs.current[i] = ref)
                                        }
                                    >
                                        <p className="desc">
                                            {step.description}
                                        </p>
                                        <a
                                            href={step.path}
                                            className={`btn primary ${step.status}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleClick(step.id, step.path);
                                            }}
                                        >
                                            {loadingButtons[step.id] ? (
                                                <div className="loader"></div>
                                            ) : (
                                                step.action
                                            )}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <ApptDetailModal
                isOpen={openApptModal}
                onClose={() => setOpenApptModal(false)}
                apptDetails={apptDetails}
                apptUrl={apptUrl}
            />
        </div>
    );
};

export default Dashboard;
