import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { cachedAxiosInstance, axiosInstance } from "../../common/utils/axios";

export const getDoctors = createAsyncThunk(
    "appointments/doctors",
    async (filters, thunkAPI) => {
        try {
            var url = "/appointments/api/doctors/";
            if (filters) {
                url = addFilters(url, filters);
            }
            const response = await cachedAxiosInstance.get(url);
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to get doctor list."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const selectDoctor = createAsyncThunk(
    "appointments/doctors/select",
    async (postData, thunkAPI) => {
        try {
            const response = await axiosInstance.post(
                "/appointments/api/doctors/select/",
                postData
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to select doctor."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

const addFilters = (url, filters) => {
    url += `?limit=${filters.limit}`;
    for (const [key, value] of Object.entries(filters)) {
        if (value && key !== "limit") {
            url += `&${key}=${value}`;
        }
    }
    return url;
};

const initialMethodState = {
    status: "idle",
    error: null,
};

export const doctorsSlice = createSlice({
    name: "doctors",
    initialState: {
        data: null,
        status: "idle",
        error: null,
        method: initialMethodState,
    },
    reducers: {
        // reset method state obj to it's initial value
        resetMethod: (state, _) => {
            state.method = initialMethodState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDoctors.pending, (state) => {
                state.status = "pending";
                state.error = null;
            })
            .addCase(getDoctors.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.data = action.payload;
                state.error = null;
            })
            .addCase(getDoctors.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(selectDoctor.pending, (state) => {
                state.method.status = "pending";
                state.method.error = null;
            })
            .addCase(selectDoctor.fulfilled, (state) => {
                state.method.status = "succeeded";
                state.method.error = null;
            })
            .addCase(selectDoctor.rejected, (state, action) => {
                state.method.status = "failed";
                state.method.error = action.payload;
            });
    },
});

export const { resetMethod } = doctorsSlice.actions;
export default doctorsSlice.reducer;
