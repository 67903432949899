// Main function to handle mapping for a group of elements
// Used while generating a PDF across all projects
const handleGroupMapping = async (
    group,
    pdfDoc,
    newFields,
    pdfPages,
    form,
    HelveticaFont,
    fontSize
) => {
    // Define handlers for different group types
    const handlers = {
        text: handleTextGroup,
        comment: handleTextGroup,
        imagepicker: handleTextGroup,
        checkbox: handleCheckboxGroup,
        radiogroup: handleRadioGroup,
        boolean: handleRadioGroup,
        signaturepad: handleSignaturePad,
        multipletext: handleMultipleTextGroup,
        multipletextgroup: handleMultipleTextGroup,
    };

    // Call the appropriate handler based on the group type
    const handler = handlers[group.type];
    if (handler) {
        await handler(
            group,
            pdfDoc,
            newFields,
            pdfPages,
            form,
            HelveticaFont,
            fontSize
        );
    } else {
        console.warn(`Unsupported group type: ${group.type}`);
    }
};

// Helper functions
// Find a field in the newFields array by its fieldName
const findField = (newFields, fieldName) =>
    newFields.find((field) => field.fieldName === fieldName);

// Get a PDF page by its index
const getPdfPage = (pdfPages, pageIndex) => pdfPages[pageIndex - 1];

// Draw text on a PDF page
const drawText = (pdfPage, text, x, y, fontSize, HelveticaFont) => {
    pdfPage.drawText(text, {
        x,
        y,
        size: fontSize,
        font: HelveticaFont,
        color: rgb(0, 0, 0),
    });
};

// Handler functions
// Handle text, comment, and imagepicker groups
const handleTextGroup = async (
    group,
    pdfDoc,
    newFields,
    pdfPages,
    form,
    HelveticaFont,
    fontSize
) => {
    const props = group.propertiesList[0];
    // set text to the field if it exists
    if (props.fieldName) {
        // get text input
        const textField = findField(newFields, props.fieldName);
        // fill text input
        if (group.value) {
            // enable multiline for text area field if it's disabled
            if (group.type === "comment" && !textField.field.isMultiline()) {
                textField.field.enableMultiline();
            }
            // write text in the field
            textField.field.setText(group.value.toString());
        }
        // otherwise draw the text using the coordinates
    } else if (group.value) {
        const pdfPage = getPdfPage(pdfPages, group.page);
        const { height } = pdfPage.getSize();
        const y =
            height -
            (props.top / scale + props.height / scale + 1) +
            props.height / scale / 2;
        drawText(
            pdfPage,
            group.value.toString(),
            props.left / scale + 1,
            y,
            fontSize,
            HelveticaFont
        );
    }
};

// Handle checkbox groups
const handleCheckboxGroup = async (
    group,
    pdfDoc,
    newFields,
    pdfPages,
    form,
    HelveticaFont,
    fontSize
) => {
    group.propertiesList.forEach((property) => {
        // check checkbox field if it exists in the pdf
        if (property.fieldName) {
            const checkboxField = findField(newFields, property.fieldName);
            let fieldName = property.fieldName;
            // add backslashes to field name if exist
            if (checkboxField.backSlashIndexList) {
                fieldName = addBackslashes(
                    fieldName,
                    checkboxField.backSlashIndexList
                );
            }
            // check checkbox
            form.getCheckBox(fieldName).check();
            // otherwise, draw x to check it using the coordinates
        } else {
            const pdfPage = getPdfPage(pdfPages, group.page);
            const { height } = pdfPage.getSize();
            // Define the SVG path string for a checkbox's x
            const starPath = "M 2 2 L 8 8 M 8 2 L 2 8";
            const x = property.left / scale - property.width / scale / 2 - 2;
            const y =
                height -
                (property.top / scale + property.height / scale + 1) +
                property.height / scale +
                6.5;
            drawSvgPath(pdfPage, starPath, x, y);
        }
    });
};

// Handle radio button and boolean groups
const handleRadioGroup = async (
    group,
    pdfDoc,
    newFields,
    pdfPages,
    form,
    HelveticaFont,
    fontSize
) => {
    group.propertiesList.forEach((property) => {
        // select radio button field if it exists in the pdf
        if (property.fieldName) {
            // get selected radio btn option index
            const optionIndex = parseInt(property.option);
            let fieldName = property.fieldName;
            // get radio button field
            const radioField = findField(newFields, property.fieldName);
            // add backslashes to field name if exist
            if (radioField.backSlashIndexList) {
                fieldName = addBackslashes(
                    fieldName,
                    radioField.backSlashIndexList
                );
            }
            // get radio button group and select group option
            const radioGroup = form.getRadioGroup(fieldName);
            const options = radioGroup.getOptions();
            radioGroup.select(options[optionIndex]);
            // otherwise, draw o to select it using the coordinates
        } else {
            const pdfPage = getPdfPage(pdfPages, group.page);
            const { height } = pdfPage.getSize();
            // Define the SVG path string for a radio button's bullet
            const starPath = "M 5 0 A 5 5 0 1 1 4.99 0 Z";
            const x = property.left / scale - property.width / scale / 2 - 0.5;
            const y =
                height -
                (property.top / scale + property.height / scale + 1) +
                property.height / scale +
                4;
            drawSvgPath(pdfPage, starPath, x, y, 0.5);
        }
    });
};

// Handle signature pad groups
const handleSignaturePad = async (
    group,
    pdfDoc,
    newFields,
    pdfPages,
    form,
    HelveticaFont,
    fontSize
) => {
    // initialize signature box coordinates var
    let coordinates;
    const pdfPage = getPdfPage(pdfPages, group.page);
    const { height } = pdfPage.getSize();
    const props = group.propertiesList[0];
    // get signature image in bytes
    const imageBytes = props.value;
    // convert it to png image
    const pngImage = await pdfDoc.embedPng(imageBytes);
    // get image dimensions
    const imageDims = await pngImage.scale(0.25);
    // get signature box field coordinates from pdf field if it exists
    if (props.fieldName) {
        const signatureField = findField(newFields, props.fieldName);
        const widgets = signatureField.field.acroField.getWidgets();
        // get signature field coordinates
        widgets.forEach((w) => {
            const newRect = w.getRectangle();
            coordinates = {
                x: newRect.x + 1,
                y: newRect.y + 1,
                width: Math.min(imageDims.width, newRect.width - 3),
                height: Math.min(imageDims.height, newRect.height - 3),
            };
        });
        // otherwise use manually selected box coordinates
    } else {
        coordinates = {
            x: props.left / scale - 1 + 3,
            y: height - (props.top / scale + props.height / scale) + 2,
            width: imageDims.width - 2,
            height: Math.min(imageDims.height, props.height / scale) - 4,
        };
    }
    // Draw the PNG image in the signature field
    pdfPage.drawImage(pngImage, coordinates);
};

// Handle multiple text and multiple text group fields
const handleMultipleTextGroup = async (
    group,
    pdfDoc,
    newFields,
    pdfPages,
    form,
    HelveticaFont,
    fontSize
) => {
    group.propertiesList.forEach((property) => {
        // set text to the field if it exists
        if (property.fieldName) {
            // get text input
            const textField = findField(newFields, property.fieldName);
            // fill text input
            if (property.value) {
                textField.field.setText(property.value.toString());
            }
            // otherwise draw the text using the coordinates
        } else {
            const pdfPage = getPdfPage(pdfPages, group.page);
            const { height } = pdfPage.getSize();
            if (property.value) {
                const x = property.left / scale + 1;
                const y =
                    height -
                    (property.top / scale + property.height / scale + 1) +
                    property.height / scale / 2;
                drawText(
                    pdfPage,
                    property.value.toString(),
                    x,
                    y,
                    fontSize,
                    HelveticaFont
                );
            }
        }
    });
};

// Utility functions
// Add backslashes to a string at specified positions
const addBackslashes = (str, positions) => {
    let result = str;
    positions.forEach((pos, index) => {
        result =
            result.slice(0, pos + index) + "\\" + result.slice(pos + index);
    });
    return result;
};

// Draw an SVG path on a PDF page
const drawSvgPath = (pdfPage, path, x, y, scale = 1) => {
    pdfPage.drawSvgPath(path, {
        x,
        y,
        scale,
        color: rgb(0, 0, 0),
        rotateDegrees: 45,
        borderColor: rgb(0, 0, 0),
        borderWidth: 1,
    });
};

export { handleGroupMapping };
