import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    contactSupport,
    ERROR_MESSAGE,
    getHelpPortal,
} from "../../features/settingsSlice";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "../../../common/components/form/fields/FormField";
import useAlert from "../../hooks/useAlert";
import Alert from "../../../common/components/Alert";

const Support = () => {
    return (
        <>
            <div className="page-top-container">
                <img src="/static/settings/img/help-icon-blue.png" alt="help" />
                <h6 className="page-top-header">Support</h6>
            </div>
            <div className="page-middle">
                <div className="content-container">
                    <div className="page-content settings-column">
                        <Search />
                        <FAQs />
                        <ContactSupport />
                        <DeleteInfo />
                    </div>
                </div>
            </div>
        </>
    );
};

const Search = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const { helpPortal, status } = useSelector((state) => state.settings);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getHelpPortal());
    }, [dispatch]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <div className="search-container">
            <h4 className="settings-title">How Can We Help You?</h4>
            <div className="search-input-wrapper">
                <div className="search-icon-container">
                    <svg
                        width="13"
                        height="14"
                        viewBox="0 0 13 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6 10.9999C8.76142 10.9999 11 8.7613 11 5.99988C11 3.23845 8.76142 0.999878 6 0.999878C3.23858 0.999878 1 3.23845 1 5.99988C1 8.7613 3.23858 10.9999 6 10.9999Z"
                            stroke="#585A5B"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M12 12.9999L10 10.9999"
                            stroke="#585A5B"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
                <input
                    type="text"
                    placeholder="Search for articles..."
                    value={searchQuery}
                    onChange={handleSearch}
                />
            </div>
            <a
                id="search-btn"
                href={
                    helpPortal?.help_portal_url
                        ? `${helpPortal.help_portal_url}search?q=${searchQuery}&platform=vetvantage`
                        : "#"
                }
                className="btn primary"
                target="_blank"
                rel="noopener noreferrer"
                disabled={status === "loading" || !searchQuery}
            >
                {status === "loading" ? (
                    <div className="loader"></div>
                ) : (
                    "Search"
                )}
            </a>
        </div>
    );
};

const FAQs = () => {
    const [expandedIndices, setExpandedIndices] = useState([]);
    const helpPortal = useSelector((state) => state.settings.helpPortal);

    const toggleFAQ = (index) => {
        setExpandedIndices((prevIndices) =>
            prevIndices.includes(index)
                ? prevIndices.filter((i) => i !== index)
                : [...prevIndices, index]
        );
    };

    return (
        <div className="faqs-container">
            <h3 className="settings-title">Frequently Asked Questions</h3>
            <div className="faqs-wrapper">
                {helpPortal?.faqs?.length > 0 ? (
                    helpPortal?.faqs.map((faq, index) => (
                        <div key={index}>
                            <div
                                className={`faq-item accordion ${
                                    expandedIndices.includes(index)
                                        ? "active"
                                        : ""
                                }`}
                                onClick={() => toggleFAQ(index)}
                            >
                                <div className="faq-question-container">
                                    <p className="faq-question ">{faq.title}</p>
                                </div>
                                <div className="faq-expand-wrapper ">
                                    <img
                                        src="/static/settings/img/chevron-down.png"
                                        alt="Arrow down"
                                    />
                                </div>
                            </div>
                            <div
                                className={`panel ${
                                    expandedIndices.includes(index)
                                        ? "active"
                                        : ""
                                }`}
                            >
                                <p className="faq-answer">{faq.answer}</p>
                                <div className="faq-more-container">
                                    <a
                                        href={faq.url}
                                        className="faq-more-link"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        Read More &gt;
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="no-faq-found">No FAQ found.</p>
                )}
            </div>
        </div>
    );
};

const ContactSupport = () => {
    const dispatch = useDispatch();
    const { alertType, alertMessage, alertVisible, showAlert } = useAlert();

    const initialValues = {
        message: "",
    };

    const validationSchema = Yup.object({
        message: Yup.string().required("Please enter a message"),
    });

    const handleSubmitContactSupport = (
        values,
        { setSubmitting, resetForm }
    ) => {
        dispatch(contactSupport(values))
            .unwrap()
            .then(() => {
                showAlert({
                    alertType: "success",
                    alertMessage: "Message sent successfully",
                });
            })
            .catch(() => {
                showAlert({
                    alertType: "error",
                    alertMessage: ERROR_MESSAGE,
                });
            })
            .finally(() => {
                setSubmitting(false);
                resetForm();
            });
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmitContactSupport}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <h3 className="settings-title">Contact Support Team</h3>
                        {alertVisible && (
                            <Alert type={alertType} message={alertMessage} />
                        )}
                        <div className="contact-info-container">
                            <FormField
                                label="How can we help you?"
                                name="message"
                                id="message"
                                type="textarea"
                                className="contact-textarea"
                            />
                        </div>
                        <div className="page-btn-container">
                            <button
                                type="submit"
                                className="btn primary"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <div className="loader"></div>
                                ) : (
                                    "Submit"
                                )}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

const DeleteInfo = () => {
    return (
        <div id="delete-info-request">
            <h3 className="settings-title">Information Deletion Request</h3>
            <p className="text-center">
                If you want to permanently delete your account information, let
                us know.
            </p>
            <Link to="/settings/delete-info" className="btn outline">
                Delete My Information
            </Link>
        </div>
    );
};

export default Support;
