import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { cachedAxiosInstance } from "@/common/utils/axios";

const initialMethodState = {
    name: null,
    status: "idle",
    error: null,
};

export const PAYMENT_TYPE = {
    CardBank: 0,
    Check: 1,
};

export const getPatientOrders = createAsyncThunk(
    "products/orders",
    async (
        { patientId, getUnpaid = false, page_size = null, page = null },
        thunkAPI
    ) => {
        try {
            const queryParams = new URLSearchParams();
            if (page_size) queryParams.append("page_size", page_size);
            if (page) queryParams.append("page", page);
            if (getUnpaid) queryParams.append("status", "unpaid");

            const response = await cachedAxiosInstance.get(
                `/products/api/orders/${patientId}/?${queryParams.toString()}`
            );
            const data = await response.data;
            return data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to get patient orders."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const getUnpaidOrders = createAsyncThunk(
    "products/unpaid_orders",
    async ({ paymentType, page_size = null, page = null }, thunkAPI) => {
        try {
            const queryParams = new URLSearchParams();
            if (page_size) queryParams.append("page_size", page_size);
            if (page) queryParams.append("page", page);

            const url = queryParams.toString()
                ? `/products/api/unpaid_orders/${paymentType}/?${queryParams.toString()}`
                : `/products/api/unpaid_orders/${paymentType}`;
            const response = await cachedAxiosInstance.get(url);
            const data = await response.data;
            return data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to get patient orders."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const markOrderPaid = createAsyncThunk(
    "products/mark_paid",
    async (orderId, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.patch(
                `/products/api/order_details/${orderId}/`,
                { status: 2 }, // Assuming you want to update the status to "paid"
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.error?.details || "Failed to update order."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const ordersSlice = createSlice({
    name: "orders",
    initialState: {
        orders: [],
        status: "idle",
        error: null,
        method: initialMethodState,
        pagination: null,
    },
    reducers: {
        resetOrderMethod: (state, _) => {
            state.method = initialMethodState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPatientOrders.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getPatientOrders.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.orders = action.payload.orders;
                state.pagination = action.payload;
                state.error = null;
            })
            .addCase(getPatientOrders.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(getUnpaidOrders.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getUnpaidOrders.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.orders = action.payload.orders;
                state.pagination = action.payload;
                state.error = null;
            })
            .addCase(getUnpaidOrders.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })

            .addCase(markOrderPaid.pending, (state) => {
                state.method.name = "patch";
                state.method.status = "pending";
            })
            .addCase(markOrderPaid.fulfilled, (state, action) => {
                state.orders = state.orders.filter((obj) => {
                    obj.id !== action.payload.id;
                });
                state.method.status = "succeeded";
                state.method.error = null;
            })
            .addCase(markOrderPaid.rejected, (state, action) => {
                state.method.status = "failed";
                state.method.error = action.payload;
            });
    },
});
export const { resetOrderMethod } = ordersSlice.actions;
export default ordersSlice.reducer;
