import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { cachedAxiosInstance } from "@/common/utils/axios";

export const getROI = createAsyncThunk("roi/getROI", async (_, thunkAPI) => {
    try {
        const response = await cachedAxiosInstance.get("/api/release-of-information/");
        return await response.data;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const errorData = error.response.data;
            return thunkAPI.rejectWithValue(
                errorData?.detail || "Failed to get Release of Information."
            );
        } else if (error.request) {
            // The request was made but no response was received
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
});

// Thunk to submit IAQ survey
export const submitROI = createAsyncThunk(
    "roi/submitROI",
    async ( action, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.post(
                "/api/release-of-information/",
                {
                    action: action,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;

                if (error.response.status === 400) {
                    return thunkAPI.rejectWithValue(
                        errorData
                    );
                }

                return thunkAPI.rejectWithValue(
                    errorData?.detail ||
                        errorData?.file?.error ||
                        "Failed to submit Release of Information."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

const roiSlice = createSlice({
    name: "roi",
    initialState: {
        content: null,
        error: null,
        loading: true,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getROI.pending, (state) => {
                state.error = null;
                state.loading = true;
            })
            .addCase(getROI.fulfilled, (state, action) => {
                state.content = action.payload.blocks[0].data.text;
                state.error = null;
                state.loading = false;
            })
            .addCase(getROI.rejected, (state, action) => {
                state.error = action.payload.error;
                state.loading = false;
            })
            .addCase(submitROI.pending, (state) => {
                state.error = null;
                state.loading = true;
            })
            .addCase(submitROI.fulfilled, (state) => {
                state.error = null;
                state.loading = false;
            })
            .addCase(submitROI.rejected, (state, action) => {
                state.error = action.payload.error;
                state.loading = false;
            });
    },
});

export default roiSlice.reducer;