import React from "react";
import { useDispatch } from "react-redux";

import ClipboardPlus from "@/common/images/icons/clipboardplus.svg";
import Document from "@/common/images/icons/document.svg";
import { toggleShow } from "../features/modalSlice";
import Modal from "./Modal";
import CloseModalBtn from "./CloseModalBtn";
import "../common/styles/modal_content.scss";
import "./product_list_modal.scss";

const ProductListModal = ({ orderDetails, setOrderDetails }) => {
    const dispatch = useDispatch();

    // reset order details state and close the modal
    const closeModal = () => {
        setOrderDetails(null);
        dispatch(toggleShow());
    };

    return (
        orderDetails && (
            <Modal>
                <div id="productListModal" className="modal-content">
                    <CloseModalBtn onClose={() => closeModal()} />
                    <div className="title-container">
                        <h5>Products</h5>
                    </div>
                    <div className="products-container">
                        {orderDetails.order_items.map((item, index) => {
                            const { product, price, quantity } = item;
                            return (
                                <div key={index + 1} className="product">
                                    <div className="product-left">
                                        <div className="product-icon">
                                            {product.type == 2 ? (
                                                <Document
                                                    height={20}
                                                    width={20}
                                                />
                                            ) : (
                                                <ClipboardPlus
                                                    height={22}
                                                    width={22}
                                                />
                                            )}
                                        </div>
                                        <p title={product.name}>
                                            {product.name}
                                        </p>
                                    </div>
                                    <div className="product-right">
                                        <p>${parseFloat(price).toFixed(2)}</p>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="total-wrapper">
                            <div className="total-title-container">
                                <p>Total</p>
                            </div>
                            <div className="total-value-container">
                                <p>
                                    ${parseFloat(orderDetails.total).toFixed(2)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    );
};

export default ProductListModal;
