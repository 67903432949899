import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const API_BASE_URL = "/itf/api";

// API functions
const fetchItfData = (axiosInstance) =>
    axiosInstance.get(`${API_BASE_URL}/itf/`);
const saveItfProgress = (axiosInstance, data) =>
    axiosInstance.post(`${API_BASE_URL}/save-progress/`, data);
const completeItf = (axiosInstance, data) =>
    axiosInstance.post(`${API_BASE_URL}/complete-itf/`, data);
const submitItf = (axiosInstance) =>
    axiosInstance.get(`${API_BASE_URL}/submit-itf/`);
const getItfData = (axiosInstance, itfDocId) =>
    axiosInstance.get(`${API_BASE_URL}/get-itf-data/${itfDocId}/`);
const fetchItfSuccess = (axiosInstance) =>
    axiosInstance.get(`${API_BASE_URL}/itf-success/`);
const fetchItfDone = (axiosInstance) =>
    axiosInstance.get(`${API_BASE_URL}/itf-done/`);

// Helper function for error handling
export const handleError = (error, customErrorMsg = "An error occurred.") => {
    if (error.response) {
        const errorData = error?.response?.data || {};
        if (errorData?.detail) {
            return errorData?.detail || customErrorMsg;
        }
        if (errorData?.message) {
            return errorData?.message || customErrorMsg;
        }
        if (errorData?.error) {
            return errorData?.error || customErrorMsg;
        }
    } else if (error.request) {
        return error?.message || customErrorMsg;
    } else {
        return error?.message || customErrorMsg;
    }
};

// Update existing async thunks
export const getItfSurveyData = createAsyncThunk(
    "itf/getItfSurveyData",
    async (_, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await fetchItfData(axiosInstance);
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to fetch ITF survey data.")
            );
        }
    }
);

export const saveProgress = createAsyncThunk(
    "itf/saveProgress",
    async (data, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await saveItfProgress(axiosInstance, data);
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to save ITF progress.")
            );
        }
    }
);
// This function is used to complete the ITF form after the user has filled out the survey
export const completeItfForm = createAsyncThunk(
    "itf/completeItf",
    async (data, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await completeItf(axiosInstance, data);
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to complete ITF form.")
            );
        }
    }
);

export const submitItfForm = createAsyncThunk(
    "itf/submitItf",
    async (_, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await submitItf(axiosInstance);
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to submit ITF form.")
            );
        }
    }
);

export const getItfDocumentData = createAsyncThunk(
    "itf/getItfDocumentData",
    async (itfDocId, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await getItfData(axiosInstance, itfDocId);
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to get ITF document data.")
            );
        }
    }
);

export const checkItfExistence = createAsyncThunk(
    "itf/checkItfExists",
    async (_, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await axiosInstance.get(
                `${API_BASE_URL}/check-itf-exists/`
            );
            return response.data.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to check ITF existence.")
            );
        }
    }
);

export const getItfSuccessData = createAsyncThunk(
    "itf/getItfSuccessData",
    async (_, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await fetchItfSuccess(axiosInstance);
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to get ITF success data.")
            );
        }
    }
);

export const getItfDoneData = createAsyncThunk(
    "itf/getItfDoneData",
    async (_, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await fetchItfDone(axiosInstance);
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to get ITF done data.")
            );
        }
    }
);

const itfSlice = createSlice({
    name: "itf",
    initialState: {
        surveyData: null,
        currentData: null,
        documentData: null,
        itfExists: false,
        isOptional: false,
        successData: null,
        doneData: null,
        status: "idle",
        error: null,
        redirect: null,
        context: null,
    },
    reducers: {
        clearRedirect: (state) => {
            state.redirect = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getItfSurveyData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getItfSurveyData.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.surveyData = action.payload;
                state.redirect = action.payload.redirect;
                if (action.payload.status === "completed") {
                    state.context = action.payload.context;
                    state.status = "completed";
                }
            })
            .addCase(getItfSurveyData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
                state.redirect = action.payload.redirect;
            })
            .addCase(saveProgress.fulfilled, (state, action) => {
                state.status = "succeeded";
            })
            .addCase(saveProgress.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(completeItfForm.pending, (state) => {
                state.status = "loading";
            })
            .addCase(completeItfForm.fulfilled, (state, action) => {
                state.status = "succeeded";
            })
            .addCase(completeItfForm.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(submitItfForm.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.currentData = action.payload.itf;
                state.isOptional = action.payload.is_optional;
                state.redirect = action.payload.redirect;
            })
            .addCase(submitItfForm.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(getItfDocumentData.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.documentData = action.payload;
            })
            .addCase(getItfDocumentData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(checkItfExistence.pending, (state) => {
                state.status = "loading";
            })
            .addCase(checkItfExistence.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.itfExists = action.payload.exists;
                state.isOptional = action.payload.is_optional;
            })
            .addCase(checkItfExistence.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(getItfSuccessData.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.successData = action.payload;
            })
            .addCase(getItfSuccessData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(getItfDoneData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getItfDoneData.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.doneData = action.payload.itf;
                state.redirect = action.payload.redirect;
            })
            .addCase(getItfDoneData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            });
    },
});

export const { clearRedirect } = itfSlice.actions;

export default itfSlice.reducer;
