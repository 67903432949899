import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../../vetvantage/features/userSlice";

const ProtectedRoute = () => {
    const { user, status } = useSelector((state) => state.user);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUser());
    }, []);

    if (status == "pending" || status == "idle") {
        return (
            <div
                style={{
                    height: "100dvh",
                    width: "100dvw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        height: "80px",
                        width: "80px",
                        borderWidth: "8px",
                    }}
                    className="loader"
                ></div>
            </div>
        );
    }
    return user?.authenticated ? (
        <Outlet />
    ) : (
        <Navigate to="/accounts/login" state={{ from: location }} replace />
    );
};

export default ProtectedRoute;
