export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie) {
        const cookieValue = document.cookie
            .split("; ")
            .find((row) => row.startsWith(name + "="));
        return cookieValue
            ? decodeURIComponent(cookieValue.split("=")[1])
            : null;
    }
    return cookieValue;
}

export function setCookie(name, value, days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

export function eraseCookie(name) {   
    document.cookie = name+'=; Max-Age=-99999999;';  
}
