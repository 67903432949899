import axios from "axios";

import { getCookie } from "@/common/utils/cookies";

// Create a custom axios instance without cache
export const axiosInstance = axios.create({
    baseURL: "",
    timeout: 30000, // 30 seconds in development switch to lower value later in prod
    headers: {
        "X-CSRFToken": getCookie("csrftoken"),
    },
});

// Create a custom axios instance witch cache
const axiosInstanceCache = axios.create({
    baseURL: "",
    timeout: 30000, // 30 seconds in development switch to lower value later in prod
    headers: {
        "X-CSRFToken": getCookie("csrftoken"),
    },
});

const createCacheMiddleware = () => {
    // Create a new Map to store cached responses
    const cache = new Map();

    // Request interceptor
    axiosInstanceCache.interceptors.request.use((config) => {
        // Don't cache POST, PUT, DELETE requests
        if (["post", "put", "delete"].includes(config.method.toLowerCase())) {
            return config;
        }

        // Generate a unique cache key for the request
        const cacheKey = `${config.method}:${config.url}${JSON.stringify(
            config.params || {}
        )}`;

        // Check if the request is cached and not expired
        if (cache.has(cacheKey)) {
            const { data, timestamp } = cache.get(cacheKey);
            if (Date.now() - timestamp < 60000) {
                // 60 seconds cache lifetime
                // Return cached data without making a new request
                return {
                    ...config,
                    adapter: () => Promise.resolve({ data, config }),
                };
            } else {
                cache.delete(cacheKey);
            }
        }
        return config;
    });

    // Response interceptor
    axiosInstanceCache.interceptors.response.use(
        (response) => {
            // Don't cache POST, PUT, DELETE responses
            if (
                !["post", "put", "delete"].includes(
                    response.config.method.toLowerCase()
                )
            ) {
                // Cache the response for GET requests
                const cacheKey = `${response.config.method}:${
                    response.config.url
                }${JSON.stringify(response.config.params || {})}`;
                cache.set(cacheKey, {
                    data: response.data,
                    timestamp: Date.now(),
                });
            } else {
                // Invalidate entire cache for POST, PUT, DELETE requests
                cache.clear();
            }
            return response;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    // Add invalidateCache method to the axiosInstanceCache
    axiosInstanceCache.invalidateCache = (method, url, params = {}) => {
        if (method && url) {
            // Invalidate specific cache entry
            const cacheKey = `${method}:${url}${JSON.stringify(params)}`;
            cache.delete(cacheKey);
        } else {
            // Invalidate entire cache
            cache.clear();
        }
    };
    return axiosInstanceCache;
};

export const cachedAxiosInstance = createCacheMiddleware();
