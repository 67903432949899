import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetOrderMethod } from "../features/ordersSlice";
import { toggleShow } from "../features/modalSlice";
import Modal from "./Modal";
import CloseModalBtn from "./CloseModalBtn";
import "./confirm_check_modal.scss";
import "./close_modal_btn.scss";
import { markOrderPaid } from "../features/ordersSlice";

const ConfirmCheckModal = ({ data }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetOrderMethod());
    }, []);

    const closeModal = () => {
        dispatch(toggleShow());
    };

    const markCheckReceived = () => {
        return new Promise((resolve, _) => {
            dispatch(markOrderPaid(data.id)).then((res) => {
                if (!res.error) {
                    closeModal();
                }
                resolve();
            });
        });
    };

    return (
        <Modal>
            <div id="confirmCheckModal" className="modal-content">
                <CloseModalBtn onClose={closeModal} />
                <h4 className="modal-title">
                    Are you sure you wish to mark this check as received?
                </h4>
                <div className="btn-container">
                    <button onClick={markCheckReceived} className="btn primary">
                        Yes
                    </button>
                    <button className="btn primary" onClick={closeModal}>
                        No
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmCheckModal;
