import React from "react";

import ProgressLabel from "../../../images/progress-label.svg";
import SidebarSteps from "../../../images/sidebar-steps.svg";
import "./ProcessStepsAnim.scss";

const ProcessStepsAnim = () => {
    return (
        <div id="processStepsAnim">
            <ProgressLabel />
            <SidebarSteps />
        </div>
    );
};

export default ProcessStepsAnim;
