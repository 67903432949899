import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./products.scss";
import CalcPay from "./CalcPay";
import CompletePay from "./CompletePay";
import arrowLeft from "../../images/arrow-left.png";
import { checkout, getCheckoutInfo, updateTotalAmount, clearRedirectUrl } from "../../features/checkoutSlice";
import { Link } from "react-router-dom";

const CheckoutPage = () => {
    const dispatch = useDispatch();
    const [step, setStep] = useState(1);
    const { orderItems, totalAmount, loading, error, redirectUrl } = useSelector((state) => state.checkout);
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        if (redirectUrl) {
            window.location.href = redirectUrl;
            dispatch(clearRedirectUrl());
        }
        dispatch(getCheckoutInfo());
    }, [redirectUrl, dispatch]);

    const handleCalculateClick = (selectedItems) => {
        const total = selectedItems.reduce((sum, item) => sum + parseFloat(item.price), 0);
        dispatch(updateTotalAmount(total));
        setSelectedItems(selectedItems);
        setStep(2);
    };

    const handlePayClick = (value) => {
        const paymentData = { ...value, selected_items: selectedItems };
        dispatch(checkout(paymentData));
    };

    if (loading) {
        return (
            <div className="loading-spinner-container">
                <div
                    style={{
                        height: "80px",
                        width: "80px",
                        borderWidth: "8px",
                    }}
                    className="loader"
                ></div>
            </div>
        );
    }

    if (error) {
        return (
            <div id="checkout-page" style={{ justifyContent: "center" }}>
                <div className="content-container">
                    <div className="page-title-container">
                        <h3 className="page-title">{error.error}</h3>
                    </div>
                    <div className="instruction-container">
                        <p>{error.detail}</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div id="checkout-page">
            <div className="progress-bar-wrapper">
                {step === 2 && (
                    <div className="go-back-container lg-btn" style={{ position: "absolute" }}>
                        <Link
                            type="button"
                            onClick={() => setStep(1)}
                            className="go-back-btn"
                        >
                            <div className="go-back-arrow-container">
                                <img src={arrowLeft} alt="Arrow left" />
                            </div>
                            <div className="go-back-text-container">
                                <p className="go-back-text">Go Back</p>
                            </div>
                        </Link>
                    </div>
                )}

                <div className="progress-bar-container">
                    <div
                        className="progress-label"
                        style={{ left: `${step === 1 ? 20 : step === 2 ? 40 : 100}%` }}
                    >
                        Payment - {step === 1 ? 20 : step === 2 ? 40 : 100}%
                    </div>
                    <div className="progress-bar">
                        <div
                            className="progress"
                            style={{ width: `${step === 1 ? 20 : step === 2 ? 40 : 100}%` }}
                        ></div>
                    </div>
                </div>
            </div>

            {step === 1 && (
                <CalcPay orderItems={orderItems} handleCalculateClick={handleCalculateClick} />
            )}
            {step === 2 && (
                <CompletePay totalAmount={totalAmount} handlePayClick={handlePayClick} />
            )}
        </div>
    );
};

export default CheckoutPage;
