import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import {
    checkUserExists,
    createUser,
    createPassword,
    clearError,
} from "@/vetvantage/features/signupSlice";
import FormField from "../../common/components/form/fields/FormField";
import Alert from "../../common/components/Alert";
import Logo from "../images/logo_white.svg";
import arrowLeft from "../images/arrow-left.png";
import "./SignupForm.scss";

const validationSchemaStep1 = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    phoneNumber: Yup.string()
        .required("Phone Number is required")
        .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits"),
    email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
});

const validationSchemaStep2 = Yup.object({
    password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(
            /[!@#$%^&*(),.?":{}|<>]/,
            "Password must contain at least one special character"
        )
        .required("Password is required"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
});

const SignupForm = () => {
    const [step, setStep] = useState(1);
    const dispatch = useDispatch();
    const error = useSelector((state) => state.signup.error);
    // Use useLocation to access the current URL
    const location = useLocation();

    // Parse the query parameters using URLSearchParams
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const [serverError, setServerError] = useState("");

    useEffect(() => {
        if (window.contextData?.errorMessage) {
            setServerError(window.contextData.errorMessage);
        }

        dispatch(clearError());

        // Check if token is present, and move to step 2 if so
        if (token) {
            setStep(2);
        }
    }, [dispatch, token]);

    const handleSubmit = async (values) => {
        if (step === 1) {
            const result = await dispatch(
                checkUserExists({
                    email: values.email,
                    phoneNumber: values.phoneNumber,
                })
            );
            if (!result.error) {
                setStep(2);
            }
        } else if (step === 2) {
            const action = token ? createPassword : createUser;
            const result = await dispatch(action(values));
            if (!result.error) {
                setStep(3);
            }
        }
    };

    if (serverError) {
        return (
            <div className="signup-form-container">
                <header className="signup-header">
                    <Link to={"/"} className="back-to-landing"><Logo /></Link>
                </header>
                <div className="signup-form">
                    <h3>Create a Password</h3>
                    <p>
                        The create password link was invalid, possibly because it has already been used. 
                        Please request <Link to={"/accounts/password/reset/"}>a password reset.</Link>
                    </p>
                </div>
                <footer className="signup-footer">
                </footer>
            </div>
        )
    }

    return (
        <div className="signup-form-container">
            <header className="signup-header">
                <Link to={"/"} className="back-to-landing">
                    <Logo />
                </Link>
                <div className="progress-bar-wrapper">
                    {step === 2 && !token && (
                        <div
                            className="go-back-container lg-btn"
                            style={{ position: "absolute" }}
                        >
                            <Link
                                type="button"
                                onClick={() => setStep(1)}
                                className="go-back-btn"
                            >
                                <div className="go-back-arrow-container">
                                    <img src={arrowLeft} alt="Arrow left" />
                                </div>
                                <div className="go-back-text-container">
                                    <p className="go-back-text">Go Back</p>
                                </div>
                            </Link>
                        </div>
                    )}

                    <div className="progress-bar-container">
                        <div
                            className="progress-label"
                            style={{
                                left: `${
                                    step === 1 ? 5 : step === 2 ? 55 : 100
                                }%`,
                            }}
                        >
                            Profile - {step === 1 ? 5 : step === 2 ? 55 : 100}%
                        </div>
                        <div className="progress-bar">
                            <div
                                className="progress"
                                style={{
                                    width: `${
                                        step === 1 ? 5 : step === 2 ? 55 : 100
                                    }%`,
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </header>

            {error && <Alert type="error" message={error} />}

            <Formik
                initialValues={{
                    firstName: "",
                    lastName: "",
                    phoneNumber: "",
                    email: "",
                    password: "",
                    confirmPassword: "",
                    token: token,
                }}
                validationSchema={
                    step === 1 ? validationSchemaStep1 : validationSchemaStep2
                }
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values);
                    setSubmitting(false);
                }}
            >
                {({
                    isSubmitting,
                    values,
                    isValid,
                    setTouched,
                    validateForm,
                }) => (
                    <Form className="signup-form">
                        {step === 1 && (
                            <>
                                <h3>Create Your Profile</h3>
                                <div className="form-row">
                                    <div className="form-group">
                                        <FormField
                                            label="First Name"
                                            name="firstName"
                                            type="text"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <FormField
                                            label="Last Name"
                                            name="lastName"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group">
                                        <FormField
                                            label="Email"
                                            name="email"
                                            type="email"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <FormField
                                            label="Phone Number"
                                            name="phoneNumber"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="button-row">
                                    <button
                                        type="submit"
                                        className="btn primary"
                                    >
                                        Next
                                    </button>
                                </div>
                            </>
                        )}

                        {step === 2 && (
                            <>
                                <h3>Create a Password</h3>
                                <div className="form-row">
                                    <div className="form-group">
                                        <FormField
                                            label="Password"
                                            name="password"
                                            type="password"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <FormField
                                            label="Confirm Password"
                                            name="confirmPassword"
                                            type="password"
                                        />
                                    </div>
                                </div>
                                <div className="button-row">
                                    <button
                                        type="submit"
                                        className="btn primary"
                                        disabled={isSubmitting || !isValid}
                                        onClick={async () => {
                                            setTouched({
                                                password: true,
                                                confirmPassword: true,
                                            });
                                            const errors = await validateForm();
                                            if (
                                                !errors.password &&
                                                !errors.confirmPassword
                                            ) {
                                                // Proceed to submit
                                            }
                                        }}
                                    >
                                        Next
                                    </button>
                                </div>
                            </>
                        )}

                        {step === 3 && (
                            <>
                                <h3>Check Your Email</h3>
                                <p>Click the link to verify your account.</p>
                                <div className="button-row">
                                    <Link
                                        to={"/accounts/login"}
                                        className="btn primary"
                                    >
                                        Return to Login
                                    </Link>
                                </div>
                            </>
                        )}
                    </Form>
                )}
            </Formik>
            <footer className="signup-footer">
                {step === 1 && (
                    <h6>
                        Start for free and pay only for the medical exams. Fees
                        will vary based on your DBQs and other options.
                    </h6>
                )}
            </footer>
        </div>
    );
};

export default SignupForm;
