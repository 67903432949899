import React, { useEffect } from "react";
import { Model } from "survey-core";
import { Survey, ReactElementFactory } from "survey-react-ui";
import { Link, useNavigate } from "react-router-dom";
import { checkIAQ, submitIAQ, setFormData } from "../../features/iaqSlice";
import { useSelector, useDispatch } from "react-redux";
import Alert from "../../../common/components/Alert";
import "./IAQSurvey.scss";
import PercentageProgressBar from "../../../common/components/form/PercentageProgressBar";
import { getProgressData } from "../../features/progressSlice";
import { themeJson } from "../../../common/utils/survey_theme";
import { GoBack } from "../../components/MainUnlock";
import MainUnlock from "../../components/MainUnlock";

ReactElementFactory.Instance.registerElement(
    "sv-progressbar-percentage",
    (props) => {
        return React.createElement(PercentageProgressBar, props);
    }
);

const IAQSurvey = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const status = useSelector((state) => state.iaq.status);
    const currentData = useSelector((state) => state.iaq.currentData);
    const formData = useSelector((state) => state.iaq.formData);
    const error = useSelector((state) => state.iaq.error);
    const loading = useSelector((state) => state.iaq.loading);
    const currentPageNo = useSelector((state) => state.iaq.currentPageNo);

    useEffect(() => {
        const fetchData = async () => {
            const response = await dispatch(checkIAQ());

            // Check if the response contains a redirect_url
            if (response.payload?.redirect_url) {
                // Redirect to the provided URL
                navigate(response.payload.redirect_url);
            }
        };

        fetchData();
    }, [dispatch, navigate]);

    const handleSurveySubmit = async (survey, action, currentPageNo = null) => {
        const surveyData = {
            data: JSON.parse(JSON.stringify(survey)),
            currentPageNo: currentPageNo,
            action: action,
        };
        await dispatch(submitIAQ(surveyData));
        await dispatch(setFormData(surveyData.data));
        await dispatch(getProgressData());
    };

    if (!formData || loading || !currentData) {
        return (
            <div className="loading-spinner-container">
                <div
                    style={{
                        height: "80px",
                        width: "80px",
                        borderWidth: "8px",
                    }}
                    className="loader"
                ></div>
            </div>
        );
    }

    if (status === "not_started") {
        return (
            <MainUnlock
                step_title={"Initial Assessment Questionnaire (IAQ)"}
                step_btn_text={"Start IAQ"}
                onClick={() => {
                    handleSurveySubmit(currentData, "start");
                }}
                message={`This questionnaire serves as the first step towards determining eligibility for our application, 
                    tailored to help veterans navigate the process of securing a VA increase. 
                    By providing essential information through this assessment, we can ensure personalized support and guidance tailored to your needs. 
                    Your responses are crucial in assisting us in understanding your circumstances better 
                    and offering the most effective assistance.`}
            />
        );
    }

    if (status === "completed") {
        return (
            <MainUnlock
                step_title={"Great News!"}
                step_btn_text={"Next"}
                btn_url={"/itf/unlocked"}
                hideGoBack
            >
                <div className="instruction-container">
                    <p>
                        Looks like you might qualify for an increase.
                        Please click “Next” to continue with the
                        process.
                    </p>
                </div>
            </MainUnlock>
        );
    }

    if (status === "failed") {
        return (
            <div className="page-container-iaq-survey">
                <div className="page-top">
                    <GoBack />
                </div>
                <div className="page-body">
                    <div className="content-container">
                        <div className="page-title-container">
                            <h3>
                                The information you provided is either
                                insufficient or disqualifies you from disability
                                benefits. Would you like to proceed anyway?
                            </h3>
                        </div>
                        <div className="page-btn-container">
                            <button
                                type="button"
                                className="btn primary"
                                onClick={() => {
                                    handleSurveySubmit(currentData, "lock");
                                }}
                            >
                                Yes
                            </button>
                            <button
                                type="button"
                                className="btn primary"
                                onClick={() => {
                                    handleSurveySubmit(currentData, "go-back");
                                }}
                            >
                                No, Go Back
                            </button>
                        </div>
                        <div className="instruction-container">
                            <p>
                                If you answer Yes to this question, your account
                                will be suspended. Learn more about eligibility
                                for disability benefits{" "}
                                <Link to={"/iaqs/eligibility"}>here.</Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (status === "locked") {
        return (
            <div className="page-container-iaq-survey">
                <div className="page-top">
                    <GoBack />
                </div>
                <div className="page-body">
                    <div className="content-container">
                        <div className="page-title-container">
                            <h3>
                                Your IAQ survey is locked. If you believe this
                                is the result of a mistake and you would like us
                                to unblock it, please submit a request to our
                                support team.
                            </h3>
                        </div>
                        <div className="page-btn-container">
                            <Link
                                to={"/iaqs/eligibility"}
                                className="btn primary"
                            >
                                Eligibility Requirements
                            </Link>
                            <Link
                                href="/settings/manage/support"
                                className="btn primary"
                            >
                                Request Account Reactivation
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const surveyModel = new Model(formData);
    surveyModel.applyTheme(themeJson);
    surveyModel.data = currentData;
    surveyModel.css = {
        navigationButton: "btn primary"
    };

    surveyModel.onValueChanged.add(function (_, options) {
        if (options.name === "A copy of your VA.Gov account is required to verify your service record. Would you like to learn how to access your VA.Gov account?" && options.value === true) {
            window.location.href = "https://www.va.gov/resources/how-to-access-my-healthevet-on-vagov/";
        }
    });

    surveyModel.addLayoutElement({
        id: "progressbar-percentage",
        component: "sv-progressbar-percentage",
        container: "contentTop",
        data: surveyModel,
    });

    if (error === "Invalid payment amount entry.") {
        surveyModel.currentPage = surveyModel.getPageByName("page21");
    } else {
        surveyModel.currentPageNo = currentPageNo;
    }

    return (
        <>
            {error && <Alert type="error" message={error} />}

            <div className="page-container-iaq-survey">
                <Survey
                    model={surveyModel}
                    onComplete={(survey) => {
                        handleSurveySubmit(
                            survey.data,
                            "complete",
                            survey.currentPageNo
                        );
                    }}
                    onCurrentPageChanged={(survey) => {
                        if (survey.isCompleted) return;
                        if (survey.currentPageNo != 0) {
                            handleSurveySubmit(
                                survey.data,
                                "proceed",
                                survey.currentPageNo
                            );
                        }
                    }}
                />
            </div>
        </>
    );
};

export default IAQSurvey;
