import React, { useState, useEffect } from "react";

import { Outlet } from "react-router-dom";

import Navbar from "../components/Navbar";
import SideBar from "../components/Sidebar";
import BottomNavBar from "../components/BottomNavBar";
import Tutorial from "../components/tutorial/Tutorial";
import "./layout.scss";

const Layout = () => {
    const [showTutorial, setShowTutorial] = useState(false);

    useEffect(() => {
        const tutorial = localStorage.getItem("tutorial");
        if (!tutorial) {
            setShowTutorial(true);
        }
    }, []);
    return (
        <div id="layout">
            {showTutorial && <Tutorial />}
            <Navbar />
            <div id="layout-inner">
                <SideBar />
                <main>
                    <Outlet />
                </main>
            </div>
            <BottomNavBar />
        </div>
    );
};

export default Layout;
