import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance, cachedAxiosInstance } from "@/common/utils/axios";
import { getCookie } from "@/common/utils/cookies";

export const login = createAsyncThunk(
    "auth/login",
    async (credentials, thunkAPI) => {
        try {
            const response = await axiosInstance.post(
                "/accounts/login/",
                {
                    email: credentials.email,
                    password: credentials.password,
                    remember_me: credentials.rememberMe,
                },
                {
                    headers: {
                        "X-CSRFToken": getCookie("csrftoken"),
                    },
                }
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.error?.details || "Failed to login."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
    try {
        const response = await cachedAxiosInstance.post("/accounts/logout/", {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return await response.data;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const errorData = error.response.data;
            return thunkAPI.rejectWithValue(
                errorData?.error?.details || "Failed to logout."
            );
        } else if (error.request) {
            // The request was made but no response was received
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
});

export const emailPasswordReset = createAsyncThunk(
    "auth/emailPasswordReset",
    async (values, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.post(
                "/accounts/password/reset/",
                values,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.error?.details || "Failed to reset password."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const resetPassword = createAsyncThunk(
    "auth/resetPassword",
    async ({ values, token }, thunkAPI) => {
        try {
            const formData = new FormData();
            formData.append("password1", values.password);
            formData.append("password2", values.confirmPassword);

            const response = await cachedAxiosInstance.post(
                `/accounts/password/reset/key/${token}/`,
                formData
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.error?.details || "Failed to reset password."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        status: "idle",
        error: null,
        redirectUrl: null,
    },
    reducers: {
        clearRedirectUrl: (state) => {
            state.redirectUrl = null;
        },
        resetStatus: (state) => {
            state.status = "idle";
        },
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.status = "pending";
            })
            .addCase(login.fulfilled, (state, action) => {
                state.state = "succeeded";
                state.redirectUrl = action.payload?.data?.redirect_url;
                state.error = null;
            })
            .addCase(login.rejected, (state, action) => {
                state.status = "failed";
                state.redirectUrl = null;
                state.error = action.payload;
            })
            .addCase(logout.pending, (state) => {
                state.status = "pending";
            })
            .addCase(logout.fulfilled, (state) => {
                state.state = "succeeded";
                state.user = null;
                state.error = null;
            })
            .addCase(logout.rejected, (state) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(emailPasswordReset.pending, (state) => {
                state.status = "pending";
            })
            .addCase(emailPasswordReset.fulfilled, (state) => {
                state.status = "succeeded";
                state.error = null;
            })
            .addCase(emailPasswordReset.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(resetPassword.pending, (state) => {
                state.status = "pending";
            })
            .addCase(resetPassword.fulfilled, (state) => {
                state.status = "succeeded";
                state.error = null;
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            });
    },
});

export const { clearRedirectUrl, resetStatus, clearError } = authSlice.actions;

export default authSlice.reducer;
