import React from "react";
import Logo from "../../images/logo.png";
import "./Errors.scss";
import { Link } from "react-router-dom";

const ServerError = () => {
    return (
        <div id="error-page">
            <div className="body">
                <div className="container">
                    <img src={Logo} style={{
                        width: "100%",
                        maxWidth: "400px",
                        height: "auto",
                        padding: "1rem",
                    }} />
                    <h4 className="error-header">- Error -</h4>
                    <h1 className="error-header">500</h1>
                    <h4 className="error-header">Oops! Something went wrong</h4>
                    <h4 class="error-header">We have an Internal Server Error.</h4>
                    <p className="error-header">
                        Please try again later or click the button below.
                    </p>
                    <Link className="btn primary" to="/">
                        Go Home
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ServerError;
