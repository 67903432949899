import { cachedAxiosInstance } from "../utils/axios";

export const getCSRFToken = async () => {
    try {
        const response = await cachedAxiosInstance.get("/get-csrf-token/");
        return await response.data;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const errorData = error.response.data;
            throw new Error(
                errorData?.error?.details || "Failed to get CSRF Token"
            );
        } else if (error.request) {
            // The request was made but no response was received
            throw new Error(error.message);
        } else {
            throw new Error(error.message);
        }
    }
};
