import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getItfSuccessData } from "../../features/itf/itfSlice";
import MainUnlock from "../../components/MainUnlock";
import Spinner from "../../components/Spinner";

const ITFSuccess = () => {
    const dispatch = useDispatch();
    const { successData, status } = useSelector((state) => state.itf);

    useEffect(() => {
        dispatch(getItfSuccessData());
    }, [dispatch]);

    if (status === "loading") {
        return <Spinner />;
    }

    if (!successData) {
        return null;
    }

    return (
        <MainUnlock
            step_title={successData.title}
            step_btn_text={"Next"}
            btn_url={successData.next_url}
        >
            <div className="instruction-container">
                <p className="text-half-width">{successData.success_msg}</p>
            </div>
        </MainUnlock>
    );
};

export default ITFSuccess;
