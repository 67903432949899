export const getPageTitle = (pathname) => {
    const routeTitles = {
        // Examples title routes
        // "/dashboard": "Dashboard",
        // "/products/orders": "Orders",
        // "/downloads": "Downloads",
        "/settings": "Settings",
        // Add more routes and their corresponding titles here
    };

    // Check if the current path exactly matches any of the defined routes
    if (routeTitles[pathname]) {
        return routeTitles[pathname];
    }

    // For nested routes, check if the pathname starts with any defined route
    for (const route in routeTitles) {
        if (pathname.startsWith(route)) {
            return routeTitles[route];
        }
    }

    // Default title if no match is found
    return "";
};
