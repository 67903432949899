import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getProgressData } from "../features/progressSlice";
import ChartColumn from "@/common/images/icons/chartcolumn.svg";
import IconComponent from "./IconComponent";
import ProgressCircle from "./progress_circle/ProgressCircle";
import "./sidebar.scss";
import SidebarFAQ from "../pages/sidebar/SidebarFAQ";

const SideBar = () => {
    const dispatch = useDispatch();
    const { progress, steps, status, error } = useSelector(
        (state) => state.progress
    );

    const isLoading = useMemo(
        () => status == "pending" || status == "idle",
        [status]
    );

    useEffect(() => {
        dispatch(getProgressData());
    }, []);

    return (
        <div id="side-bar">
            <nav id="side-bar-nav">
                {isLoading ? (
                    <div className="loading-spinner-container">
                        <div className="loader"></div>
                    </div>
                ) : (
                    <>
                        <ProgressCircle valueStart={0} valueEnd={progress} />
                        <div className="progress-steps-header">
                            <ChartColumn height={32} width={32} />
                            <span>Progress</span>
                        </div>
                        <div className="progress-steps">
                            {/* TODO: Replace with react-router NavLink*/}
                            {steps.map((step, i) =>
                                step.react ? (
                                    <NavLink
                                        key={step.id}
                                        to={step.path}
                                        className={`step ${step.status}`}
                                        onClick={(e) =>
                                            step.status === "locked" &&
                                            e.preventDefault()
                                        }
                                    >
                                        <div className="step-inner">
                                            <IconComponent
                                                iconKey={step.id}
                                                iconSize={18}
                                            />
                                            <span>{step.name}</span>
                                        </div>
                                    </NavLink>
                                ) : (
                                    <a
                                        key={step.id}
                                        href={step.path}
                                        className={`step ${step.status}`}
                                        onClick={(e) =>
                                            step.status === "locked" &&
                                            e.preventDefault()
                                        }
                                    >
                                        <div className="step-inner">
                                            <IconComponent
                                                iconKey={step.id}
                                                iconSize={18}
                                            />
                                            <span>{step.name}</span>
                                        </div>
                                    </a>
                                )
                            )}
                        </div>
                    </>
                )}
            </nav>
            <SidebarFAQ />
        </div>
    );
};

export default SideBar;
