import React from "react";
import "./AccountVerified.scss";
import { Link } from "react-router-dom";

const AccountVerified = () => {
    return (
        <div id="account-verified">
            <div className="account-verified-wrapper">
                <ProgressBar />
                <h1 className="success-font">Account Verified!</h1>
                <h2>Are You Ready to Get the Benefits You Deserve?</h2>
                <Link to="/accounts/login" className="btn primary">
                    Get Started Now
                </Link>
            </div>
        </div>
    );
};

const ProgressBar = () => {
    return (
        <div className="progress-bar-wrapper">
            <div className="progress-bar-container">
                <div
                    className="progress-label"
                    style={{
                        left: `100%`,
                    }}
                >
                    Profile - 100%
                </div>
                <div className="progress-bar">
                    <div
                        className="progress"
                        style={{
                            width: `100%`,
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default AccountVerified;
