import React, { useRef, useEffect } from "react";
import "./appt_details_modal.scss";
import CloseModalBtn from "../../components/CloseModalBtn";
import { useNavigate } from "react-router-dom";

const ApptDetailModal = ({ isOpen, onClose, apptDetails, apptUrl }) => {
    const modalRef = useRef(null);
    const navigate = useNavigate();
    const isPending = apptUrl?.includes("unlocked");
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, onClose, apptUrl]);

    if (!isOpen) return null;

    return (
        <div className="modal">
            <div className="modal-content" ref={modalRef}>
                <CloseModalBtn onClose={onClose} />
                <h2 className="page-title">Appointment Details</h2>
                <p className="modal-txt">
                    Status: {isPending ? "Pending" : "Approved"}
                </p>
                <p className="modal-txt">Your appointment is scheduled for</p>

                <p className="modal-txt">{apptDetails.start_datetime}</p>
                <div className="modal-btn-container">
                    {isPending ? (
                        <div className="btn-column">
                            <button
                                className="btn-primary"
                                onClick={() => {
                                    navigate("/appointments/cancel/");
                                }}
                            >
                                <h6 className="btn-text">Cancel</h6>
                            </button>
                            <button
                                className="btn-primary"
                                onClick={() => {
                                    window.location.href = `/appointments/select-datetime/?is_reschedule=true`;
                                }}
                            >
                                <h6 className="btn-text">Reschedule</h6>
                            </button>
                            <button
                                className="btn-primary"
                                onClick={() => {
                                    navigate(
                                        "/appointments/cancel/?change_doctor=true"
                                    );
                                }}
                            >
                                <h6 className="btn-text">Change Doctor</h6>
                            </button>
                        </div>
                    ) : (
                        <>
                            <div className="btn-column">
                                <button
                                    className="btn-primary"
                                    onClick={() => {
                                        window.location.href =
                                            "/appointments/cancel/";
                                    }}
                                >
                                    <h6 className="btn-text">Cancel</h6>
                                </button>
                                <button
                                    className="btn-primary"
                                    onClick={() => {
                                        window.location.href =
                                            "/appointments/change_doctor/";
                                    }}
                                >
                                    <h6 className="btn-text">Change Doctor</h6>
                                </button>
                                <button
                                    className="btn-primary-outline"
                                    onClick={() => {
                                        //TODO Change to Navigate after React migration
                                        window.location.href = apptUrl;
                                    }}
                                >
                                    <h6 className="btn-outline-text">
                                        Details
                                    </h6>
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ApptDetailModal;
