import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { getCSRFToken } from "@/common/api/auth";

import Router from "./Router";

const App = () => {
    useEffect(() => {
        getCSRFToken();
    }, []);

    return <RouterProvider router={Router} />;
};

export default App;
