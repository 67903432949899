import React from "react";

import { formatDate } from "../utils";

const MedHistTableRow = ({
    id,
    fileName,
    checklistItemName,
    timestamp,
    modalHandler,
    isRequired = false,
}) => {
    if (timestamp) {
        return (
            <tr key={id} className={isRequired ? "grey-bg" : null}>
                <td className="text-el truncate-text">
                    {fileName ? fileName : "-"}
                </td>
                <td className="text-el truncate-text">
                    {checklistItemName ? checklistItemName : "-"}
                </td>
                <td>
                    <div className="btn-wrapper">
                        <div className="btn outline">Uploaded</div>
                    </div>
                </td>
                <td className="text-el">{formatDate(timestamp)}</td>
                <td>
                    <div className="btn-wrapper">
                        <button
                            type="button"
                            className="btn primary"
                            onClick={() =>
                                modalHandler(
                                    "delete",
                                    id,
                                    checklistItemName,
                                    fileName
                                )
                            }
                        >
                            {"Delete"}
                        </button>
                    </div>
                </td>
            </tr>
        );
    } else {
        return (
            <tr key={id} className={isRequired ? "grey-bg" : null}>
                <td className="text-el truncate-text">
                    {fileName ? fileName : "-"}
                </td>
                <td className="text-el truncate-text">
                    {checklistItemName ? checklistItemName : "-"}
                </td>
                <td>
                    <div className="btn-wrapper">
                        <div className="btn outline">Not Uploaded</div>
                    </div>
                </td>
                <td className="text-el">-</td>
                <td>
                    <div className="btn-wrapper">
                        <button
                            type="button"
                            className="btn primary"
                            onClick={() =>
                                modalHandler("upload", id, checklistItemName)
                            }
                        >
                            Upload
                        </button>
                    </div>
                </td>
            </tr>
        );
    }
};

export default MedHistTableRow;
