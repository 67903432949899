// replace json reserved characters
export const replaceSpecialChars = (string) =>{
    return string.replace(/\\'/g, "\\'")
        .replace(/\\&/g, "\\&")
        .replace(/\\r/g, "\\r") // carriage return
        .replace(/\\t/g, "\\t") // tab
        .replace(/\\b/g, "\\b") // backspace
        .replace(/\\f/g, "\\f") // form feed
        .replace(/\\"/g, "$1’") // double quote
        .replace(/([a-zA-Z])'\\s/g, "$1’") // single quote with alphabetic chars right after it
        .replace(/\\'/g, ' "') // backslash
        .replace(/\\\\/g, " ") // backslash
        .replace(/ {3,}/g, " ") // multiple spaces (more than 2 spaces)
        .replace(/\\n/g, "\\\\n") // new line
        .replace(/`/g, "") // backtick
        .replace(/([a-zA-Z])'(?=[a-zA-Z])/g, "$1’"); // single quote with alphabetic chars right after it
};
