import React, { useState, useEffect } from "react";
import "./Pagination.scss";
import Arrow from "../../images/arrow-right.svg";

const Pagination = ({
    currentPage,
    total_pages,
    onPageChange,
    itemsPerPage,
    onItemsPerPageChange,
}) => {
    const handlePageChange = (page) => {
        if (page >= 1 && page <= total_pages) {
            onPageChange && onPageChange(page);
        }
    };

    const handleItemsPerPageChange = (value) => {
        onItemsPerPageChange && onItemsPerPageChange(value);
    };
    return (
        <div className="pagination-container">
            <div id="pagination">
                {/* Input to change the number of items per page */}
                <div className="pagination-item">
                    <span>Show</span>
                    <select
                        value={itemsPerPage}
                        onChange={(e) =>
                            handleItemsPerPageChange(Number(e.target.value))
                        }
                    >
                        {[5, 10, 25, 50, 100].map((value) => (
                            <option key={value} value={value}>
                                {value}
                            </option>
                        ))}
                    </select>
                    <span className="hide-text">per page</span>
                </div>
                {/* Input to change the page number */}
                <div className="pagination-item">
                    <span>page</span>
                    <select
                        value={currentPage}
                        onChange={(e) =>
                            handlePageChange(Number(e.target.value))
                        }
                    >
                        {Array.from({ length: total_pages }, (_, i) => (
                            <option key={i + 1} value={i + 1}>
                                {i + 1}
                            </option>
                        ))}
                    </select>
                    <span className="hide-text">of {total_pages}</span>
                </div>
                <div className="pagination-buttons-container">
                    {/* Button to go to the previous page */}
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="pagination-button btn-left"
                        title="Previous"
                    >
                        <Arrow width={20} height={20} />
                    </button>
                    {/* Button to go to the next page */}
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === total_pages}
                        className="pagination-button btn-right"
                        title="Next"
                    >
                        <Arrow width={20} height={20} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Pagination;
