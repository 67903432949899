import React, { useEffect, useState, useRef, useCallback } from "react";
import ZoomVideo from "@zoom/videosdk"; // Import the Zoom Video SDK
import { useNavigate } from "react-router-dom";
import Alert from "../../common/components/Alert";
import testSound from "../../common/images/test-sound.mp3";
import "./testaudiovideo.scss";
import AdminLoadingSpinner from "../../vetvantage/components/admin/AdminLoadingSpinner";

const CameraAudioTestPage = () => {
    const [cameraOn, setCameraOn] = useState(false);
    const [initDone, setInitDone] = useState(false);
    const [testSoundPlayed, setTestSoundPlayed] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [alertDuration, setAlertDuration] = useState(5000);
    const [zmClient, setZmClient] = useState();
    const [mediaStream, setMediaStream] = useState();
    const [localVideoTrack, setLocalVideoTrack] = useState();
    const [localAudioTrack, setLocalAudioTrack] = useState();
    const [appointment, setAppointment] = useState({});
    const [videoDevices, setVideoDevices] = useState([]);
    const [audioDevices, setAudioDevices] = useState([]);
    const [speakerDevices, setSpeakerDevices] = useState([]);
    const [microphoneTester, setMicrophoneTester] = useState();

    const videoElementRef = useRef(null); // Reference for the video preview element
    const audioTestRef = useRef(new Audio(testSound)); // Test sound for speakers
    const navigate = useNavigate();

    // Trigger Alert
    const triggerAlert = useCallback((type, message, duration = 5000) => {
        setAlertType(type);
        setAlertMessage(message);
        setAlertDuration(duration);
    }, []);

    // Initialize Zoom SDK (but don't start audio or video yet)
    useEffect(() => {
        const initZoom = async () => {
            try {
                const scriptTag = document.querySelector(
                    "script[data-zoomjwt]"
                );
                const client = ZoomVideo.createClient();
                const appointment = JSON.parse(
                    scriptTag.getAttribute("data-appointment")
                );

                await client.init("en-US", "Global", {
                    patchJsMedia: true,
                });

                let devices = await ZoomVideo.getDevices();

                let videoDevices = [];
                let microphoneDevices = [];
                let speakerDevices = [];

                devices.forEach((device) => {
                    if (device.kind === "videoinput") {
                        videoDevices.push(device);
                    }
                    if (device.kind === "audioinput") {
                        microphoneDevices.push(device);
                    }
                    if (device.kind === "audiooutput") {
                        speakerDevices.push(device);
                    }
                });

                const mediaStream = client.getMediaStream();

                let localVideoTrack = ZoomVideo.createLocalVideoTrack(
                    videoDevices[0].deviceId
                );
                let localAudioTrack = ZoomVideo.createLocalAudioTrack(
                    microphoneDevices[0].deviceId
                );

                setLocalVideoTrack(localVideoTrack);
                setLocalAudioTrack(localAudioTrack);

                setVideoDevices(videoDevices);
                setAudioDevices(microphoneDevices);
                setSpeakerDevices(speakerDevices);

                setZmClient(client);
                setMediaStream(mediaStream);

                setInitDone(true);
                setAppointment(appointment);
            } catch (error) {
                console.error("Error initializing Zoom SDK:", error);
                triggerAlert("error", "Failed to initialize Zoom SDK");
            }
        };

        initZoom();

        return () => {
            if (zmClient && mediaStream) {
                if (mediaStream) {
                    mediaStream.stopVideo();
                    mediaStream.stopAudio();
                }
                zmClient.leave();
            }
        };
    }, []);

    // Function to toggle camera on/off
    const handleCameraToggle = async () => {
        const videoTestElement = document.getElementById("video-test");
        const previewCameraEl = document.getElementById("preview-camera-video");

        if (cameraOn) {
            // Stop the camera
            localVideoTrack.stop();
            setCameraOn(false);
            triggerAlert("info", "Camera turned off");

            if (videoTestElement) {
                videoTestElement.style.height = "auto"; // Set the desired default height when camera is off
                videoTestElement.children[1].style.height = "50px";
                previewCameraEl.style.height = "0px";
            }
        } else {
            // Start the camera
            localVideoTrack.start(previewCameraEl);
            setCameraOn(true);
            triggerAlert("success", "Camera turned on");

            if (videoElementRef.current) {
                if (videoTestElement) {
                    previewCameraEl.style.height = "auto";
                    videoTestElement.children[1].style.height = "550px";
                    videoTestElement.classList.add("camera-on"); // Add class when video is ready
                }
            }
        }
    };

    // Function to toggle microphone on/off
    const handleMicrophoneToggle = async (event) => {
        const target = event.target;
        const inputLevelElm = document.querySelector("#mic-input-level");
        const value = target.dataset["start"];
        let newMicTester;
        if (value === "1" || value === "3") {
            if (microphoneTester) {
                microphoneTester.stop();
                target.dataset["start"] = "0";
                target.textContent = "Start Microphone";
            }
        } else if (!value || value === "0") {
            newMicTester = localAudioTrack.testMicrophone({
                microphoneId: audioDevices[0].deviceId,
                speakerId: speakerDevices[0].deviceId,
                onAnalyseFrequency: (v) => {
                    inputLevelElm.value = v;
                },
                recordAndPlay: true,
                onStartRecording: () => {
                    target.textContent = "Recording...";
                    target.dataset["start"] = "2";
                },
                onStartPlayRecording: () => {
                    target.textContent = "Playing...";
                    target.dataset["start"] = "3";
                },
                onStopPlayRecording: () => {
                    target.textContent = "Stop test";
                    target.dataset["start"] = "1";
                },
            });
            setMicrophoneTester(newMicTester);
            target.dataset["start"] = "1";
            target.textContent = "Stop test";
        } else if (value === "2") {
            microphoneTester.stopRecording();
        }
    };

    // Play test sound for speaker test
    const handlePlayTestSound = () => {
        if (audioTestRef.current) {
            audioTestRef.current.play();
            setTestSoundPlayed(true);
            triggerAlert("success", "Test sound played");
        }
    };

    // Handle navigation to video meeting page
    const handleProceed = () => {
        if (localVideoTrack) {
            localVideoTrack.stop();
        }
        if (localAudioTrack) {
            localAudioTrack.stop();
        }
        window.location.href = "/appointments/summary/"; // Redirect to the video meeting page
    };

    // Handle alert timeout
    useEffect(() => {
        if (alertMessage) {
            const timer = setTimeout(() => setAlertMessage(""), alertDuration);
            return () => clearTimeout(timer);
        }
    }, [alertMessage, alertDuration]);

    if (!initDone) {
        return <AdminLoadingSpinner />;
    }
    return (
        <div className="camera-audio-test-page">
            {alertMessage && <Alert type={alertType} message={alertMessage} />}

            <h2>Test Your Camera and Audio</h2>
            <p>
                Ensure your camera, microphone, and speakers are working
                properly before joining the meeting.
            </p>

            {/* Camera Test */}
            <div id="video-test" className="camera-test">
                <h3>Camera Preview</h3>
                <video
                    id="preview-camera-video"
                    ref={videoElementRef}
                    autoPlay
                    playsInline
                />
                {cameraOn ? <p>Camera is working!</p> : <p>Camera is off</p>}
                <div className="btn-container">
                    <button onClick={handleCameraToggle}>
                        {cameraOn ? "Turn Off Camera" : "Start Camera"}
                    </button>
                </div>
            </div>

            {/* Microphone Test */}
            <div className="microphone-test">
                <h3>Microphone Test</h3>
                <p>
                    Click the button below to start recording your audio. When
                    you are done, it will playback your recorded audio
                </p>
                <div className="input-lvl-container">
                    <label className="progress-label" htmlFor="mic-input-level">
                        Input level:
                    </label>
                    <progress
                        className="mic-input-level"
                        id="mic-input-level"
                        max="100"
                        value="0"
                    ></progress>
                </div>
                <div className="btn-container">
                    <button onClick={handleMicrophoneToggle}>
                        {"Start Microphone"}
                    </button>
                </div>
            </div>

            {/* Speaker Test */}
            <div className="speaker-test">
                <h3>Speaker Test</h3>
                <div className="btn-container">
                    <button onClick={handlePlayTestSound}>
                        Play Test Sound
                    </button>
                    {testSoundPlayed && <p>Did you hear the sound?</p>}
                </div>
            </div>

            {/* Proceed Button */}
            <div className="controls">
                <div className="btn-container">
                    <button onClick={handleProceed}>
                        Back To Appointment Detail
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CameraAudioTestPage;
