import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { cachedAxiosInstance, axiosInstance } from "@/common/utils/axios";

export const getApptUnlockedPageData = createAsyncThunk(
    "appointments/unlocked",
    async (_, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.get(
                "/appointments/api/unlocked/"
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to get page data."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const checkApptExistence = createAsyncThunk(
    "appointments/cancel/check",
    async (_, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.get(
                "/appointments/api/cancel/"
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail ||
                        "Failed to check appointment existence."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const cancelAppointment = createAsyncThunk(
    "appointments/cancel/confirm",
    async (postData, thunkAPI) => {
        try {
            const response = await axiosInstance.post(
                "/appointments/api/cancel/",
                postData
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to cancel appointment."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

const initialSubmissionState = {
    status: "idle",
    error: null,
};

export const appointmentSlice = createSlice({
    name: "appointment",
    initialState: {
        appointment: null,
        status: "idle",
        error: null,
        submission: initialSubmissionState,
    },
    reducers: {
        // reset submission state obj to it's initial value
        resetSubmission: (state, _) => {
            state.submission = initialSubmissionState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getApptUnlockedPageData.pending, (state) => {
                state.status = "pending";
                state.error = null;
            })
            .addCase(getApptUnlockedPageData.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.appointment = action.payload;
                state.error = null;
            })
            .addCase(getApptUnlockedPageData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(checkApptExistence.pending, (state) => {
                state.status = "pending";
                state.error = null;
            })
            .addCase(checkApptExistence.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.appointment = action.payload;
                state.error = null;
            })
            .addCase(checkApptExistence.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(cancelAppointment.pending, (state) => {
                state.submission.status = "pending";
                state.submission.error = null;
            })
            .addCase(cancelAppointment.fulfilled, (state) => {
                state.submission.status = "succeeded";
                state.submission.error = null;
            })
            .addCase(cancelAppointment.rejected, (state, action) => {
                state.submission.status = "failed";
                state.submission.error = action.payload;
            });
    },
});

export const { resetSubmission } = appointmentSlice.actions;
export default appointmentSlice.reducer;
