// Utility functions for ITF (Intent to File) operations

import FileSaver from "file-saver";
import { PDFDocument, StandardFonts } from "pdf-lib";
import { handleGroupMapping } from "./grouping";
import { getCookie } from "../../common/utils/cookies";
import { cachedAxiosInstance } from "@/common/utils/axios";
import { handleError } from "../features/itf/itfSlice";
import { replaceSpecialChars } from "../../common/utils/helpers";

export const scale = 2.1;

/**
 * Process the ITF (Intent to File) document
 * @param {string} pdfUrl - URL of the PDF template
 * @param {string} data - JSON string containing form data
 * @param {string} type - 'download' or 'upload'
 * @returns {Promise<boolean>} - True if successful, throws error otherwise
 */
export async function processITF({ pdfUrl, data, type = "download" }) {
    try {
        const pdfDoc = await preparePdfDocument(pdfUrl, data);

        if (type === "download") {
            const pdfBytes = await pdfDoc.save();
            await downloadPdf(pdfBytes);
            return true;
        } else if (type === "upload") {
            const pdfB64 = await pdfDoc.saveAsBase64();
            const result = await uploadPdf(pdfB64);

            return result;
        }
    } catch (error) {
        throw new Error(handleError(error, "Failed to process ITF"));
    }
}

// Prepare the PDF document with form data
async function preparePdfDocument(pdfUrl, data) {
    // Parse data object and remove line breaks
    const parsedData = JSON.parse(data.replace(/\\\\n/g, " "));
    const existingPdfBytes = await fetch(pdfUrl).then((res) =>
        res.arrayBuffer()
    );
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontSize = 10;

    // Get pdf form fields
    const form = pdfDoc.getForm();
    const fields = form.getFields();
    const newFields = prepareFields(fields);

    mapDataToPdf(parsedData, pdfDoc, newFields, helveticaFont, fontSize);
    lockAllFields(fields);

    return pdfDoc;
}

// Prepare fields by removing backslashes from field names
function prepareFields(fields) {
    return fields.map((field) => ({
        field,
        fieldName: removeBackslashes(field.getName()),
    }));
}

// Remove backslashes from field name
function removeBackslashes(fieldName) {
    return fieldName.split("\\").join("");
}

// Map data to PDF form fields
function mapDataToPdf(data, pdfDoc, newFields, helveticaFont, fontSize) {
    const pdfPages = pdfDoc.getPages();
    const form = pdfDoc.getForm();

    data.pages.forEach((page) => {
        page.forEach((group) => {
            if (group.type === "matrixdynamic") {
                // Handle table's mapping
                const props = group.propertiesList[0].cells;
                props.forEach((tableCell) => {
                    handleGroupMapping(
                        tableCell,
                        pdfDoc,
                        newFields,
                        pdfPages,
                        form,
                        helveticaFont,
                        fontSize
                    );
                });
            } else {
                // Handle mapping for a group of type other than table
                handleGroupMapping(
                    group,
                    pdfDoc,
                    newFields,
                    pdfPages,
                    form,
                    helveticaFont,
                    fontSize
                );
            }
        });
    });
}

// Lock all fields (make them read-only)
function lockAllFields(fields) {
    fields.forEach((field) => field.enableReadOnly());
}

async function downloadPdf(pdfBytes) {
    try {
        const byteArray = new Uint8Array(pdfBytes);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        FileSaver(url, "itf_completed.pdf");

        return true;
    } catch (error) {
        throw new Error(response?.data?.message || "Failed to Download ITF");
    }
}

// Upload the PDF file
async function uploadPdf(pdfB64) {
    const formData = new FormData();
    formData.append("file", pdfB64);
    formData.append("csrfmiddlewaretoken", getCookie("csrftoken"));

    return new Promise((resolve, reject) => {
        cachedAxiosInstance
            .post("/itf/api/send-itf/", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                if (response.error) {
                    throw new Error(
                        response?.message || "Failed to upload ITF"
                    );
                }
                resolve({
                    success: true,
                });
            })
            .catch((error) => {
                reject(error);
            });
    });
}

/**
 * Process the claims document
 * @param {string} pdfUrl - URL of the PDF template
 * @param {string} data - JSON string containing form data
 * @param {string} type - 'download' or 'upload'
 * @returns {Promise<boolean>} - True if successful, throws error otherwise
 */
export async function processClaims({ pdfUrl, data, type = "download" }) {
    try {
        const pdfDoc = await preparePdfDocument(pdfUrl, data);

        if (type === "download") {
            const pdfBytes = await pdfDoc.save();
            await downloadPdfClaims(pdfBytes);
            return true;
        } else if (type === "upload") {
            const pdfB64 = await pdfDoc.saveAsBase64();
            const result = await uploadPdfClaims(pdfB64);

            return result;
        }
    } catch (error) {
        throw new Error(handleError(error, "Failed to process claims"));
    }
}
async function downloadPdfClaims(pdfBytes) {
    try {
        const byteArray = new Uint8Array(pdfBytes);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        FileSaver(url, "526EZ_complete.pdf");

        return true;
    } catch (error) {
        throw new Error(response?.data?.message || "Failed to Download Claims");
    }
}
/**
 * Retrieve a client or doctor PAQ with data required for downloading
 * @param {number} id - The id of the clients 526EZ Claim
 * @returns {Promise} Promise, resolves when claim retrieved successfully, rejects if failed.
 */
const getClaimData = async (claimId) => {
    return new Promise((resolve, reject) => {
        fetch(`/claims/get_data/${claimId}/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookie("csrftoken"),
            },
        })
            .then((resp) => {
                if (!resp.ok) reject(new Error("Failed to get 526EZ data"));
                resolve(resp.json());
            })
            .catch((err) => {
                reject(err);
            });
    });
};

/**
 * Retrieve a client PAQ with data required for uploading
 * @param {number} paqId - The id of the client PAQ
 * @returns {Promise} Promise, resolves when dbq retrieved successfully, rejects if failed.
 */
const getPaqData = (documentId) => {
    return new Promise((resolve, reject) => {
        fetch(`/claims/get_daq_results/${documentId}/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": getCookie("csrftoken"),
            },
        })
            .then((resp) => {
                if (!resp.ok) reject(new Error("Failed to get PAQ Data"));
                resolve(resp.json());
            })
            .catch((err) => {
                reject(err);
            });
    });
};
// Upload the PDF file
export async function uploadPdfClaims(docIdArr) {
    let dataArray = [];
    const promises = docIdArr.map((docId, index) =>
        index === 0 ? getClaimData(docId) : getPaqData(docId)
    );

    const results = await Promise.all(promises);
    dataArray = results.map((res) => res);

    const formData = new FormData();
    for (const element of dataArray) {
        const pdfDoc = await preparePdfDocument(
            element.fileUrl,
            replaceSpecialChars(JSON.stringify(element.results_data))
        );
        const pdfB64 = await pdfDoc.saveAsBase64();
        formData.append(
            element.disability ? element.disability : "526EZ",
            pdfB64
        );
    }
    return new Promise((resolve, reject) => {
        cachedAxiosInstance
            .post("/claims/submit-claim/", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
/**
 * Retrieve a client PAQ with data required for uploading
 * @param {string} pdfUrl - The url of the DBQ
 * @param {string} data - The data of the DBQ results_data
 * @param {string} paqName - The name of the PAQ
 * @returns {Bool} Boolean, true if successful.
 */
export async function downloadDBQs({ pdfUrl, data, paqName }) {
    try {
        const pdfDoc = await preparePdfDocument(pdfUrl, data);
        const pdfBytes = await pdfDoc.save();

        const byteArray = new Uint8Array(pdfBytes);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        FileSaver(url, `${paqName}_dbq_completed.pdf`);
        return true;
    } catch (error) {
        throw new Error(response?.data?.message || "Failed to Download PDF");
    }
}
