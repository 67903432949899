import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "./PageHeader";
import DisabilityInformation from "./DisabilityInformation";
import ConditionIncreases from "./ConditionIncreases";
import PaymentIncome from "./PaymentIncome";
import PageFooter from "./PageFooter";
import "./BenefitSummary.scss";
import { getBenefitSummary } from "../../features/benefitSummarySlice";

const BenefitSummary = () => {
    const dispatch = useDispatch();

    const { data, loading, error } = useSelector((state) => state.benefit);

    useEffect(() => {
        dispatch(getBenefitSummary());
    }, [dispatch]);

    if (loading) {
        return (
            <div className="loading-spinner-container">
                <div
                    style={{
                        height: "80px",
                        width: "80px",
                        borderWidth: "8px",
                    }}
                    className="loader"
                ></div>
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div id="benefit-summary">
            <div className="benefits-summary">
                <div className="page-middle">
                    <div className="page-content">
                        <div className="content-container">
                            <PageHeader />
                            <div className="card-sm-container">
                                <DisabilityInformation
                                    calculations={data.calculations.disability_info}
                                />
                                <ConditionIncreases
                                    disabilities={data.disabilities}
                                />
                            </div>
                            <PaymentIncome
                                title="Disability Income Over Time"
                                paymentData={data.calculations.income_over_time}
                            />
                            <PaymentIncome
                                title="Back Pay Details*"
                                paymentData={data.calculations.backpay_over_time}
                                isBackPay={true}
                            />
                            <PageFooter
                                requestDbqs={data.request_dbqs}
                                allPaqsReviewed={data.all_paqs_reviewed}
                                allPaqsCompleted={data.all_paqs_completed}
                                isExam4Appt={data.is_exam_4_appt}
                            />
                        </div>
                    </div>
                </div>
                <div className="page-end"></div>
            </div>
        </div>
    );
};

export default BenefitSummary;
