import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Helper function for error handling
const handleError = (
    error,
    customErrorMsg = "An unexpected error occurred. Please try again later."
) => {
    if (error.status === 500) {
        return "Server error. Please try again later.";
    }
    if (error.response) {
        const errorData = error?.response?.data || {};
        if (errorData?.detail) {
            return errorData?.detail || customErrorMsg;
        }
        if (errorData?.message) {
            return errorData?.message || customErrorMsg;
        }
        if (errorData?.error) {
            return errorData?.error || customErrorMsg;
        }
    } else if (error.request) {
        return error?.message || customErrorMsg;
    } else {
        return error?.message || customErrorMsg;
    }
};

export const getDownloads = createAsyncThunk(
    "downloads/getDownloads",
    async (_, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await axiosInstance.get(
                `/downloads/get-download-list/`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to fetch downloads data.")
            );
        }
    }
);

export const getDBQData = createAsyncThunk(
    "downloads/getDBQData",
    async (data, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await axiosInstance.post(
                `/downloads/get-dbq-data/`,
                data
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to fetch DBQ data.")
            );
        }
    }
);

export const downloadSlice = createSlice({
    name: "downloads",
    initialState: {
        downloads: null,
        status: "idle",
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDownloads.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getDownloads.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.downloads = action.payload;
                state.error = null;
            })
            .addCase(getDownloads.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(getDBQData.pending, (state) => {
                state.status = "getDBQDataPending";
            })
            .addCase(getDBQData.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.error = null;
            })
            .addCase(getDBQData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            });
    },
});

export default downloadSlice.reducer;
