import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./referrallink.scss";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Alert from "../../common/components/Alert";
import FormField from "../../common/components/form/fields/FormField";
import { cachedAxiosInstance } from "@/common/utils/axios";

const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
});

const ReferralLink = () => {
    const user = useSelector((state) => state.user.user);

    const [responseError, setResponseError] = useState(null);
    const [disabled, setDisabled] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);

    async function sendReferral(referral, resetForm) {
        setDisabled(true);
        try {
            const response = await cachedAxiosInstance.post(
                "/users/referral/",
                {
                    email: referral.email,
                    referrer: referral.referrer.user.id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            const data = await response.data;
            setSuccessMsg(data.message);
            resetForm();
            setTimeout(() => {
                setSuccessMsg(null);
            }, 2000);
            setDisabled(null);
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                setResponseError(error.response.message);
                // TODO: delete setTimeout() if we don't need it
                setTimeout(() => {
                    setResponseError(null);
                }, 2000);
                setDisabled(null);
            } else {
                resetForm();
                setResponseError(error);
                // TODO: delete setTimeout() if we don't need it
                setTimeout(() => {
                    setResponseError(null);
                }, 2000);
                setDisabled(null);
            }
        }
    }

    return (
        <div className="main-content">
            <h2 className="page-title">Referral Page</h2>
            <div className="card" style={{ padding: "20px" }}>
                <h4 className="referral-code">Client ID: {user.id}</h4>
                <Formik
                    initialValues={{ email: "" }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                        sendReferral(
                            {
                                email: values.email,
                                referrer: { user },
                            },
                            resetForm
                        );
                    }}
                >
                    {({ isSubmitting }) => (
                        <Form id="referral-form" className="form-control">
                            <h5>Please enter your referrals email.</h5>
                            {responseError && (
                                <Alert type="error" message={responseError} />
                            )}
                            {successMsg && (
                                <Alert type="success" message={successMsg} />
                            )}
                            <FormField
                                label="Friends Email"
                                name="email"
                                type="email"
                            />
                            {/*Handle button state with status*/}
                            <button
                                id="referral-submit-btn"
                                className="btn primary"
                                type="submit"
                                disabled={disabled}
                            >
                                {disabled ? (
                                    <div className="loader"></div>
                                ) : (
                                    "Send Referral"
                                )}
                            </button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};
export default ReferralLink;
