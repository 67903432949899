import React from "react";

import Close from "@/common/images/icons/close.svg";
import "./close_modal_btn.scss";

const CloseModalBtn = ({ onClose }) => {
    return (
        <button type="button" className="close-modal-btn" onClick={onClose}>
            <Close width={48} height={48} />
        </button>
    );
};

export default CloseModalBtn;
