import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormField from "../../../common/components/form/fields/FormField";
import useAlert from "../../hooks/useAlert";
import Alert from "../../../common/components/Alert";
import {
    deleteInformationRequest,
    ERROR_MESSAGE,
} from "../../features/settingsSlice";
import { useDispatch } from "react-redux";
import SuccessStarLogo from "../../components/SuccessStarLogo";
import "./DeleteInfo.scss";

const DeleteInfo = () => {
    const { alertType, alertMessage, alertVisible, showAlert } = useAlert();
    const dispatch = useDispatch();
    const [showSuccess, setShowSuccess] = useState(false);

    const initialValues = {
        description: "",
    };

    const validationSchema = Yup.object({
        description: Yup.string().required("Please enter a reason"),
    });

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            dispatch(deleteInformationRequest(values));
            setShowSuccess(true);
            resetForm();
        } catch (error) {
            setShowSuccess(false);
            showAlert({
                alertType: "error",
                alertMessage: ERROR_MESSAGE,
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div id="delete-info-page">
            {showSuccess ? (
                <div className="delete-info-success-container">
                    <SuccessStarLogo />
                    <h3 className="page-title">Delete Information</h3>
                    <p>
                        Your request for deleting your information has been sent
                        successfully to the support team.Thank you.
                    </p>
                    <Link
                        to="/settings/manage/account"
                        className="btn-primary-outline go-to-account-btn"
                    >
                        Go to My Account
                    </Link>
                </div>
            ) : (
                <div className="request-delete-info-container">
                    <h1 className="page-title">
                        Request to delete my information
                    </h1>
                    {alertVisible && (
                        <Alert type={alertType} message={alertMessage} />
                    )}
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <div className="delete-info-container">
                                    <p>
                                        When you submit a request to delete your
                                        information, you will receive a response
                                        within 10 days. We will permanently
                                        delete the user's data within 45 days of
                                        the request unless the information is
                                        required to be retained by law or falls
                                        under another exception as indicated in
                                        the "Right to Deletion of Personal
                                        Information" section of our{" "}
                                        <a
                                            href="/release-of-information"
                                            target="_blank"
                                        >
                                            Privacy Policy.
                                        </a>
                                    </p>
                                    <div className="input-wrapper">
                                        <FormField
                                            label="Please describe the reason:*"
                                            name="description"
                                            id="description"
                                            type="textarea"
                                            className="desc-textarea"
                                        />
                                    </div>
                                </div>
                                <div className="page-btn-container">
                                    <button
                                        type="submit"
                                        className="btn outline"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? (
                                            <div className="loader"></div>
                                        ) : (
                                            "Submit"
                                        )}
                                    </button>
                                    <Link
                                        to="/settings/manage/support"
                                        className="btn outline"
                                    >
                                        Cancel
                                    </Link>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            )}
        </div>
    );
};

export default DeleteInfo;
