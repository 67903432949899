import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { emailPasswordReset, resetStatus, clearError } from "@/vetvantage/features/authSlice";
import FormField from "../../common/components/form/fields/FormField";
import Alert from "../../common/components/Alert";
import Logo from "../images/logo_white.svg";
import "./PasswordReset.scss";

const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Email is required"),
});

const PasswordReset = () => {
    const dispatch = useDispatch();
    const error = useSelector((state) => state.auth.error);
    const status = useSelector((state) => state.auth.status);

    useEffect(() => {
        dispatch(resetStatus());
        dispatch(clearError());
    }, [dispatch]);

    return (
        <div className="form-container">
            <header className="header">
                <Link to={"/"} className="back-to-landing"><Logo /></Link>
            </header>

            {status === "succeeded" ? (
                <>
                    <div className="body">
                        <h3>Password Reset</h3>
                        <p>
                            We have sent you an e-mail. Please contact us if you do not receive it within a few minutes.
                        </p>
                        <div className="button-row">
                            <Link to={"/accounts/login"} className="btn primary">
                                Return to Login
                            </Link>
                        </div>
                    </div>
                </>
            ) : status === "pending" ? (
                <div className="loading-spinner-container">
                    <div
                        style={{
                            height: '80px',
                            width: '80px',
                            borderWidth: '8px',
                        }}
                        className="loader"
                    ></div>
                </div>
            ) : (
                <>
                    {error && <Alert type="error" message={error} />}

                    <Formik
                        initialValues={{ email: "" }}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            dispatch(emailPasswordReset(values));
                        }}
                    >
                        {() => (
                            <Form className="body">
                                <h3>Reset Your Password</h3>

                                <div className="form-row">
                                    <div className="form-group">
                                        <FormField
                                            label="Email"
                                            name="email"
                                            type="email"
                                        />
                                    </div>
                                </div>
                                <div className="button-row">
                                    <button
                                        type="submit"
                                        className="btn primary"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
            <footer className="footer">
            </footer>
        </div>
    );
};

export default PasswordReset;
