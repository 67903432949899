import React from "react";

const PaymentIncome = ({ title, paymentData, isBackPay }) => {
    return (
        <div className="card-sm-container">
            <div className="card-sm">
                <div className="card-header">{title}</div>
                <div className="table-container">
                    <table className="table-stats">
                        <thead>
                            <tr>
                                <th scope="col">Period</th>
                                <th scope="col">Current</th>
                                <th scope="col">Potential</th>
                                <th scope="col">Difference</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(paymentData).map((period, index) => (
                                <tr key={index}>
                                    <th scope="row">{paymentData[period].period}</th>
                                    <td>${paymentData[period].current.toFixed(2)}</td>
                                    <td>${paymentData[period].potential.toFixed(2)}</td>
                                    <td>${paymentData[period].difference.toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {isBackPay && (
                    <footer>
                        <div className="stat-help">
                            <p>*Backpay projections are based on your ITF date. If your ITF has not been filed, the projections are based on today's date.</p>
                        </div>
                    </footer>
                )}
            </div>
        </div>
    );
};

export default PaymentIncome;
