import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cachedAxiosInstance } from "@/common/utils/axios";

export const getPAQ = createAsyncThunk(
    "paq/getPAQ",
    async (_, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.get(
                "/paqs/api/patient-paqs/"
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;

                if (error.response.status === 302) {
                    return thunkAPI.rejectWithValue(
                        errorData
                    );
                }

                return thunkAPI.rejectWithValue(
                    errorData?.error || "Failed to get user PAQ(s)."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const getPAQSurvey = createAsyncThunk(
    "paq/getPAQSurvey",
    async (disabilityName, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.get(
                `/paqs/api/paq-survey/${disabilityName}`
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;

                if (error.response.status === 302) {
                    return thunkAPI.rejectWithValue(
                        errorData
                    );
                }

                return thunkAPI.rejectWithValue(
                    errorData?.error || "Failed to get PAQ Survey."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const submitPAQSurvey = createAsyncThunk(
    "paq/submitPAQSurvey",
    async (surveyData, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.post(
                `/paqs/api/paq-survey/${surveyData.disabilityName}`,
                {
                    action: surveyData.action,
                    data: surveyData.data,
                    currentPageNo: surveyData.currentPageNo,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData || "PAQ Submission failed."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

const paqSlice = createSlice({
    name: "paq",
    initialState: {
        status: null,
        currentData: null,
        formData: null,
        error: null,
        loading: true,
        disabilities: null,
        currentPageNo: 0,
        allData: null,
    },
    reducers: {
        setFormData: (state, action) => {
            state.currentData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPAQ.pending, (state) => {
                state.error = null;
                state.loading = true;
            })
            .addCase(getPAQ.fulfilled, (state, action) => {
                state.disabilities = action.payload.disabilities;
                state.error = null;
                state.loading = false;
            })
            .addCase(getPAQ.rejected, (state, action) => {
                state.error = action.payload.error;
                state.loading = false;
            })
            .addCase(getPAQSurvey.pending, (state) => {
                state.error = null;
                state.loading = true;
            })
            .addCase(getPAQSurvey.fulfilled, (state, action) => {
                state.status = action.payload.status;
                state.currentData = action.payload.curr_data;
                state.formData = action.payload.form_data;
                state.allData = action.payload.all_data;
                state.currentPageNo = action.payload.curr_page_no;
                state.error = null;
                state.loading = false;
            })
            .addCase(getPAQSurvey.rejected, (state, action) => {
                state.error = action.payload.error;
                state.loading = false;
            })
            .addCase(submitPAQSurvey.pending, (state) => {
                state.error = null;
                state.loading = true;
            })
            .addCase(submitPAQSurvey.fulfilled, (state, action) => {
                state.status = action.payload.status;
                state.currentPageNo = action.payload.curr_page_no;
                state.error = null;
                state.loading = false;
            })
            .addCase(submitPAQSurvey.rejected, (state, action) => {
                state.status = action.payload.status;
                state.error = action.payload.error;
                state.currentPageNo = action.payload.curr_page_no;
                state.loading = false;
            });
    },
});

export const { setFormData } = paqSlice.actions;
export default paqSlice.reducer;