import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getTicketDetails } from "../../features/TicketDetailSlice";
import Alert from "@/common/components/Alert";
import { toggleShow } from "../../features/modalSlice";
import Modal from "../Modal";
import CloseModalBtn from "../CloseModalBtn";
import "./TicketModal.scss";

const TicketModal = ({ id }) => {
    const dispatch = useDispatch();

    const { ticket, status, error } = useSelector(
        (state) => state.ticketDetails
    );

    useEffect(() => {
        dispatch(getTicketDetails(id));
    }, []);

    const closeModal = () => {
        dispatch(toggleShow());
    };

    const isLoading = useMemo(
        () => status == "pending" || status == "idle",
        [status]
    );

    return (
        <Modal>
            <div id="ticket-modal" className="modal-content">
                <CloseModalBtn onClose={closeModal} />
                <div className="title-container">
                    <h5>Refund Ticket Details</h5>
                </div>
                {status === "failed" ? (
                    <Alert message={error} type={"danger"} />
                ) : isLoading ? (
                    <div className="loading-spinner-container">
                        <div
                            style={{
                                height: "80px",
                                width: "80px",
                                borderWidth: "8px",
                            }}
                            className="loader"
                        ></div>
                    </div>
                ) : (
                    <div className="details-container">
                        <div className="detail-item-wrapper">
                            <div className="detail-item-key">
                                <p>ID:</p>
                            </div>
                            <div className="detail-item-value">
                                <p>{ticket.id}</p>
                            </div>
                        </div>
                        <div className="detail-item-wrapper">
                            <div className="detail-item-key">
                                <p>Amount:</p>
                            </div>
                            <div className="detail-item-value">
                                <p>${ticket.amount}</p>
                            </div>
                        </div>
                        <div className="detail-item-wrapper">
                            <div className="detail-item-key">
                                <p>Product:</p>
                            </div>
                            <div className="detail-item-value">
                                <p>{ticket.order_item.product.name}</p>
                            </div>
                        </div>
                        <div className="detail-item-wrapper">
                            <div className="detail-item-key">
                                <p>Status:</p>
                            </div>
                            <div className="detail-item-value">
                                <p>{ticket.status.display_text}</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default TicketModal;
