import React from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import * as SurveyTheme from "survey-core/themes";
import "./PercentageProgressBar.scss";
//import { json } from "./json";
import { Serializer } from "survey-core";
import { ReactSurveyElement, ReactElementFactory } from "survey-react-ui";

Serializer.addProperty("survey", "progressTitle");

class PercentageProgressBar extends ReactSurveyElement {
    render() {
        return (
            <div className="sv-progressbar-percentage">
              <div className="sv-progressbar-percentage__title">
                <span>{this.props.model.progressTitle}</span>
              </div>
              <div className="sv-progressbar-percentage__indicator">
                <div className="sv-progressbar-percentage__value-bar" style={{ width: this.props.model.progressValue + "%" }}></div>
              </div>
              <div className="sv-progressbar-percentage__value">
                <span>{this.props.model.progressValue + "%"}</span>
              </div>
            </div>
        );
    }
}

export default PercentageProgressBar;