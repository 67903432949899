import React from "react";

const PageHeader = () => {
    return (
        <>
            <div className="page-title-container">
                <h3 className="page-title">Disability Benefit Summary</h3>
            </div>
            <div className="content-wrapper">
                <div className="info-container">
                    <p>
                        Projected rating and benefits summary are calculated based on the information you provided.
                    </p>
                </div>
            </div>
        </>
    );
};

export default PageHeader;
