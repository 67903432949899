import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance } from "@/common/utils/axios";

export const getAppointmentSummary = createAsyncThunk(
    "appointments/summary",
    async (_, thunkAPI) => {
        try {
            // get user timezone
            const userTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const response = await axiosInstance.get(
                `/appointments/api/summary/?timezone=${encodeURIComponent(userTZ)}`
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to load appointment summary."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const submitAppointment = createAsyncThunk(
    "appointments/summary/submit",
    async (_, thunkAPI) => {
        try {
            const response = await axiosInstance.post(
                "/appointments/api/summary/"
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to submit appointment."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

const initialSubmissionState = {
    status: "idle",
    error: null,
};

export const apptSummarySlice = createSlice({
    name: "appointment_summary",
    initialState: {
        summary: null,
        status: "idle",
        error: null,
        submission: initialSubmissionState,
    },
    reducers: {
        // reset submission state obj to it's initial value
        resetSubmission: (state, _) => {
            state.submission = initialSubmissionState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAppointmentSummary.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getAppointmentSummary.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.summary = action.payload;
                state.error = null;
            })
            .addCase(getAppointmentSummary.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(submitAppointment.pending, (state) => {
                state.submission.status = "pending";
            })
            .addCase(submitAppointment.fulfilled, (state) => {
                state.status = "succeeded";
                state.submission.error = null;
            })
            .addCase(submitAppointment.rejected, (state, action) => {
                state.submission.status = "failed";
                state.submission.error = action.payload;
            });
    },
});

export const { resetSubmission } = apptSummarySlice.actions;
export default apptSummarySlice.reducer;
