import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

import { getTicketDetails } from "../../features/TicketDetailSlice";
import AdminModal from "./AdminModal";
import AdminLoadingSpinner from "./AdminLoadingSpinner";

const AdminRefundTicketModal = ({ id }) => {
    const dispatch = useDispatch();

    const { ticket, status, error } = useSelector(
        (state) => state.ticketDetails
    );

    useEffect(() => {
        dispatch(getTicketDetails(id));
    }, []);

    const isLoading = useMemo(
        () => status == "pending" || status == "idle",
        [status]
    );

    const boxStyle = {
        minWidth: 723,
        height: 291,
        position: "absolute",
        top: "50%",
        left: "50%",
        display: "flex",
        flexDirection: "column",
        transform: "translate(-50%, -50%)",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    return (
        <AdminModal>
            <Box sx={boxStyle} component={Paper}>
                {isLoading ? (
                    <AdminLoadingSpinner />
                ) : (
                    <>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h6"
                            sx={{ marginBottom: 2 }}
                        >
                            Refund Ticket Details
                        </Typography>
                        {status === "failed" ? (
                            <Alert severity="error" sx={{ marginBottom: 1 }}>
                                {error}
                            </Alert>
                        ) : (
                            <Stack
                                sx={{
                                    flexGrow: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Stack
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginBottom: 1,
                                    }}
                                >
                                    <Typography>ID: </Typography>
                                    <Typography>{ticket.id}</Typography>
                                </Stack>
                                <Stack
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginBottom: 1,
                                    }}
                                >
                                    <Typography>Patient: </Typography>
                                    <Typography>
                                        {ticket.patient.first_name}{" "}
                                        {ticket.patient.last_name}
                                    </Typography>
                                </Stack>
                                <Stack
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginBottom: 1,
                                    }}
                                >
                                    <Typography>Amount: </Typography>
                                    <Typography>${ticket.amount}</Typography>
                                </Stack>
                                <Stack
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginBottom: 1,
                                    }}
                                >
                                    <Typography>Product: </Typography>
                                    <Typography>
                                        {ticket.order_item.product.name}
                                    </Typography>
                                </Stack>
                                <Stack
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        marginBottom: 1,
                                    }}
                                >
                                    <Typography>Status: </Typography>
                                    <Typography>
                                        {ticket.status.display_text}
                                    </Typography>
                                </Stack>
                            </Stack>
                        )}
                    </>
                )}
            </Box>
        </AdminModal>
    );
};

export default AdminRefundTicketModal;
