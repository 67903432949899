import React from "react";
import { Link } from "react-router-dom";

import SuccessStarLogo from "../../components/SuccessStarLogo";
import "./ApptSuccess.scss";

const ApptSuccess = () => {
    return (
        <div id="apptSuccess">
            <div className="page-container">
                <SuccessStarLogo />
                <div className="page-title-container">
                    <h4>Your Appointment Request Has Been Sent!</h4>
                </div>
                <div className="instruction-container">
                    <h5>
                        Once medical provider approves your request, we’ll send
                        you a confirmation email.
                    </h5>
                </div>
                <div className="page-btn-container">
                    <Link to={"/dashboard/"} className="btn primary">
                        Go to Dashboard
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ApptSuccess;
