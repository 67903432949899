import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";

import { clearRedirectUrl, login, clearError } from "@/vetvantage/features/authSlice";
import FormField from "./fields/FormField";
import Alert from "../Alert";
import "./loginform.scss";
import { setCookie } from "../../utils/cookies";

const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string().required("Required"),
    rememberMe: Yup.boolean(),
});

const LoginForm = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const redirectUrl = useSelector((state) => state.auth.redirectUrl);
    const status = useSelector((state) => state.auth.status);
    const error = useSelector((state) => state.auth.error);

    /* TODO: We can optinally move this into middleware */
    useEffect(() => {
        if (redirectUrl) {
            // TODO: use react router navigate once all routes added
            // navigate(resp.data.redirect_url);
            window.location.href = redirectUrl;
            dispatch(clearRedirectUrl());
        }
        dispatch(clearError());
    }, [redirectUrl, navigate, dispatch]);

    return (
        <Formik
            initialValues={{
                email: "",
                password: "",
                rememberMe: false,
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting }) => {
                await dispatch(
                    login({
                        email: values.email,
                        password: values.password,
                        rememberMe: values.rememberMe,
                    })
                );
                setSubmitting(false);
                setCookie("user_tz", Intl.DateTimeFormat().resolvedOptions().timeZone, 30)
                
            }}
        >
            {({ isSubmitting }) => (
                <Form id="login-form">
                    <h5>Please enter your details.</h5>
                    {error && <Alert type="error" message={error} />}
                    <FormField
                        label="Email"
                        name="email"
                        type="email"
                        autoComplete="username"
                    />
                    <FormField
                        label="Password"
                        name="password"
                        type="password"
                    />
                    <div className="login-form-row">
                        <FormField
                            label="Remeber Me"
                            name="rememberMe"
                            type="checkbox"
                        />
                        <Link to={"/accounts/password/reset/"}>
                            Forgot Password
                        </Link>
                    </div>
                    <button
                        id="login-submit-btn"
                        className="btn primary"
                        type="submit"
                        disabled={isSubmitting || status == "pending"}
                    >
                        {status == "pending" ? (
                            <div className="loader"></div>
                        ) : (
                            "Login"
                        )}
                    </button>
                    <p>
                        Don't have an account?{" "}
                        <Link to={"/users/signup"}>Sign up</Link>
                    </p>
                </Form>
            )}
        </Formik>
    );
};

export default LoginForm;
