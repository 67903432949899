import React from "react";
import "./Settings.scss";
import SettingsSidebar from "./SettingsSidebar";
import { Outlet } from "react-router-dom";

function Settings() {
    return (
        <div id="settings">
            <div className="settings-content">
                <SettingsSidebar />
                {/* Main Content Here we render the child routes Account, Security, etc. */}
                <div className="settings-main-content">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default Settings;
