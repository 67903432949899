import React from "react";
import successStar from "../images/star.png";
import "./success_star_logo.scss";

function SuccessStarLogo() {
    return (
        <div id="SuccessStarLogo" className="success-img-container">
            <div className="icon-container">
                <div className="ellipse-se"></div>
                <div className="ellipse-se-2"></div>
                <div className="ellipse-se-3"></div>
                <div className="ellipse-sw"></div>
                <div className="ellipse-sw-2"></div>
                <div className="ellipse-nw"></div>
                <div className="ellipse-nw-2"></div>
                <div className="ellipse-nw-3"></div>
                <div className="ellipse-ne"></div>
                <div className="ellipse-ne-2"></div>
                <div className="wht-circle"></div>
                <div className="blue-circle"></div>
                <img className="img-pos" src={successStar} alt="Success star" />
            </div>
        </div>
    );
}

export default SuccessStarLogo;
