import React from "react";

import FAQ from "../../../images/faq.svg";
import "./PageFAQAnim.scss";

const PageFAQAnim = () => {
    return (
        <div id="pageFAQAnim">
            <FAQ />
        </div>
    );
};

export default PageFAQAnim;
