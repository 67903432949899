export const dateOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
};

export const convertUTCToLocalString = (
    isoString,
    options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
    }
) => {
    const date = new Date(isoString);
    const localDateString = date.toLocaleString(undefined, options);
    return localDateString;
};
