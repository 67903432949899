import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cachedAxiosInstance } from "@/common/utils/axios";
import { updateUser } from "./userSlice";

export const markAllViewed = createAsyncThunk(
    "notifications/markAllViewed",
    async (_, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.post(
                "/notifications/api/notifications/new/",
                { mark_all_viewed: true }
            );

            thunkAPI.dispatch(
                updateUser({
                    notification_count: response.data.notification_count,
                })
            );

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const markAllArchived = createAsyncThunk(
    "notifications/markAllArchived",
    async (_, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.post(
                "/notifications/api/notifications/new/",
                { mark_all_archived: true }
            );

            thunkAPI.dispatch(
                updateUser({
                    notification_count: response.data.notification_count,
                })
            );

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const markNotificationViewed = createAsyncThunk(
    "notifications/markNotificationViewed",
    async (notificationId, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.post(
                "/notifications/api/notifications/new/",
                {
                    viewed_id: notificationId,
                }
            );

            thunkAPI.dispatch(
                updateUser({
                    notification_count: response.data.notification_count,
                })
            );

            return notificationId;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const archiveNotification = createAsyncThunk(
    "notifications/archiveNotification",
    async ({ notificationId, isPast }, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.post(
                "/notifications/api/notifications/new/",
                { archived_id: notificationId }
            );

            thunkAPI.dispatch(
                updateUser({
                    notification_count: response.data.notification_count,
                })
            );

            return { ...response.data, notificationId, isPast };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchNotifications = createAsyncThunk(
    "notifications/fetchNotifications",
    async ({ type = "new", page_size = null, page = null }, thunkAPI) => {
        try {
            const queryParams = new URLSearchParams();
            if (page_size) queryParams.append("page_size", page_size);
            if (page) queryParams.append("page", page);

            const response = await cachedAxiosInstance.get(
                `/notifications/api/notifications/${type}/?${queryParams.toString()}`
            );
            return { ...response.data, type };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const notificationSlice = createSlice({
    name: "notifications",
    initialState: {
        newNotifications: [],
        pastNotifications: [],
        archivedNotifications: [],
        error: null,
        loading: false,
        pagination: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotifications.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchNotifications.fulfilled, (state, action) => {
                const { type } = action.payload;
                if (type === "new") {
                    state.newNotifications = action.payload.new_notifications;
                    state.pastNotifications = action.payload.past_notifications;
                } else {
                    state.archivedNotifications =
                        action.payload.archived_notifications;
                }
                state.pagination = action.payload;
                state.loading = false;
            })
            .addCase(fetchNotifications.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(markAllViewed.fulfilled, (state) => {
                state.pastNotifications = [
                    ...state.pastNotifications,
                    ...state.newNotifications,
                ];
                state.newNotifications = [];
            })
            .addCase(markAllArchived.fulfilled, (state) => {
                state.pastNotifications = [];
                state.newNotifications = [];
            })
            .addCase(archiveNotification.fulfilled, (state, action) => {
                const { notificationId, isPast } = action.payload;
                if (isPast) {
                    state.pastNotifications = state.pastNotifications.filter(
                        (notification) => notification.id !== notificationId
                    );
                } else {
                    state.newNotifications = state.newNotifications.filter(
                        (notification) => notification.id !== notificationId
                    );
                }
            })
            .addCase(markNotificationViewed.fulfilled, (state, action) => {
                const notificationId = action.payload;

                // Find the viewed notification in newNotifications
                const viewedNotification = state.newNotifications.find(
                    (n) => n.id === notificationId
                );

                if (viewedNotification) {
                    // Move the notification to pastNotifications
                    state.newNotifications = state.newNotifications.filter(
                        (n) => n.id !== notificationId
                    );
                    state.pastNotifications.push(viewedNotification);
                }
                state.loading = false;
                state.error = null;
            });
    },
});

export default notificationSlice.reducer;
