import React from "react";
import { Link } from "react-router-dom";
import Logo from "../images/logo.png";
import "./Landing.scss";

const Landing = () => {
    return (
        <div id="Landing">
            <div className="inner">
                <h2>Welcome to</h2>
                <div className="logo-wrapper">
                    <img src={Logo} alt="VetVantage logo" />
                    {/* <span>Powered by TekMed</span> */}
                </div>
                <h5>
                    Empowering Veterans: Saluting Your Service, Elevating Your
                    Benefits.
                </h5>
                <Link to={"accounts/login"} className="btn primary">
                    Get Started
                </Link>
            </div>
        </div>
    );
};

export default Landing;
