import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getItfSurveyData,
    saveProgress,
    completeItfForm,
} from "../../features/itf/itfSlice";
import MainUnlock from "../../components/MainUnlock";

import { getCookie } from "@/common/utils/cookies";
import SurveyComponent from "../../components/Survey/Survey";
import Alert from "../../../common/components/Alert";
import {
    processSavingSurveyData,
    processSurveyCompletion,
} from "../../utils/survey";
import ITFSkipModal from "./ITFSkipModal";
import useAlert from "../../hooks/useAlert";
import "./ITF.scss";
import Spinner from "../../components/Spinner";
import { useNavigate } from "react-router-dom";

const Itf = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isSkipModalOpen, setIsSkipModalOpen] = useState(false);
    const { alertType, alertMessage, alertVisible, showAlert, hideAlert } =
        useAlert();

    const { surveyData, status, error, context, redirect } = useSelector(
        (state) => state.itf
    );

    useEffect(() => {
        hideAlert();
        dispatch(getItfSurveyData());
    }, [dispatch]);

    useEffect(() => {
        if (redirect) {
            // navigate to /itf/done/ when ITF is done
            navigate(redirect);
        }
    }, [redirect]);
    // Function to save survey progress
    const onCurrentPageChanging = async (survey) => {
        if (survey.isCompleted) return;
        hideAlert();

        const currDataJSON = processSavingSurveyData(survey);
        const formData = new FormData();
        formData.append("csrfmiddlewaretoken", getCookie("csrftoken"));
        formData.append("document_id", surveyData.document_id);
        formData.append("surveyData", currDataJSON);
        dispatch(saveProgress(formData))
            .then((res) => {
                if (res.error) {
                    showAlert({
                        alertType: "error",
                        alertMessage: "Failed to save survey progress",
                    });
                    survey.currentPageNo = survey.currentPageNo - 1;
                }
            })
            .catch((error) => {
                showAlert({
                    alertType: "error",
                    alertMessage: "Failed to save survey progress",
                });
                survey.currentPageNo = survey.currentPageNo - 1;
            });
    };

    // Function to complete the survey
    const onComplete = async (survey) => {
        const dataJSON = processSurveyCompletion(survey);
        const formData = new FormData();
        formData.append("csrfmiddlewaretoken", getCookie("csrftoken"));
        formData.append("document_id", surveyData.document_id);
        formData.append("resultData", dataJSON);
        dispatch(completeItfForm(formData))
            .then(() => {
                dispatch(getItfSurveyData());
            })
            .then((res) => {
                if (res.error) {
                    showAlert({
                        alertType: "error",
                        alertMessage: "Failed to complete survey",
                    });
                }
            })
            .catch((error) => {
                showAlert({
                    alertType: "error",
                    alertMessage: "Failed to complete survey",
                });
            });
    };

    const onCurrentPageChanged = (survey, options) => {
        // handle ebenefit validation next button func
        if (
            options.isNextPage &&
            options.oldCurrentPage.name === "EBenefitAccountValidationPage"
        ) {
            // get upload screenshot page
            const screenshotPage = survey.getPageByName("UploadScreenShotPage");
            // navigate back to the upload screenshot page
            survey.currentPage = screenshotPage;
        }
    };

    if (status === "loading") {
        return <Spinner />;
    }

    if (error && status === "failed") {
        return <Alert type={"error"} message={error} />;
    }

    if (status === "completed" && context) {
        return (
            <>
                <MainUnlock
                    step_title={"Great News!"}
                    step_btn_text={"Next"}
                    btn_url={context.next_url}
                    hideGoBack
                >
                    <div className="instruction-container">
                        <p className="text-half-width">{context.success_msg}</p>
                    </div>
                </MainUnlock>
            </>
        );
    }

    if (
        !surveyData ||
        !surveyData.document_id ||
        !surveyData.curr_data_json ||
        !surveyData.form_data_json ||
        !surveyData.all_data_json
    )
        return <p>Failed to load ITF</p>;

    return (
        <div id="itf" className="itf-container">
            {alertVisible && <Alert type={alertType} message={alertMessage} />}
            {surveyData.is_optional && (
                <div className="itf-skip">
                    <button
                        className="itf-skip-btn"
                        onClick={() => setIsSkipModalOpen(true)}
                    >
                        Skip ITF
                    </button>
                </div>
            )}
            <SurveyComponent
                currentDataJSON={surveyData.curr_data_json}
                formDataJSON={surveyData.form_data_json}
                allData={surveyData.all_data_json}
                onComplete={onComplete}
                onCurrentPageChanging={onCurrentPageChanging}
                onCurrentPageChanged={onCurrentPageChanged}
            />
            <ITFSkipModal
                isOpen={isSkipModalOpen}
                onClose={() => setIsSkipModalOpen(false)}
                onDoLater={() => {
                    window.location.href = "/condition_exam/";
                }}
                onStay={() => setIsSkipModalOpen(false)}
            />
        </div>
    );
};

export default Itf;
