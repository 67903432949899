import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cachedAxiosInstance } from "@/common/utils/axios";

export const getBenefitSummary = createAsyncThunk("benefit-summary/summary", async (_, thunkAPI) => {
    try {
        const response = await cachedAxiosInstance.get("/benefit-summary/api/summary");
        return await response.data;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const errorData = error.response.data;
            return thunkAPI.rejectWithValue(
                errorData?.error || "Failed to get benefit summary"
            );
        } else if (error.request) {
            // The request was made but no response was received
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
});


const benefitSummarySlice = createSlice({
    name: "benefit",
    initialState: {
        data: null,
        error: null,
        loading: true
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBenefitSummary.pending, (state) => {
                state.error = null;
                state.loading = true;
            })
            .addCase(getBenefitSummary.fulfilled, (state, action) => {
                state.data = action.payload;
                state.error = null;
                state.loading = false;
            })
            .addCase(getBenefitSummary.rejected, (state, action) => {
                state.error = action.payload.error;
                state.loading = false;
            })
    },
});

export default benefitSummarySlice.reducer;