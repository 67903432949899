import React from "react";
import LoginForm from "@/common/components/form/LoginForm";

const Login = () => {
    return (
        <div id="login">
            <div className="inner">
                <h2>Welcome to VetVantage™</h2>
                <LoginForm />
            </div>
        </div>
    );
};

export default Login;
