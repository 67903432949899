import React from "react";
import { ErrorMessage, useField } from "formik";
import HiddenField from "./HiddenField";
import "./formfield.scss";

const FormField = ({ label, name, type = "text", className, ...props }) => {
    const [field, meta] = useField(name);
    const isError = meta.error;

    const getInputClassName = () => {
        return `${className || ""} ${isError ? "error" : ""}`.trim();
    };

    let inputElement;

    switch (type) {
        case "text":
        case "email":
        case "number":
            inputElement = (
                <input
                    {...field}
                    {...props}
                    type={type}
                    className={getInputClassName()}
                />
            );
            break;
        case "password":
            inputElement = (
                <HiddenField
                    fieldProps={{
                        ...props,
                        ...field,
                        className: getInputClassName(),
                    }}
                    isError={isError}
                />
            );
            break;
        case "textarea":
            inputElement = (
                <textarea
                    {...field}
                    {...props}
                    className={getInputClassName()}
                />
            );
            break;
        case "checkbox":
            inputElement = (
                <input
                    type="checkbox"
                    id={name}
                    {...field}
                    {...props}
                    className={getInputClassName()}
                />
            );
            break;
        case "select":
            inputElement = (
                <select
                    {...field}
                    id={name}
                    name={name}
                    {...props}
                    className={getInputClassName()}
                />
            );
            break;
        default:
            inputElement = (
                <input
                    {...field}
                    {...props}
                    type="text"
                    className={getInputClassName()}
                />
            );
            break;
    }

    return (
        <div className={`form-field ${type} ${isError ? "error" : ""}`}>
            {label && (
                <label className="form-label" htmlFor={name}>
                    {label}
                </label>
            )}
            {inputElement}
            <ErrorMessage
                name={name}
                component="div"
                className="field-error-message"
            />
        </div>
    );
};

export default FormField;
