import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Alert from "@/common/components/Alert";
import { getRefunds } from "../../features/refundsSlice";
import { toggleShow } from "../../features/modalSlice";
import { formatDate } from "../../utils";
import TicketModal from "../../components/ticket_modal/TicketModal";
import "./RefundHistory.scss";
import Pagination from "../../components/Pagination/Pagination";

const RefundHistory = () => {
    const dispatch = useDispatch();

    const { refunds, status, error, pagination } = useSelector(
        (state) => state.refunds
    );

    const modalData = useSelector((state) => state.modal.data);

    const isLoading = useMemo(
        () => status == "pending" || status == "idle",
        [status]
    );

    useEffect(() => {
        dispatch(getRefunds({ status: "complete" }));
    }, []);

    const openModal = (id) => {
        dispatch(toggleShow({ id: id }));
    };

    if (status === "failed") {
        return <Alert message={error} type={"error"} />;
    } else {
        return (
            <div id="refund-history">
                <div className="page-title-container">
                    <h3>Refund History</h3>
                </div>
                <div className="card table-container">
                    <div className="table-wrapper scrollable">
                        {isLoading ? (
                            <div className="loading-spinner-container">
                                <div className="loader"></div>
                            </div>
                        ) : refunds.length ? (
                            <>
                                <table>
                                    <thead>
                                        <tr className="table-header">
                                            <th>ID</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Ticket</th>
                                            <th>Amount</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {refunds.map(
                                            ({
                                                id,
                                                ticket,
                                                amount,
                                                status,
                                                timestamp,
                                            }) => {
                                                return (
                                                    <tr key={id}>
                                                        <td className="text-el">
                                                            {id}
                                                        </td>
                                                        <td className="text-el">
                                                            {formatDate(
                                                                timestamp
                                                            )}
                                                        </td>
                                                        <td>
                                                            <div className="btn-wrapper">
                                                                <div className="btn outline">
                                                                    {
                                                                        status.display_text
                                                                    }
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="ticket-id">
                                                            <div
                                                                onClick={() =>
                                                                    openModal(
                                                                        ticket
                                                                    )
                                                                }
                                                            >
                                                                {ticket}
                                                            </div>
                                                        </td>
                                                        <td className="text-el">
                                                            $
                                                            {parseFloat(
                                                                amount
                                                            ).toFixed(2)}
                                                        </td>
                                                        <td>
                                                            <div className="btn-wrapper">
                                                                {status.value ===
                                                                    1 && (
                                                                    <button
                                                                        type="button"
                                                                        className="btn primary"
                                                                        onClick={() =>
                                                                            openModal(
                                                                                ticket
                                                                            )
                                                                        }
                                                                    >
                                                                        Details
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </>
                        ) : (
                            <div className="empty-list-container">
                                <h6>You do not have any Refund yet.</h6>
                            </div>
                        )}
                    </div>

                    {refunds?.length > 0 &&
                        pagination?.current_page &&
                        pagination?.total_pages &&
                        pagination?.page_size && (
                            <Pagination
                                currentPage={pagination?.current_page ?? 1}
                                total_pages={pagination?.total_pages ?? 1}
                                itemsPerPage={pagination?.page_size ?? 10}
                                // change current page
                                onPageChange={(page) => {
                                    dispatch(
                                        getRefunds({
                                            status: "complete",
                                            page: page,
                                            page_size:
                                                pagination?.page_size ?? 10,
                                        })
                                    );
                                }}
                                // change page size
                                onItemsPerPageChange={(value) => {
                                    dispatch(
                                        getRefunds({
                                            status: "complete",
                                            page_size: value,
                                            page: 1, // reset page number
                                        })
                                    );
                                }}
                            />
                        )}
                </div>
                {modalData && <TicketModal id={modalData.id} />}
            </div>
        );
    }
};

export default RefundHistory;
