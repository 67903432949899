import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import Alert from "../../../common/components/Alert";
import { getCookie } from "../../../common/utils/cookies";
import {
    updateNotificationSettings,
    resetStatus as resetSettingsStatus,
    getNotificationSettings,
} from "../../features/settingsSlice";
import useAlert from "../../hooks/useAlert";

const notificationSettingsValidationSchema = Yup.object({
    allNotifications: Yup.boolean(),
    in_app_notifications: Yup.boolean(),
    text_notifications: Yup.boolean(),
    email_notifications: Yup.boolean(),
});

const updateAllNotifications = (values, setFieldValue) => {
    const allChecked =
        values.in_app_notifications &&
        values.text_notifications &&
        values.email_notifications;
    setFieldValue("allNotifications", allChecked);
};

const Notification = () => {
    const dispatch = useDispatch();

    // Redux State
    const { status: settingsStatus, notificationSettings } = useSelector(
        (state) => state.settings
    );

    // Local State
    const { alertType, alertMessage, alertVisible, showAlert } = useAlert();

    useEffect(() => {
        dispatch(resetSettingsStatus());
        dispatch(getNotificationSettings());
    }, [dispatch]);

    const handleSubmitNotifications = (values, { setSubmitting }) => {
        const formData = new FormData();
        formData.append("csrfmiddlewaretoken", getCookie("csrftoken"));
        formData.append("in_app_notifications", values.in_app_notifications);
        formData.append("text_notifications", values.text_notifications);
        formData.append("email_notifications", values.email_notifications);

        dispatch(updateNotificationSettings(formData))
            .unwrap()
            .then(() => {
                showAlert({
                    alertType: "success",
                    alertMessage: "Notification settings updated successfully",
                });
            })
            .catch((error) => {
                showAlert({
                    alertType: "error",
                    alertMessage: "Error updating notification settings",
                });
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <>
            <div className="page-top-container">
                <img src="/static/settings/img/lock-icon-blue.png" alt="lock" />
                <h6 className="page-top-header">Notification Settings</h6>
            </div>
            <div className="error-container">
                {alertVisible && (
                    <Alert type={alertType} message={alertMessage} />
                )}
            </div>
            <div className="page-middle">
                <div className="content-container">
                    <div className="page-content settings-column">
                        <div className="settings-header">
                            <h3 className="settings-title">
                                Notification Settings
                            </h3>

                            <Formik
                                validationSchema={
                                    notificationSettingsValidationSchema
                                }
                                initialValues={{
                                    allNotifications:
                                        (notificationSettings?.in_app_notifications &&
                                            notificationSettings?.text_notifications &&
                                            notificationSettings?.email_notifications) ||
                                        false,
                                    in_app_notifications:
                                        notificationSettings?.in_app_notifications ??
                                        false,
                                    text_notifications:
                                        notificationSettings?.text_notifications ??
                                        false,
                                    email_notifications:
                                        notificationSettings?.email_notifications ??
                                        false,
                                }}
                                enableReinitialize
                                validateOnMount={true}
                                onSubmit={handleSubmitNotifications}
                            >
                                {({
                                    values,
                                    setFieldValue,
                                    isSubmitting,
                                    dirty,
                                    isValid,
                                }) => (
                                    <Form id="notification-form">
                                        <div className="switch-row">
                                            <label htmlFor="allNotifications">
                                                All Notifications
                                            </label>
                                            <label className="switch">
                                                <input
                                                    id="allNotifications"
                                                    type="checkbox"
                                                    checked={
                                                        values.allNotifications
                                                    }
                                                    onChange={(e) => {
                                                        const checked =
                                                            e.target.checked;
                                                        setFieldValue(
                                                            "allNotifications",
                                                            checked
                                                        );
                                                        setFieldValue(
                                                            "in_app_notifications",
                                                            checked
                                                        );
                                                        setFieldValue(
                                                            "text_notifications",
                                                            checked
                                                        );
                                                        setFieldValue(
                                                            "email_notifications",
                                                            checked
                                                        );
                                                    }}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                        <div className="switch-row">
                                            <label htmlFor="in_app_notifications">
                                                In App Notifications
                                            </label>
                                            <label className="switch">
                                                <input
                                                    id="in_app_notifications"
                                                    type="checkbox"
                                                    checked={
                                                        values.in_app_notifications
                                                    }
                                                    onChange={() => {
                                                        const newValue =
                                                            !values.in_app_notifications;
                                                        setFieldValue(
                                                            "in_app_notifications",
                                                            newValue
                                                        );
                                                        updateAllNotifications(
                                                            {
                                                                ...values,
                                                                in_app_notifications:
                                                                    newValue,
                                                            },
                                                            setFieldValue
                                                        );
                                                    }}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                        <div className="switch-row">
                                            <label htmlFor="text_notifications">
                                                Text Notifications
                                            </label>
                                            <label className="switch">
                                                <input
                                                    id="text_notifications"
                                                    type="checkbox"
                                                    checked={
                                                        values.text_notifications
                                                    }
                                                    onChange={() => {
                                                        const newValue =
                                                            !values.text_notifications;
                                                        setFieldValue(
                                                            "text_notifications",
                                                            newValue
                                                        );
                                                        updateAllNotifications(
                                                            {
                                                                ...values,
                                                                text_notifications:
                                                                    newValue,
                                                            },
                                                            setFieldValue
                                                        );
                                                    }}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                        <div className="switch-row">
                                            <label htmlFor="email_notifications">
                                                Email Notifications
                                            </label>
                                            <label className="switch">
                                                <input
                                                    id="email_notifications"
                                                    type="checkbox"
                                                    checked={
                                                        values.email_notifications
                                                    }
                                                    onChange={() => {
                                                        const newValue =
                                                            !values.email_notifications;
                                                        setFieldValue(
                                                            "email_notifications",
                                                            newValue
                                                        );
                                                        updateAllNotifications(
                                                            {
                                                                ...values,
                                                                email_notifications:
                                                                    newValue,
                                                            },
                                                            setFieldValue
                                                        );
                                                    }}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                        <div className="page-btn-container-settings my-2">
                                            <button
                                                type="submit"
                                                className="btn primary"
                                                disabled={
                                                    settingsStatus ===
                                                        "loading" ||
                                                    isSubmitting ||
                                                    !dirty ||
                                                    !isValid
                                                }
                                            >
                                                {isSubmitting ||
                                                settingsStatus === "loading" ? (
                                                    <div className="loader"></div>
                                                ) : (
                                                    "Apply"
                                                )}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Notification;
