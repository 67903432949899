import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Stack } from "@mui/material";
import Alert from "@mui/material/Alert";

import {
    resetMethod,
    createChecklistItem,
} from "../../features/admin/medHistAdminSlice";
import { toggleShow } from "../../features/modalSlice";
import AdminModal from "./AdminModal";
import AdminLoadingSpinner from "./AdminLoadingSpinner";

const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
});

const CreateChecklistItemForm = () => {
    const dispatch = useDispatch();
    const { status, error } = useSelector((state) => state.checklist.method);

    useEffect(() => {
        dispatch(resetMethod());
    }, []);

    const handleSubmit = (values) => {
        return new Promise((resolve, _) => {
            dispatch(createChecklistItem(values.name)).then((res) => {
                if (!res.error) {
                    closeModal();
                }
                resolve();
            });
        });
    };

    const closeModal = () => {
        dispatch(toggleShow());
    };

    const boxStyle = {
        width: 450,
        height: 270,
        position: "absolute",
        top: "50%",
        left: "50%",
        display: "flex",
        flexDirection: "column",
        transform: "translate(-50%, -50%)",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    return (
        <AdminModal>
            <div>
                <Formik
                    initialValues={{
                        name: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        handleSubmit(values);
                    }}
                >
                    {({ handleChange }) => (
                        <Form method="post" autoComplete="off">
                            <Box sx={boxStyle} component={Paper}>
                                {status === "pending" ? (
                                    <AdminLoadingSpinner />
                                ) : (
                                    <>
                                        {status === "failed" && (
                                            <Alert
                                                severity="error"
                                                sx={{ marginBottom: 1 }}
                                            >
                                                {error}
                                            </Alert>
                                        )}
                                        <Typography
                                            id="modal-modal-title"
                                            variant="h6"
                                            component="h6"
                                            sx={{ marginBottom: 2 }}
                                        >
                                            Create Checklist Item
                                        </Typography>
                                        <Stack
                                            sx={{
                                                flexGrow: 1,
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <TextField
                                                id="outlined-basic"
                                                name="name"
                                                label="Document Name"
                                                variant="outlined"
                                                onChange={handleChange}
                                                sx={{ width: "100%" }}
                                                required
                                            />
                                            <ErrorMessage
                                                name="name"
                                                component="div"
                                                className="field-error-message"
                                            />
                                            <Stack
                                                direction={"row"}
                                                width={"100%"}
                                                marginTop={2}
                                                justifyContent={"flex-end"}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    onClick={closeModal}
                                                    sx={{
                                                        marginRight: 1,
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                >
                                                    Create
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </>
                                )}
                            </Box>
                        </Form>
                    )}
                </Formik>
            </div>
        </AdminModal>
    );
};

export default CreateChecklistItemForm;
