import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Alert from "../../common/components/Alert";
import { formatDate } from "../utils";
import { getPatientOrders } from "../features/ordersSlice";
import { toggleShow } from "../features/modalSlice";
import ProductListModal from "../components/ProductListModal";
import "../common/styles/table_container.scss";
import "./order_history.scss";
import "./medical_history.scss";
import Pagination from "../components/Pagination/Pagination";

const OrderHistory = () => {
    const user = useSelector((state) => state.user.user);
    const { orders, status, error, pagination } = useSelector(
        (state) => state.orders
    );
    const dispatch = useDispatch();
    const [orderDetails, setOrderDetails] = useState(null);
    useEffect(() => {
        dispatch(getPatientOrders({ patientId: user.id, getUnpaid: false }));
    }, []);

    const getOrderDetails = (orderItems, total) => {
        setOrderDetails({ order_items: orderItems, total });
        dispatch(toggleShow());
    };

    if (status === "failed") {
        return <Alert message={error} type={"error"} />;
    } else {
        return (
            <div id="orderHistory">
                <div className="page-title-container">
                    <h3>Order History</h3>
                </div>
                <div className="card table-container">
                    <div className="table-wrapper scrollable">
                        {status === "succeeded" ? (
                            orders.length ? (
                                <table>
                                    <thead>
                                        <tr className="table-header">
                                            <th>ID</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Products</th>
                                            <th>Total</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders.map((order) => {
                                            const {
                                                id,
                                                timestamp,
                                                status,
                                                order_items,
                                                amount_paid,
                                            } = order;
                                            return (
                                                <tr key={id}>
                                                    <td className="text-el">
                                                        {id}
                                                    </td>
                                                    <td className="text-el">
                                                        {formatDate(timestamp)}
                                                    </td>
                                                    <td>
                                                        <div className="btn-wrapper">
                                                            <div className="btn outline">
                                                                {
                                                                    status.display_text
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="num-products">
                                                        <div
                                                            onClick={() =>
                                                                getOrderDetails(
                                                                    order_items,
                                                                    amount_paid
                                                                )
                                                            }
                                                        >
                                                            {order_items.length}
                                                        </div>
                                                    </td>
                                                    <td className="text-el">
                                                        $
                                                        {parseFloat(
                                                            amount_paid
                                                        ).toFixed(2)}
                                                    </td>
                                                    <td>
                                                        <div className="btn-wrapper">
                                                            <button
                                                                type="button"
                                                                className="btn primary"
                                                                onClick={() =>
                                                                    getOrderDetails(
                                                                        order_items,
                                                                        amount_paid
                                                                    )
                                                                }
                                                            >
                                                                View Details
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="empty-list-container">
                                    <h6>You do not have any order yet.</h6>
                                </div>
                            )
                        ) : (
                            <div className="loading-spinner-container">
                                <div
                                    style={{
                                        height: "80px",
                                        width: "80px",
                                        borderWidth: "8px",
                                    }}
                                    className="loader"
                                ></div>
                            </div>
                        )}
                    </div>

                    {orders.length > 0 &&
                        pagination?.current_page &&
                        pagination?.total_pages &&
                        pagination?.page_size && (
                            <Pagination
                                currentPage={pagination?.current_page ?? 1}
                                total_pages={pagination?.total_pages ?? 1}
                                itemsPerPage={pagination?.page_size ?? 10}
                                // change current page
                                onPageChange={(page) => {
                                    dispatch(
                                        getPatientOrders({
                                            patientId: user.id,
                                            getUnpaid: false,
                                            page: page,
                                            page_size:
                                                pagination?.page_size ?? 10,
                                        })
                                    );
                                }}
                                // change page size
                                onItemsPerPageChange={(value) => {
                                    dispatch(
                                        getPatientOrders({
                                            patientId: user.id,
                                            getUnpaid: false,
                                            page_size: value,
                                            page: 1, // reset page number
                                        })
                                    );
                                }}
                            />
                        )}
                </div>
                <ProductListModal
                    orderDetails={orderDetails}
                    setOrderDetails={setOrderDetails}
                />
            </div>
        );
    }
};

export default OrderHistory;
