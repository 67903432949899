import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import doctorImage from "../../images/doctor-board-blue-2.png";
import { getPAQ } from "../../features/paqSlice";
import Alert from "../../../common/components/Alert";
import "./PAQ.scss";

const PAQDashboard = () => {
    const dispatch = useDispatch();
    const { disabilities, loading, error } = useSelector((state) => state.paq);

    useEffect(() => {
        dispatch(getPAQ());
    }, [dispatch]);

    if (loading || !disabilities) {
        return (
            <div className="loading-spinner-container">
                <div
                    style={{
                        height: "80px",
                        width: "80px",
                        borderWidth: "8px",
                    }}
                    className="loader"
                ></div>
            </div>
        );
    }

    if (error) {
        <div id="paq-page">
            <Alert type="error" message={error} />
        </div>;
    }

    return (
        <div id="paq-page">
            <div className="page-title-container">
                <h3 className="page-title">
                    {disabilities.length === 1
                        ? "You Have 1 Personal Assessment Questionnaire Form"
                        : `You Have ${disabilities.length} Personal Assessment Questionnaire Forms`}
                </h3>
            </div>
            <div className="paq-list-instruction-container">
                <p>
                    Below you can find a list of your current conditions and PAQ
                    completion status.
                </p>
            </div>
            <div className="paq-list-container">
                <div className="paq-list-title-container">
                    <h6>Current Conditions:</h6>
                </div>
                <div className="paq-list">
                    {disabilities.map((disability) => (
                        <div className="paq-item-container" key={disability.id}>
                            <div className="paq-item-left">
                                <div className="paq-img-container">
                                    <img src={doctorImage} alt="Doctor board" />
                                </div>
                                <div className="paq-column">
                                    <div className="paq-name-container">
                                        <p>{disability.product.name}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="paq-item-center">
                                <div className="paq-status-container">
                                    {disability.status === "in_progress" && (
                                        <p>Incomplete</p>
                                    )}
                                    {disability.status === "completed" && (
                                        <p>Completed</p>
                                    )}
                                    {disability.status === "not_started" && (
                                        <p>Not Started</p>
                                    )}
                                    {disability.status === "incomplete" && (
                                        <p>Incomplete</p>
                                    )}
                                </div>
                            </div>
                            <div className="paq-item-end">
                                {disability.status === "completed" &&
                                disabilities.findIndex(
                                    (obj) => obj.status !== "completed"
                                ) !== -1 ? (
                                    <button className="btn primary" disabled>
                                        View Pre-Benefits Summary
                                    </button>
                                ) : (
                                    <Link
                                        to={
                                            disability.status === "completed"
                                                ? "/benefit-summary"
                                                : `/paqs/paq-survey/${disability.product.name}`
                                        }
                                        className="btn primary"
                                    >
                                        {disability.status === "not_started" &&
                                            "Start Questionnaire"}
                                        {disability.status === "in_progress" &&
                                            "Resume Questionnaire"}
                                        {disability.status === "completed" &&
                                            "View Pre-Benefits Summary"}
                                    </Link>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PAQDashboard;
