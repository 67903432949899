import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance } from "../../common/utils/axios";

export const getDoctorTime = createAsyncThunk(
    "appointments/doctor-time",
    async ({ isReschedule = false, date = null }, thunkAPI) => {
        try {
            // get user timezone
            const userTZ = Intl.DateTimeFormat().resolvedOptions().timeZone;
            var url = `/appointments/api/doctors/doctor-time/?timezone=${encodeURIComponent(
                userTZ
            )}`;
            if (isReschedule) {
                url += `&is_reschedule=${isReschedule}`;
            }
            if (date) {
                url += `&date=${date}`;
            }
            const response = await axiosInstance.get(url);
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to get doctor time."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const selectApptTime = createAsyncThunk(
    "appointments/select-datetime",
    async (postData, thunkAPI) => {
        try {
            const response = await axiosInstance.post(
                "/appointments/api/doctors/select/",
                postData
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to submit appointment time."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

const initialSubmissionState = {
    status: "idle",
    error: null,
};

export const doctorTimeSlice = createSlice({
    name: "doctor_time",
    initialState: {
        data: null,
        status: "idle",
        error: null,
        submission: initialSubmissionState,
    },
    reducers: {
        // reset submission state obj to it's initial value
        resetSubmission: (state, _) => {
            state.submission = initialSubmissionState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDoctorTime.pending, (state) => {
                state.status = "pending";
                state.error = null;
            })
            .addCase(getDoctorTime.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.data = action.payload;
                state.error = null;
            })
            .addCase(getDoctorTime.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(selectApptTime.pending, (state) => {
                state.submission.status = "pending";
                state.submission.error = null;
            })
            .addCase(selectApptTime.fulfilled, (state) => {
                state.submission.status = "succeeded";
                state.submission.error = null;
            })
            .addCase(selectApptTime.rejected, (state, action) => {
                state.submission.status = "failed";
                state.submission.error = action.payload;
            });
    },
});

export const { resetSubmission } = doctorTimeSlice.actions;
export default doctorTimeSlice.reducer;
