import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

import { toggleShow } from "../../features/modalSlice";
import AdminModal from "./AdminModal";
import AdminLoadingSpinner from "./AdminLoadingSpinner";

const AdminConfirmModal = ({
    slice,
    title,
    text,
    data,
    clickHandler,
    resetMethod,
    action,
}) => {
    const dispatch = useDispatch();
    const { status, error } = useSelector((state) => state[slice].method);

    useEffect(() => {
        dispatch(resetMethod());
    }, []);

    const closeModal = () => {
        dispatch(toggleShow());
    };

    const handleClick = () => {
        return new Promise((resolve, _) => {
            dispatch(clickHandler(data.id)).then((res) => {
                if (!res.error) {
                    closeModal();
                }
                resolve();
            });
        });
    };

    const boxStyle = {
        minWidth: 450,
        height: 240,
        position: "absolute",
        top: "50%",
        left: "50%",
        display: "flex",
        flexDirection: "column",
        transform: "translate(-50%, -50%)",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    return (
        <AdminModal>
            <Box sx={boxStyle} component={Paper}>
                {status === "pending" ? (
                    <AdminLoadingSpinner />
                ) : (
                    <>
                        {status === "failed" && (
                            <Alert severity="error" sx={{ marginBottom: 1 }}>
                                {error}
                            </Alert>
                        )}
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h6"
                            sx={{ marginBottom: 2 }}
                        >
                            {title}
                        </Typography>
                        <Stack
                            sx={{
                                flexGrow: 1,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Typography>{text}</Typography>
                            <Stack
                                direction={"row"}
                                width={"100%"}
                                marginTop={2}
                                justifyContent={"flex-end"}
                            >
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={closeModal}
                                    sx={{
                                        marginRight: 1,
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={handleClick}
                                >
                                    {action}
                                </Button>
                            </Stack>
                        </Stack>
                    </>
                )}
            </Box>
        </AdminModal>
    );
};

export default AdminConfirmModal;
