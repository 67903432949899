import React from "react";
import { Link } from "react-router-dom";

const PageFooter = ({
    requestDbqs,
    allPaqsReviewed,
    allPaqsCompleted,
    isExam4Appt,
}) => {
    if (allPaqsCompleted) {
        if (allPaqsReviewed) {
            return (
                <>
                    <div className="page-title-container">
                        <h3 className="page-title">
                            Request DBQs With This Information?
                        </h3>
                    </div>
                    <div className="page-btn-container">
                        <Link to={"/downloads/"} className="btn primary">
                            Yes
                        </Link>
                    </div>
                </>
            );
        } else if (isExam4Appt) {
            return (
                <div className="page-title-container">
                    <h3 className="page-title">
                        We are currently waiting for your doctor to review your
                        Disability Benefits Questionnaire. Once complete, we
                        will send you a notification.
                    </h3>
                </div>
            );
        } else {
            return (
                <>
                    <div className="page-title-container">
                        <h3 className="page-title">
                            Schedule an Evaluation for your conditions?
                        </h3>
                    </div>
                    <div className="page-btn-container">
                        <a
                            href="/appointments/unlocked"
                            className="btn primary"
                        >
                            Yes
                        </a>
                    </div>
                </>
            );
        }
    }

    return null;
};

export default PageFooter;
