import React from "react";
import { createBrowserRouter } from "react-router-dom";
import ProtectedRoute from "@/common/components/ProtectedRoute";

import Layout from "./pages/Layout";
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import ReferralLink from "./components/ReferralLink";
import NoMatch from "./pages/errors/NoMatch";
import Products from "./pages/Products";
import OrderHistory from "./pages/OrderHistory";
import Transcripts from "./pages/Transcripts";
import AdminLayout from "../common/components/admin/AdminLayout";
import AdminMedHistory from "./pages/admin/AdminMedHistory";
import AdminRefunds from "./pages/admin/AdminRefunds";
import AdminRefundTickets from "./pages/admin/AdminRefundTickets";
import AdminRefundHistory from "./pages/admin/AdminRefundHistory";
import { AdminIconMap } from "./AdminIconMap";
import MedicalHistory from "./pages/MedicalHistory";
import CheckPayments from "./pages/CheckPayments";
import CheckInformation from "./pages/CheckInformation";
import ConditionHistory from "./pages/condition_exam/ConditionHistory";
import AddConditionSurvey from "./pages/condition_exam/AddConditionSurvey";
import Refunds from "./pages/Refunds";
import RefundTickets from "./pages/refund_tickets/RefundTickets";
import RefundHistory from "./pages/refund_history/RefundHistory";
import ConditionExams from "./pages/condition_exam/ConditionExams";
import Iaq from "./pages/iaq/Iaq";
import IAQSurvey from "./pages/iaq/IAQSurvey";
import PasswordReset from "./pages/PasswordReset";
import PasswordResetForm from "./pages/PasswordResetForm";
import SignupForm from "./pages/SignupForm";
import Main from "./pages/Main";
import TwoFactorBase from "./pages/two_factor/TwoFactorBase";
import TwoFactorMethod from "./pages/two_factor/TwoFactorMethod";
import TwoFactorInput from "./pages/two_factor/TwoFactorInput";
import StrGuide from "./pages/medical_history/StrGuide";
import MedHistoryBase from "./pages/medical_history/MedHistoryBase";
import Itf from "./pages/itf/Itf";
import ITFSuccess from "./pages/itf/ITFSuccess";
import ITFDone from "./pages/itf/ITFDone";
import ITFUnlocked from "./pages/itf/ItfUnlocked";
import ITFSubmit from "./pages/itf/ITFSubmit";
import AppointmentsBase from "./pages/AppointmentsBase";
import AppointmntsUnlocked from "./pages/appointments_unlocked/AppointmntsUnlocked";
import DoctorList from "./pages/doctor_list/DoctorList";
import DoctorTime from "./pages/doctor_time/DoctorTime";
import ApptSummary from "./pages/appt_summary/ApptSummary";
import ApptSuccess from "./pages/appt_success/ApptSuccess";
import BenefitSummary from "./pages/benefit_summary/BenefitSummary";
import ReleaseOfInformation from "./pages/ReleaseOfInformation";
import EligibilityRequirements from "./pages/iaq/EligibilityRequirements";
import Logout from "./pages/accounts/Logout";
import Settings from "./pages/settings/Settings";
import Security from "./pages/settings/Security";
import Account from "./pages/settings/Account";
import Notification from "./pages/settings/Notification";
import About from "./pages/settings/About";
import Support from "./pages/settings/Support";
import DeleteInfo from "./pages/settings/DeleteInfo";
import SecurityStandard from "./pages/settings/SecurityStandard";
import NotificationCenter from "./pages/notifications/NotificationCenter";
import ArchivedNotifications from "./pages/notifications/ArchivedNotifications";
import Notifications from "./pages/notifications/Notifications";
import PAQ from "./pages/paqs/PAQ";
import PAQDashboard from "./pages/paqs/PAQDashboard";
import PAQSurvey from "./pages/paqs/PAQSurvey";
import CurrentConditions from "./pages/condition_exam/CurrentConditions";
import TestAudioVideo from "../telehealth/components/testaudiovideo";
import Checkout from "./pages/checkout/Checkout";
import CheckoutPage from "./pages/checkout/CheckoutPage";
import CheckoutSuccess from "./pages/checkout/CheckoutSuccess";
import Claims from "./pages/claims/Claims";
import SubmitClaims from "./pages/claims/ClaimsSubmit";
import ServerError from "./pages/errors/ServerError";
import CancelAppt from "./pages/cancel_appt/CancelAppt";
import Downloads from "./pages/downloads/Downloads";
import AccountVerified from "./pages/users/AccountVerified";

const Router = createBrowserRouter([
    {
        path: "/",
        element: <Main />,
        errorElement: <ServerError />,
        children: [
            {
                path: "",
                element: <Landing />,
            },
            {
                path: "users/twofactor/",
                element: <TwoFactorBase />,
                children: [
                    {
                        path: "method/",
                        element: <TwoFactorMethod />,
                    },
                    {
                        path: "input/",
                        element: <TwoFactorInput />,
                    },
                ],
            },
            {
                path: "accounts/login",
                element: <Login />,
            },
            {
                path: "release-of-information",
                element: <ReleaseOfInformation />,
            },
            {
                path: "accounts/logout/",
                element: <Logout />,
            },
            {
                path: "accounts/password/reset/",
                element: <PasswordReset />,
            },
            {
                path: "accounts/password/reset/key/:token",
                element: <PasswordResetForm />,
            },
            {
                path: "users/signup",
                element: <SignupForm />,
            },
            {
                path: "users/account-verified",
                element: <AccountVerified />,
            },
        ],
    },
    {
        element: <ProtectedRoute />,
        errorElement: <ServerError />,
        children: [
            {
                element: <Layout />,
                children: [
                    {
                        path: "/dashboard",
                        element: <Dashboard />,
                    },
                    {
                        path: "users/referral",
                        element: <ReferralLink />,
                    },
                    {
                        path: "/benefit-summary",
                        element: <BenefitSummary />,
                    },
                    {
                        path: "/products/",
                        element: <Products />,
                        children: [
                            {
                                path: "checkout/",
                                element: <Checkout />,
                                children: [
                                    {
                                        path: "",
                                        element: <CheckoutPage />,
                                    },
                                    {
                                        path: "success/",
                                        element: <CheckoutSuccess />,
                                    },
                                ],
                            },
                            {
                                path: "orders/",
                                element: <OrderHistory />,
                            },
                            {
                                path: "check_payments/",
                                element: <CheckInformation />,
                            },
                            {
                                path: "refunds/",
                                element: <Refunds />,
                                children: [
                                    {
                                        path: "tickets/",
                                        element: <RefundTickets />,
                                    },
                                    {
                                        path: "history/",
                                        element: <RefundHistory />,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        path: "/downloads/",
                        children: [
                            {
                                index: true,
                                element: <Downloads />,
                            },
                            {
                                path: "transcripts/",
                                element: <Transcripts />,
                            },
                        ],
                    },
                    {
                        path: "/notifications/",
                        element: <Notifications />,
                        children: [
                            {
                                path: "",
                                element: <NotificationCenter />,
                            },
                            {
                                path: "archived/",
                                element: <ArchivedNotifications />,
                            },
                        ],
                    },
                    {
                        path: "/paqs/",
                        element: <PAQ />,
                        children: [
                            {
                                path: "",
                                element: <PAQDashboard />,
                            },
                            {
                                path: "paq-survey/:disabilityName/",
                                element: <PAQSurvey />,
                            },
                        ],
                    },
                    {
                        path: "/iaqs/",
                        element: <Iaq />,
                        children: [
                            {
                                path: "iaq-survey/",
                                element: <IAQSurvey />,
                            },
                            {
                                path: "eligibility/",
                                element: <EligibilityRequirements />,
                            },
                        ],
                    },
                    {
                        path: "/itf/",
                        children: [
                            {
                                path: "unlocked/",
                                element: <ITFUnlocked />,
                            },
                            {
                                index: true,
                                element: <Itf />,
                            },
                            {
                                path: "submit/",
                                element: <ITFSubmit />,
                            },
                            {
                                path: "success/",
                                element: <ITFSuccess />,
                            },
                            {
                                path: "done/",
                                element: <ITFDone />,
                            },
                        ],
                    },
                    {
                        path: "/claims/",
                        children: [
                            {
                                index: true,
                                element: <Claims />,
                            },
                            {
                                path: "submit/",
                                element: <SubmitClaims />,
                            },
                        ],
                    },
                    {
                        path: "/settings/delete-info",
                        element: <DeleteInfo />,
                    },
                    {
                        path: "/security-standards",
                        element: <SecurityStandard />,
                    },
                    {
                        path: "/audio-video-test",
                        element: <TestAudioVideo />,
                    },
                    {
                        path: "/settings/manage/",
                        element: <Settings />,
                        children: [
                            {
                                path: "account/",
                                element: <Account />,
                            },
                            {
                                path: "security/",
                                element: <Security />,
                            },
                            {
                                path: "notifications/",
                                element: <Notification />,
                            },
                            {
                                path: "about/",
                                element: <About />,
                            },
                            {
                                path: "support/",
                                element: <Support />,
                            },
                        ],
                    },
                    {
                        path: "/medical-history/",
                        element: <MedHistoryBase />,
                        children: [
                            {
                                path: "",
                                element: <MedicalHistory />,
                            },
                            {
                                path: "str-guide/",
                                element: <StrGuide />,
                            },
                        ],
                    },
                    {
                        path: "/condition_exam/",
                        children: [
                            {
                                index: true,
                                element: <ConditionExams />,
                            },
                            {
                                path: "condition-history/",
                                element: <ConditionHistory />,
                            },
                            {
                                path: "add_conditions/",
                                element: <AddConditionSurvey />,
                            },
                            {
                                path: "current_conditions/",
                                element: <CurrentConditions />,
                            },
                        ],
                    },
                    {
                        path: "/appointments/",
                        element: <AppointmentsBase />,
                        children: [
                            {
                                path: "unlocked/",
                                element: <AppointmntsUnlocked />,
                            },
                            {
                                path: "select-doctor/",
                                element: <DoctorList />,
                            },
                            {
                                path: "select-datetime/",
                                element: <DoctorTime />,
                            },
                            {
                                path: "summary/",
                                element: <ApptSummary />,
                            },
                            {
                                path: "success/",
                                element: <ApptSuccess />,
                            },
                            {
                                path: "cancel/",
                                element: <CancelAppt />,
                            },
                        ],
                    },
                ],
            },
            {
                path: "/control-hub/",
                element: <AdminLayout AdminIconMap={AdminIconMap} />,
                children: [
                    {
                        path: "medical-history/",
                        element: <AdminMedHistory />,
                    },
                    {
                        path: "check-payments/",
                        element: <CheckPayments />,
                    },
                    {
                        path: "refunds/",
                        element: <AdminRefunds />,
                        children: [
                            {
                                path: "tickets/",
                                element: <AdminRefundTickets />,
                            },
                            {
                                path: "history/",
                                element: <AdminRefundHistory />,
                            },
                        ],
                    },
                ],
            },
        ],
    },

    {
        path: "*",
        element: <NoMatch />,
    },
]);

export default Router;
