import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import "./modal.scss";

const Modal = ({ children }) => {
    const is_open = useSelector((state) => state.modal.is_open);

    useEffect(() => {
        if (is_open) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    }, [is_open]);

    return (
        <div id="modalOverlay" className={`${is_open && "show-modal"}`}>
            {children}
        </div>
    );
};

export default Modal;
