import React, { useState, useRef, useEffect } from "react";
import Croppie from "croppie";
import "croppie/croppie.css";
import "./ProfilePhotoUpload.scss";
import Close from "@/common/images/icons/close.svg";

const croppieOptions = {
    showZoomer: true,
    enableOrientation: true,
    viewport: {
        width: 200,
        height: 200,
        type: "circle",
    },
    boundary: {
        width: 300,
        height: 300,
    },
};

const ProfilePhotoUpload = ({ onImageUpload }) => {
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const modalRef = useRef(null);
    const croppieInstanceRef = useRef(null);
    const fileInputRef = useRef(null);
    const croppieElementRef = useRef(null);

    useEffect(() => {
        // Initialize Croppie instance only once
        if (croppieElementRef.current && !croppieInstanceRef.current) {
            croppieInstanceRef.current = new Croppie(
                croppieElementRef.current,
                croppieOptions
            );
        }

        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setModalVisible(false);
                handleCancel();
            }
        };

        if (modalVisible) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        // Cleanup function
        return () => {
            if (croppieInstanceRef.current) {
                croppieInstanceRef.current.destroy();
                croppieInstanceRef.current = null;
            }
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [modalVisible]);

    const onFileUpload = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target && e.target.result && croppieInstanceRef.current) {
                    croppieInstanceRef.current.bind({ url: e.target.result });
                    setIsFileUploaded(true);
                }
            };
            reader.readAsDataURL(e.target.files[0]);
            setModalVisible(true); // Open modal after file is loaded
        }
    };

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    const onResult = () => {
        if (croppieInstanceRef.current) {
            croppieInstanceRef.current
                .result({
                    type: "base64",
                    format: "jpeg",
                    size: { width: 138, height: 138 },
                })
                .then((resp) => {
                    // get the file name from fileInputRef
                    const fileName = fileInputRef.current.files[0].name;
                    const file = dataURLtoFile(resp, fileName);
                    onImageUpload(file);
                    setIsFileUploaded(false);
                    handleCancel();
                });
        }
    };

    const handleUploadClick = () => {
        fileInputRef.current?.click();
    };

    const handleCancel = () => {
        setIsFileUploaded(false);
        setModalVisible(false);
        if (croppieInstanceRef.current) {
            croppieInstanceRef.current.destroy();
            croppieInstanceRef.current = new Croppie(
                croppieElementRef.current,
                croppieOptions
            );
        }
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const handleSave = () => {
        onResult();
        setModalVisible(false);
    };

    return (
        <div id="profile-photo-upload">
            <button
                type="button"
                onClick={handleUploadClick}
                className="link-blk"
            >
                Upload New Profile Photo
            </button>
            <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={onFileUpload}
            />
            {modalVisible && (
                <div id="profile-photo-modal">
                    <div className="modal-content" ref={modalRef}>
                        <button
                            type="button"
                            className="close-btn"
                            onClick={handleCancel}
                        >
                            <Close width={51} height={52} />
                        </button>

                        <p className="modal-title">
                            Crop your new profile picture
                        </p>
                        <div id="profile-photo-upload-content">
                            <div
                                ref={croppieElementRef}
                                style={{
                                    display: isFileUploaded ? "block" : "none",
                                }}
                            ></div>
                            {isFileUploaded && (
                                <div className="modal-btn-container">
                                    <button
                                        type="button"
                                        onClick={handleCancel}
                                        className="btn outline"
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="button"
                                        onClick={handleSave}
                                        className="btn primary"
                                    >
                                        Save
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProfilePhotoUpload;
