import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
    name: "modal",
    initialState: {
        is_open: false,
        data: null,
    },
    reducers: {
        toggleShow: (state, action) => {
            state.is_open = !state.is_open;
            state.data = action.payload ? action.payload : null;
        },
    },
});

export const { toggleShow } = modalSlice.actions;

export default modalSlice.reducer;
