import React from "react";
import "./SecurityStandard.scss";
import { Link } from "react-router-dom";

const SecurityStandard = () => {
    return (
        <div id="security-standard">
            <h3 className="page-title">Security Standards</h3>

            <div className="security-content">
                <p className="security-title">Data encryption</p>
                <p className="security-text">
                    DBQPro™ encrypts data in transit and at rest
                </p>

                <ul className="security-text">
                    <li>
                        <b>Encryption in transit:</b> All data sent to or from
                        DBQPro™ infrastructure is encrypted in transit using
                        Transport Layer Security (TLS)
                    </li>
                    <li>
                        <b>Encryption at rest:</b> All user data is encrypted in
                        the database using the AES-256 encryption standard
                    </li>
                    <li>
                        <b>Extra encryption for sensitive data:</b> We secure
                        sensitive fields using industry best practices to salt
                        and repeatedly hash data before it is stored in the
                        database
                    </li>
                </ul>

                <p className="security-title">
                    Application monitoring and protection
                </p>
                <p className="security-text">
                    We have deployed an array of solutions to monitor and
                    protect our applications, including:
                </p>

                <ul className="security-text">
                    <li>
                        A Web Application Firewall (WAF) that identifies and
                        blocks Open Web Application Security Project (OWASP) Top
                        10 and business logic attacks in real time
                    </li>
                    <li>
                        Technologies to monitor exceptions and detect anomalies
                        in our applications
                    </li>
                    <li>
                        Collection and storage of application logs to provide an
                        audit trail of our application activity
                    </li>
                    <li>Security headers to protect our users from attacks</li>
                </ul>
            </div>

            <div className="page-btn-container">
                <Link to="/dashboard" className="btn primary">
                    Homepage
                </Link>
            </div>
        </div>
    );
};

export default SecurityStandard;
