import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const API_BASE_URL = "/claims";
// Helper function for error handling
export const handleError = (
    error,
    customErrorMsg = "An unexpected error occurred. Please try again later."
) => {
    if (error.response) {
        const errorData = error?.response?.data || {};
        if (errorData?.detail) {
            return errorData?.detail || customErrorMsg;
        }
        if (errorData?.message) {
            return errorData?.message || customErrorMsg;
        }
        if (errorData?.error) {
            return errorData?.error || customErrorMsg;
        }
    } else if (error.request) {
        return error?.message || customErrorMsg;
    } else {
        return error?.message || customErrorMsg;
    }
};

// Update existing async thunks
export const getClaimsData = createAsyncThunk(
    "claims/getClaimsData",
    async (_, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await axiosInstance.get(`${API_BASE_URL}/claims/`);
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to fetch claims data.")
            );
        }
    }
);
export const createClaim = createAsyncThunk(
    "claims/createClaim",
    async (_, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await axiosInstance.post(
                `${API_BASE_URL}/create-claim/`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to create claim.")
            );
        }
    }
);

export const saveClaimsProgress = createAsyncThunk(
    "claims/saveClaimsProgress",
    async (data, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await axiosInstance.post(
                `${API_BASE_URL}/save-progress/`,
                data
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to save Claims progress.")
            );
        }
    }
);
// This function is used to complete the Claims form after the user has filled out the survey
export const completeClaimsForm = createAsyncThunk(
    "claims/completeClaims",
    async (data, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await axiosInstance.post(
                `${API_BASE_URL}/complete/`,
                data
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to complete Claims form.")
            );
        }
    }
);

export const getClaimsForm = createAsyncThunk(
    "claims/getClaimsForm",
    async (_, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await axiosInstance.get(
                `${API_BASE_URL}/submit-claim/`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to submit Claims form.")
            );
        }
    }
);

export const getClaimsDocumentData = createAsyncThunk(
    "claims/getClaimsDocumentData",
    async (claim_doc_id, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await axiosInstance.get(
                `${API_BASE_URL}/get_data/${claim_doc_id}/`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to get Claims document data.")
            );
        }
    }
);

export const getClaimsDoneData = createAsyncThunk(
    "claims/getClaimsDoneData",
    async (_, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await axiosInstance.get(`${API_BASE_URL}/done/`);
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to get Claims done data.")
            );
        }
    }
);

const claimsSlice = createSlice({
    name: "claims",
    initialState: {
        surveyData: null,
        currentData: null,
        documentData: null,
        doneData: null,
        status: "idle",
        error: null,
        redirect: null,
        context: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getClaimsData.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getClaimsData.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.surveyData = action.payload;
                state.redirect = action.payload.redirect;
                if (action.payload.status === "completed") {
                    state.context = action.payload.context;
                    state.status = "completed";
                }
                if (action.payload.status === "claims-done") {
                    state.context = action.payload.context;
                    state.status = "claims-done";
                }
            })
            .addCase(getClaimsData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(createClaim.pending, (state) => {
                state.status = "pendingCreateClaim";
            })
            .addCase(createClaim.fulfilled, (state, action) => {
                state.status = "succeeded";
                // state.claims = action.payload;
            })
            .addCase(createClaim.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(saveClaimsProgress.fulfilled, (state, action) => {
                state.status = "succeeded";
            })
            .addCase(saveClaimsProgress.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(completeClaimsForm.pending, (state) => {
                state.status = "pending";
            })
            .addCase(completeClaimsForm.fulfilled, (state, action) => {
                state.status = "succeeded";
            })
            .addCase(completeClaimsForm.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(getClaimsDocumentData.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.documentData = action.payload;
            })
            .addCase(getClaimsDocumentData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(getClaimsForm.pending, (state) => {
                state.status = "loadingGetClaimsForm";
            })
            .addCase(getClaimsForm.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.currentData = action.payload;
                state.redirect = action.payload.redirect;
            })
            .addCase(getClaimsForm.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            });
    },
});

export default claimsSlice.reducer;
