import { useState, useRef, useCallback } from "react";

const useAlert = () => {
    const [alertType, setAlertType] = useState(null);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertVisible, setAlertVisible] = useState(false);
    const timeoutRef = useRef(null);

    const showAlert = useCallback(
        ({ alertType, alertMessage, duration = 5000 }) => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            setAlertType(alertType);
            setAlertMessage(alertMessage);
            setAlertVisible(true);

            timeoutRef.current = setTimeout(() => {
                setAlertVisible(false);
            }, duration);
        },
        []
    );

    const hideAlert = useCallback(() => {
        setAlertVisible(false);
        setAlertType(null);
        setAlertMessage("");

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }, []);

    return {
        alertType,
        alertMessage,
        alertVisible,
        showAlert,
        hideAlert,
    };
};

export default useAlert;
