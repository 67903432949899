import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    STATE_CHOICES,
    GENDER_CHOICES,
} from "../../utils/constants/settings-constants";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, updateUserData } from "../../features/settingsSlice";
import Alert from "../../../common/components/Alert";
import ProfilePhotoUpload from "./ProfilePhotoUpload";
import useAlert from "../../hooks/useAlert";

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone_number: Yup.string()
        .matches(
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
            "Invalid phone number"
        )
        .required("Phone Number is required"),
    street_name: Yup.string().required("Street Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zip_code: Yup.string()
        .matches(/^\d{5}(-\d{4})?$/, "Invalid ZIP code")
        .required("ZIP Code is required"),
    gender: Yup.string().required("Gender is required"),
});

const Account = () => {
    const dispatch = useDispatch();
    const { userData, status } = useSelector((state) => state.settings);
    const { alertType, alertMessage, alertVisible, showAlert } = useAlert();

    useEffect(() => {
        dispatch(getUserData());
    }, [dispatch]);

    const handleSubmit = (values, { setSubmitting }) => {
        dispatch(updateUserData(values))
            .unwrap()
            .then(() => {
                showAlert({
                    alertType: "success",
                    alertMessage: "Profile updated successfully",
                });
            })
            .catch((error) => {
                showAlert({
                    alertType: "error",
                    alertMessage: error || "Error updating profile",
                });
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <>
            <div className="page-top-container">
                <img src="/static/settings/img/bell-icon-blue.png" alt="bell" />
                <h6 className="page-top-header">Account</h6>
            </div>

            <div className="page-middle">
                <div className="settings-column">
                    <div className="content-container">
                        <Formik
                            initialValues={{
                                first_name: userData?.first_name ?? "",
                                last_name: userData?.last_name ?? "",
                                email: userData?.email ?? "",
                                phone_number: userData?.phone_number ?? "",
                                street_name: userData?.street_name ?? "",
                                city: userData?.city ?? "",
                                state: userData?.state ?? "",
                                zip_code: userData?.zip_code ?? "",
                                gender: userData?.gender ?? "",
                                image: userData?.image ?? null,
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                            enableReinitialize
                            validateOnMount={true}
                        >
                            {({
                                errors,
                                touched,
                                isSubmitting,
                                values,
                                dirty,
                                isValid,
                                setFieldValue,
                            }) => (
                                <Form
                                    id="profile-form"
                                    encType="multipart/form-data"
                                >
                                    <div className="settings-header">
                                        <div className="content-container">
                                            <h3 className="settings-title">
                                                Edit Profile
                                            </h3>
                                            {alertVisible && (
                                                <Alert
                                                    type={alertType}
                                                    message={alertMessage}
                                                />
                                            )}
                                            <img
                                                id="img-id"
                                                src={
                                                    values.image
                                                        ? typeof values.image ===
                                                          "string"
                                                            ? values.image
                                                            : URL.createObjectURL(
                                                                  values.image
                                                              )
                                                        : "/static/img/profile-image.png"
                                                }
                                                alt="profile picture"
                                                className="profile-picture"
                                            />
                                            {/* Image upload functionality */}
                                            <ProfilePhotoUpload
                                                onImageUpload={(file) => {
                                                    setFieldValue(
                                                        "image",
                                                        file
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="profile-input-container">
                                        <InputField
                                            label="First Name"
                                            name="first_name"
                                            errors={errors}
                                            touched={touched}
                                        />
                                        <InputField
                                            label="Last Name"
                                            name="last_name"
                                            errors={errors}
                                            touched={touched}
                                        />
                                        <InputField
                                            label="Email"
                                            name="email"
                                            type="email"
                                            errors={errors}
                                            touched={touched}
                                        />
                                        <InputField
                                            label="Phone Number"
                                            name="phone_number"
                                            errors={errors}
                                            touched={touched}
                                        />
                                        <InputField
                                            label="Street Address"
                                            name="street_name"
                                            errors={errors}
                                            touched={touched}
                                        />
                                        <InputField
                                            label="City"
                                            name="city"
                                            errors={errors}
                                            touched={touched}
                                        />
                                        <SelectField
                                            label="State"
                                            name="state"
                                            options={STATE_CHOICES}
                                            errors={errors}
                                            touched={touched}
                                        />
                                        <InputField
                                            label="Zip Code"
                                            name="zip_code"
                                            errors={errors}
                                            touched={touched}
                                        />
                                        <SelectField
                                            label="Gender"
                                            name="gender"
                                            options={GENDER_CHOICES}
                                            errors={errors}
                                            touched={touched}
                                        />
                                    </div>
                                    <div className="page-btn-container-settings my-2">
                                        <button
                                            type="submit"
                                            className="btn primary"
                                            disabled={
                                                status === "loading" ||
                                                isSubmitting ||
                                                !dirty ||
                                                !isValid
                                            }
                                        >
                                            {isSubmitting ||
                                            status === "loading" ? (
                                                <div className="loader"></div>
                                            ) : (
                                                "Apply"
                                            )}
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
};

const InputField = ({ label, name, type = "text", errors, touched }) => (
    <div className="input-container input-margin">
        <label htmlFor={name}>{label}</label>
        <Field type={type} id={name} name={name} placeholder={label} />
        {errors[name] && touched[name] && (
            <div className="input-error">{errors[name]}</div>
        )}
    </div>
);

const SelectField = ({ label, name, options, errors, touched }) => (
    <div className="input-container input-margin">
        <label htmlFor={name}>{label}</label>
        <Field as="select" className="select-style" id={name} name={name}>
            <option value="">Select {label}</option>
            {Object.entries(options).map(([key, value]) => (
                <option key={key} value={key}>
                    {value}
                </option>
            ))}
        </Field>
        {errors[name] && touched[name] && (
            <div className="input-error">{errors[name]}</div>
        )}
    </div>
);

export default Account;
