import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { cachedAxiosInstance } from "@/common/utils/axios";

export const getPatientTranscripts = createAsyncThunk(
    "downloads/transcripts",
    async (patientId, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.get(
                `/downloads/api/transcripts/${patientId}/`
            );
            const data = await response.data;
            return data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to get patient transcripts."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const transcriptsSlice = createSlice({
    name: "transcripts",
    initialState: {
        transcripts: [],
        status: "idle",
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPatientTranscripts.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getPatientTranscripts.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.transcripts = action.payload;
                state.error = null;
            })
            .addCase(getPatientTranscripts.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            });
    },
});

export default transcriptsSlice.reducer;
