import React from "react";

import dashboard from "@/common/images/icons/dashboard-gif.gif";
import gear from "@/common/images/icons/gear-gif.gif";
import help from "@/common/images/icons/help1.gif";
import ProgressCircle from "../progress_circle/ProgressCircle";
import SuccessStarLogo from "../SuccessStarLogo";
import ProgressBarAnim from "./animations/ProgressBarAnim";
import NavbarItemAnim from "./animations/NavbarItemAnim";
import ProcessStepsAnim from "./animations/ProcessStepsAnim";
import PageFAQAnim from "./animations/PageFAQAnim";

const strNext = "Next";
const strColumn = "column";

// TODO: finish steps details
export const stepMap = [
    {
        id: 1,
        animation: <SuccessStarLogo />,
        title: "Welcome to VetVantage™",
        instruction:
            "A step-by-step process that makes it easy to generate medical evidence to support your VA disability claim. Here are some tools to help you get the benefits you deserve!",
        btnText: "Get Started Now",
        direction: strColumn,
    },
    {
        id: 2,
        animation: <ProgressCircle valueStart={0} valueEnd={100} />,
        title: "Overall Progress",
        instruction:
            "The progress meter shows how far you are in the VetVantage™ process. At 100% completion, you will have all the documentation necessary to take to a third-party medical provider to support your claim.",
        btnText: strNext,
        direction: strColumn,
    },
    {
        id: 3,
        animation: <ProgressBarAnim />,
        title: "Progress Bar",
        instruction:
            "The progress bar demonstrates how much you have completed in each individual step of the VetVantage™ process.",
        btnText: strNext,
        direction: strColumn,
    },
    {
        id: 4,
        animation: (
            <NavbarItemAnim>
                <img
                    src={dashboard}
                    alt="Dashboard"
                    style={{ width: 32, height: 32 }}
                />
            </NavbarItemAnim>
        ),
        title: "VetVantage™ Dashboard",
        instruction:
            "The dashboard shows your current and estimated ratings, next steps, and more.",
        btnText: strNext,
        direction: strColumn,
    },
    {
        id: 5,
        animation: (
            <NavbarItemAnim>
                <img src={gear} alt="Gear" style={{ width: 32, height: 32 }} />
            </NavbarItemAnim>
        ),
        title: "Settings",
        instruction:
            "The settings page allows you to manage your profile and log out of your account.",
        btnText: strNext,
        direction: strColumn,
    },
    {
        id: 6,
        animation: (
            <NavbarItemAnim>
                <img src={help} alt="Help" style={{ width: 32, height: 32 }} />
            </NavbarItemAnim>
        ),
        title: "Help",
        instruction:
            "The help section contains resource guides, our FAQ page, and VetVantage™ support.",
        btnText: strNext,
        direction: strColumn,
    },
    {
        id: 7,
        animation: <ProcessStepsAnim />,
        title: "Process Steps",
        instruction:
            "The process steps show where you are in the VetVantage™ process.",
        btnText: strNext,
        direction: "row-reverse",
    },
    {
        id: 8,
        animation: <PageFAQAnim />,
        title: "Page FAQ",
        instruction:
            "VA language can be very technical. This section will help you understand what we are asking and why it is important.",
        btnText: "Finish",
        direction: strColumn,
    },
];
