import React from "react";
import Profile from "@/common/images/icons/profile.svg";
import ClipboardPlus from "@/common/images/icons/clipboardplus.svg";
import CalendarPlus from "@/common/images/icons/calendarplus.svg";
import Checkbox from "@/common/images/icons/checkbox.svg";
import Download from "@/common/images/icons/download.svg";
import Document from "@/common/images/icons/document.svg";
import FileArrowLeft from "@/common/images/icons/filearrowleft.svg";

//Icon Component to be used everywhere through out the application
const IconComponent = ({ iconKey, iconSize = 18 }) => {
    const idIconMap = {
        iaq: <Profile width={iconSize} height={iconSize} />,
        conditions1: <ClipboardPlus width={iconSize} height={iconSize} />,
        conditions2: <ClipboardPlus width={iconSize} height={iconSize} />,
        conditions3: <ClipboardPlus width={iconSize} height={iconSize} />,
        appointments: <CalendarPlus width={iconSize} height={iconSize} />,
        paqs: <ClipboardPlus width={iconSize} height={iconSize} />,
        benefits: <Checkbox width={iconSize} height={iconSize} />,
        downloads: <Download width={iconSize} height={iconSize} />,
        itf: <Document width={iconSize} height={iconSize} />,
        claim: <FileArrowLeft width={iconSize} height={iconSize} />,
        dbq: <Document width={iconSize} height={iconSize} />,
        medical_history: <Document width={iconSize} height={iconSize} />,
    };

    let Icon = idIconMap[iconKey];

    if (!Icon) {
        return null; // or a default icon, or an error message
    }

    return Icon;
};

export default IconComponent;
