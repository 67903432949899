import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import { getRefunds } from "../../features/refundsSlice";
import { toggleShow } from "../../features/modalSlice";
import { formatDate } from "../../utils";
import AdminLoadingSpinner from "../../components/admin/AdminLoadingSpinner";
import AdminRefundTicketModal from "../../components/admin/AdminRefundTicketModal";
import Pagination from "../../components/Pagination/Pagination";

const AdminRefundHistory = () => {
    const dispatch = useDispatch();

    const { refunds, status, error, pagination } = useSelector(
        (state) => state.refunds
    );

    const modalData = useSelector((state) => state.modal.data);

    const isLoading = useMemo(
        () => status == "pending" || status == "idle",
        [status]
    );

    useEffect(() => {
        dispatch(getRefunds({ status: "complete" }));
    }, []);

    const openModal = (id) => {
        dispatch(toggleShow({ id: id }));
    };

    if (status === "failed") {
        return (
            <Alert severity="error" sx={{ marginBottom: 1 }}>
                {error}
            </Alert>
        );
    } else {
        return (
            <Container maxWidth="">
                <Typography
                    variant="h5"
                    component="div"
                    sx={{ flexGrow: 1, paddingTop: 2, paddingBottom: 2 }}
                >
                    Refund History
                </Typography>
                {isLoading ? (
                    <AdminLoadingSpinner />
                ) : refunds.length ? (
                    <TableContainer component={Paper} sx={{ minHeight: 200 }}>
                        <Table aria-label="simple table" sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Patient</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Ticket</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {refunds.map(
                                    ({
                                        id,
                                        patient,
                                        ticket,
                                        amount,
                                        status,
                                        timestamp,
                                    }) => {
                                        return (
                                            <TableRow
                                                key={id}
                                                sx={{
                                                    "&:last-child td, &:last-child th":
                                                        {
                                                            border: 0,
                                                        },
                                                }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {id}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    <Stack>
                                                        <Typography
                                                            variant="p"
                                                            component="div"
                                                        >
                                                            {patient.first_name}{" "}
                                                            {patient.last_name}
                                                        </Typography>
                                                        <Typography
                                                            variant="p"
                                                            component="div"
                                                            color={"grey"}
                                                        >
                                                            {patient.email}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {formatDate(timestamp)}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {status.display_text}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {ticket}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    $
                                                    {parseFloat(amount).toFixed(
                                                        2
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        onClick={() =>
                                                            openModal(ticket)
                                                        }
                                                    >
                                                        Details
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                )}
                            </TableBody>
                        </Table>

                        {refunds?.length > 0 &&
                            pagination?.current_page &&
                            pagination?.total_pages &&
                            pagination?.page_size && (
                                <Pagination
                                    currentPage={pagination?.current_page ?? 1}
                                    total_pages={pagination?.total_pages ?? 1}
                                    itemsPerPage={pagination?.page_size ?? 10}
                                    // change current page
                                    onPageChange={(page) => {
                                        dispatch(
                                            getRefunds({
                                                status: "complete",
                                                page: page,
                                                page_size:
                                                    pagination?.page_size ?? 10,
                                            })
                                        );
                                    }}
                                    // change page size
                                    onItemsPerPageChange={(value) => {
                                        dispatch(
                                            getRefunds({
                                                status: "complete",
                                                page_size: value,
                                                page: 1, // reset page number
                                            })
                                        );
                                    }}
                                />
                            )}
                    </TableContainer>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Typography variant="h6" component="div">
                            No Refund yet.
                        </Typography>
                    </Box>
                )}
                {modalData && <AdminRefundTicketModal id={modalData.id} />}
            </Container>
        );
    }
};

export default AdminRefundHistory;
