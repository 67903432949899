import { configureStore } from "@reduxjs/toolkit";

import { cachedAxiosInstance } from "@/common/utils/axios";
import authReducer from "./features/authSlice";
import userReducer from "./features/userSlice";
import progressReducer from "./features/progressSlice";
import dashboardReducer from "./features/dashboardSlice";
import ordersReducer from "./features/ordersSlice";
import medicalHistoryReducer from "./features/medicalHistorySlice";
import checklistReducer from "./features/admin/medHistAdminSlice";
import TranscriptsReducer from "./features/transcriptsSlice";
import TranscriptDetailReducer from "./features/transcriptDetailSlice";
import modalReducer from "./features/modalSlice";
import conditionExamReducer from "./features/condition_exam/conditionExamSlice";
import disabilityReducer from "./features/productSlice";
import iaqReducer from "./features/iaqSlice";
import itfReducer from "./features/itf/itfSlice";
import refundTicketsReducer from "./features/refundTicketsSlice";
import refundsReducer from "./features/refundsSlice";
import ticketDetailReducer from "./features/TicketDetailSlice";
import signupReducer from "./features/signupSlice";
import twoFactorReducer from "./features/twoFactorSlice";
import appointmentReducer from "./features/appointmentSlice";
import doctorsSlice from "./features/doctorsSlice";
import doctorTimeSlice from "./features/doctorTimeSlice";
import ApptSummarySlice from "./features/ApptSummarySlice";
import benefitSummaryReducer from "./features/benefitSummarySlice";
import releaseOfInformationReducer from "./features/releaseOfInformationSlice";
import notificationsReducer from "./features/notificationsSlice";
import settingsReducer from "./features/settingsSlice";
import claimsReducer from "./features/claimsSlice";
import paqReducer from "./features/paqSlice";
import downloadSlice from "./features/downloadSlice";
import checkoutReducer from "./features/checkoutSlice";

const store = configureStore({
    reducer: {
        auth: authReducer,
        twoFactor: twoFactorReducer,
        user: userReducer,
        progress: progressReducer,
        dashboard: dashboardReducer,
        orders: ordersReducer,
        medicalHistory: medicalHistoryReducer,
        checklist: checklistReducer,
        transcripts: TranscriptsReducer,
        transcriptDetail: TranscriptDetailReducer,
        modal: modalReducer,
        conditionExam: conditionExamReducer,
        disabilities: disabilityReducer,
        iaq: iaqReducer,
        itf: itfReducer,
        disabilities: disabilityReducer,
        refundTickets: refundTicketsReducer,
        refunds: refundsReducer,
        ticketDetails: ticketDetailReducer,
        signup: signupReducer,
        appointment: appointmentReducer,
        doctors: doctorsSlice,
        doctorTime: doctorTimeSlice,
        appointmentSummary: ApptSummarySlice,
        benefit: benefitSummaryReducer,
        twoFactor: twoFactorReducer,
        roi: releaseOfInformationReducer,
        settings: settingsReducer,
        notifications: notificationsReducer,
        claims: claimsReducer,
        paq: paqReducer,
        downloads: downloadSlice,
        checkout: checkoutReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: cachedAxiosInstance,
            },
        }),
});

export default store;
