import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getItfDoneData } from "../../features/itf/itfSlice";
import MainUnlock from "../../components/MainUnlock";
import Spinner from "../../components/Spinner";
import { useNavigate } from "react-router-dom";

const ITFDone = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { doneData, status, error, redirect } = useSelector(
        (state) => state.itf
    );

    useEffect(() => {
        dispatch(getItfDoneData());
    }, [dispatch]);

    useEffect(() => {
        if (redirect) {
            // navigate to /itf/ if ITF doesn't exist
            navigate(redirect);
        }
    }, [redirect]);

    if (status === "loading") {
        return <Spinner />;
    }

    if (status === "failed" && error) {
        return <div>Error: {error}</div>;
    }

    if (!doneData) {
        return null;
    }

    return (
        <>
            <MainUnlock
                step_title={"You’ve Already Submitted an ITF"}
                step_btn_text={"Go to Dashboard"}
                message={doneData.success_msg}
                btn_url={"/dashboard/"}
                outlinedBtn
                btnSize="large"
                go_back_url={"/dashboard/"}
            >
                {doneData.creation_date && (
                    <p className="itf-done-label">
                        Submitted on {doneData.creation_date}
                    </p>
                )}
                {doneData.received_date && (
                    <p className="itf-done-label">
                        Received on {doneData.received_date}
                    </p>
                )}
            </MainUnlock>
        </>
    );
};

export default ITFDone;
