import React, { useEffect, useState } from "react";
import Chevron from "@/common/images/icons/chevron.svg";
import "./SidebarFAQ.scss";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardFAQ } from "@/vetvantage/features/dashboardSlice";

const SidebarFAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const { sidebarFAQ, status } = useSelector((state) => state.dashboard);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDashboardFAQ());
    }, [dispatch]);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    if (status === "pending") {
        return null;
    }

    return (
        <div id="sidebar-faq">
            <div className="title-container">
                <h3>Page FAQ</h3>
            </div>
            <div className="faq-accordion">
                {sidebarFAQ && sidebarFAQ.length > 0 ? (
                    sidebarFAQ.map((item, index) => (
                        <div key={index} className="faq-accordion-item">
                            <button onClick={() => toggleAccordion(index)}>
                                {item.title}
                                <span
                                    className={`arrow ${
                                        openIndex === index ? "open" : ""
                                    }`}
                                >
                                    <Chevron height={24} width={24} />
                                </span>
                            </button>
                            {openIndex === index && <p>{item.answer}</p>}
                        </div>
                    ))
                ) : (
                    <p className="no-faq">No FAQ found</p>
                )}
            </div>
        </div>
    );
};

export default SidebarFAQ;
