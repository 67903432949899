import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Alert from "@/common/components/Alert";
import FormField from "@/common/components/form/fields/FormField";
import { selectTwoFactorMethod } from "../../features/twoFactorSlice";
import "./TwoFactorMethod.scss";

const validationSchema = Yup.object({
    select: Yup.string().required("Required").oneOf(["authenticator", "sms"]),
});

const TwoFactorMethod = () => {
    const dispatch = useDispatch();

    const { status, error } = useSelector((state) => state.twoFactor);

    const [isSelected, setIsSelected] = useState(false);

    const handleChange = (event, setFieldValue) => {
        const value = event.currentTarget.value;
        setIsSelected(value ? true : false);
        setFieldValue("select", value);
    };
    return (
        <div id="verification-type">
            <div className="page-title-container">
                <h3>2 Step Verification</h3>
            </div>
            {error && <Alert type="error" message={error} />}
            <div className="instruction-container">
                <p>Select your preferred method.</p>
            </div>
            <Formik
                initialValues={{
                    select: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    return new Promise((resolve, _) => {
                        dispatch(selectTwoFactorMethod(values.select)).then(
                            (res) => {
                                if (!res.error) {
                                    location.href = "/users/twofactor/input/";
                                } else {
                                    setSubmitting(false);
                                    resolve();
                                }
                            }
                        );
                    });
                }}
            >
                {({ setFieldValue, isSubmitting }) => (
                    <Form id="verificationTypeForm">
                        <FormField
                            name="select"
                            type="select"
                            onChange={(e) => handleChange(e, setFieldValue)}
                        >
                            <option value="">Select</option>
                            <option value="authenticator">
                                Authenticator App
                            </option>
                            <option value="sms">SMS Verification</option>
                        </FormField>
                        <div className="submit-btn-container">
                            <button
                                type="submit"
                                className="btn primary"
                                disabled={
                                    !isSelected ||
                                    isSubmitting ||
                                    status == "pending"
                                }
                            >
                                {isSubmitting || status == "pending" ? (
                                    <div className="loader"></div>
                                ) : (
                                    "Next"
                                )}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default TwoFactorMethod;
