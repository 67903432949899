import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cachedAxiosInstance } from "@/common/utils/axios";

export const getCheckoutInfo = createAsyncThunk(
    "checkout/getInfo",
    async (_, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.get(
                "/products/api/checkout/"
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData || "Failed to get page data."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const checkout = createAsyncThunk(
    "checkout/checkout",
    async (values, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.post(
                "/products/api/checkout/",
                values,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData || "Failed to process checkout."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const getOrder = createAsyncThunk(
    "checkout/getOrder",
    async (sessionId, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.get(
                `/products/api/order_details/stripe/${sessionId}/`
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to get order data."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const checkoutSlice = createSlice({
    name: "checkout",
    initialState: {
        loading: true,
        orderItems: null,
        totalAmount: 0,
        error: null,
        redirectUrl: null,
        order: null,
    },
    reducers: {
        updateTotalAmount: (state, action) => {
            state.totalAmount = action.payload;
          },
          clearRedirectUrl: (state) => {
            state.redirectUrl = null;
          }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCheckoutInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCheckoutInfo.fulfilled, (state, action) => {
                state.orderItems = action.payload.order_items;
                state.totalAmount = action.payload.total_amount;
                state.error = null;
                state.loading = false;
            })
            .addCase(getCheckoutInfo.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(checkout.pending, (state) => {
                state.loading = true;
            })
            .addCase(checkout.fulfilled, (state, action) => {
                state.redirectUrl = action.payload.redirect_url;
                state.error = null;
                state.loading = false;
            })
            .addCase(checkout.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(getOrder.pending, (state) => {
                state.loading = true;
            })
            .addCase(getOrder.fulfilled, (state, action) => {
                state.order = action.payload;
                state.error = null;
                state.loading = false;
            })
            .addCase(getOrder.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    },
});

export const { updateTotalAmount, clearRedirectUrl } = checkoutSlice.actions;

export default checkoutSlice.reducer;
