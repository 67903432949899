import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Alert from "@/common/components/Alert";
import {
    getAppointmentSummary,
    submitAppointment,
    resetSubmission,
} from "../../features/ApptSummarySlice";
import UserIcon from "../../images/icons/user_icon.svg";
import CalendarIcon from "../../images/icons/calendar.svg";

import "./ApptSummary.scss";


const ApptSummary = () => {
    const dispatch = useDispatch();

    const { summary, status, error, submission } = useSelector(
        (state) => state.appointmentSummary
    );

    const isLoading = useMemo(
        () => status === "pending" || status === "idle",
        [status]
    );

    useEffect(() => {
        dispatch(getAppointmentSummary());
    }, []);

    const HandleSubmitAppointment = () => {
        dispatch(resetSubmission());
        return new Promise((resolve, _) => {
            dispatch(submitAppointment()).then((res) => {
                if (!res.error) {
                    // TODO: use react router navigate once Payment routes added
                    window.location.replace(res.payload.redirect_url);
                } else {
                    resolve();
                }
            });
        });
    };

    return (
        <div id="apptSummary">
            <div className="page-container">
                {status === "failed" ? (
                    <Alert message={error} type={"error"} />
                ) : (
                    <>
                        <div className="page-title-container">
                            <h4>Appointment Summary</h4>
                        </div>
                        {submission.status === "failed" && (
                            <Alert message={submission.error} type={"error"} />
                        )}

                        <div className="summary-container">
                            {isLoading ? (
                                <div className="loading-spinner-container">
                                    <div className="loader"></div>
                                </div>
                            ) : (
                                <>
                                    <div className="summary-top">
                                        <div className="patient-wrapper">
                                            <UserIcon
                                                width={17}
                                                height={17}
                                                color={"#585A5B"}
                                            />

                                            <p>
                                                {summary.patient.first_name}{" "}
                                                {summary.patient.last_name}
                                            </p>
                                        </div>
                                        <div className="summary-date-time">
                                            <CalendarIcon
                                                width={17}
                                                height={19}
                                                color={"#585A5B"}
                                            />

                                            <div className="date-time-wrapper">
                                                <p>{summary.formatted_time}</p>

                                                <p>{summary.formatted_date}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="summary-bottom">
                                        <p>
                                            {summary.doctor.first_name}{" "}
                                            {summary.doctor.last_name}
                                        </p>
                                        {summary.doctor.clinic ? (
                                            <>
                                                <p>
                                                    {summary.doctor.clinic.name}
                                                </p>
                                                <p>
                                                    {
                                                        summary.doctor.clinic
                                                            .address
                                                    }
                                                    ,{" "}
                                                    {summary.doctor.clinic.city}
                                                    ,{" "}
                                                    {summary.doctor.clinic.state.toUpperCase()}{" "}
                                                    {
                                                        summary.doctor.clinic
                                                            .zip_code
                                                    }
                                                    ,{" "}
                                                    {summary.doctor.clinic.country.toUpperCase()}
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <p>Independent</p>
                                                <p>
                                                    {summary.doctor.address},{" "}
                                                    {summary.doctor.city},{" "}
                                                    {summary.doctor.state.toUpperCase()}{" "}
                                                    {summary.doctor.zip_code},{" "}
                                                    {summary.doctor.country.toUpperCase()}
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                        {!isLoading &&
                            (summary.is_summary ? (
                                <div className="page-btn-container">
                                    <button
                                        type="button"
                                        className="btn primary"
                                        onClick={HandleSubmitAppointment}
                                        disabled={
                                            submission.status === "pending"
                                        }
                                    >
                                        {submission.status === "pending" ? (
                                            <div className="loader"></div>
                                        ) : (
                                            "Confirm Appointment"
                                        )}
                                    </button>
                                </div>
                            ) : (
                                summary.type === "telehealth" &&
                                (summary.is_meeting_started ? (
                                    <>
                                        <div className="instruction-container">
                                            <h6>
                                                The Meeting has started. Click
                                                the button below to join.
                                            </h6>
                                        </div>
                                        <div className="page-btn-container">
                                            {/* TODO: use react router navigate once all routes added  */}
                                            <a
                                                href={`/appointments/telehealth/${summary.appt_id}`}
                                                className="btn primary"
                                            >
                                                Join Meeting
                                            </a>
                                            <a
                                                href={`/audio-video-test/`}
                                                className="btn primary"
                                            >
                                                Test Audio Video
                                            </a>
                                        </div>
                                        
                                    </>
                                ) : (
                                    <>
                                        <div className="instruction-container">
                                            <h6>
                                                Click the button below to join
                                                the meeting once it starts.
                                            </h6>
                                        </div>
                                        <div className="page-btn-container">
                                            <button
                                                type="button"
                                                className="btn primary"
                                                disabled={true}
                                            >
                                                Join Meeting
                                            </button>
                                        </div>
                                    </>
                                ))
                            ))}
                    </>
                )}
            </div>
        </div>
    );
};

export default ApptSummary;
