import React from 'react';
import { Paper } from '@mui/material';

const Step = ({ imageSrc, description, number }) => {
    return (
        <Paper className="carousel-item">
            <img src={imageSrc} alt={`Step ${number} Image`}className="carousel-image" />
            <div className="carousel-description">
                <p>{description}</p>
            </div>
        </Paper>
    );
};

export default Step