import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { cachedAxiosInstance } from "@/common/utils/axios";

const initialMethodState = {
    name: null,
    status: "idle",
    error: null,
};

export const getRefundTickets = createAsyncThunk(
    "products/refunds/tickets",
    async (
        { status = "approved", page_size = null, page = null },
        thunkAPI
    ) => {
        try {
            const queryParams = new URLSearchParams();
            if (page_size) queryParams.append("page_size", page_size);
            if (page) queryParams.append("page", page);

            // TODO: add filter by status & enable this status filter
            // if (status) queryParams.append("status", status);
            let url =
                !page_size && !page
                    ? `/products/api/refunds/tickets/`
                    : `/products/api/refunds/tickets/?${queryParams.toString()}`;

            const response = await cachedAxiosInstance.get(url);
            const data = await response.data;

            return data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to get refund tickets."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const createRefund = createAsyncThunk(
    "products/refunds/create",
    async (ticketId, thunkAPI) => {
        try {
            await cachedAxiosInstance.post(
                "/products/api/refunds/",
                {
                    ticket: ticketId,
                },
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            return ticketId;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to refund product amount."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const refundTicketsSlice = createSlice({
    name: "refund_tickets",
    initialState: {
        tickets: [],
        status: "idle",
        error: null,
        method: initialMethodState,
        pagination: null,
    },
    reducers: {
        // reset method state obj to it's initial value
        resetMethod: (state, _) => {
            state.method = initialMethodState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRefundTickets.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getRefundTickets.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.tickets = action.payload.refund_tickets;
                state.pagination = action.payload;
                state.error = null;
            })
            .addCase(getRefundTickets.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(createRefund.pending, (state) => {
                state.method.name = "refund";
                state.method.status = "pending";
            })
            .addCase(createRefund.fulfilled, (state, action) => {
                state.method.status = "succeeded";
                state.tickets = state.tickets.filter(
                    (obj) => obj.id !== action.payload
                );
                state.method.error = null;
            })
            .addCase(createRefund.rejected, (state, action) => {
                state.method.status = "failed";
                state.method.error = action.payload;
            });
    },
});

export const { resetMethod } = refundTicketsSlice.actions;
export default refundTicketsSlice.reducer;
