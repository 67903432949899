import React from "react";
import {
    CircularProgressbarWithChildren,
    buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import ProgressProvider from "./ProgressProvider";
import "./ProgressCircle.scss";

const ProgressCircle = ({ valueStart, valueEnd }) => {
    return (
        <div id="progressCircle">
            <ProgressProvider valueStart={valueStart} valueEnd={valueEnd}>
                {(value) => (
                    <CircularProgressbarWithChildren
                        value={value}
                        strokeWidth={10}
                        styles={buildStyles({
                            strokeLinecap: "butt",
                            pathColor: "#3c6ca4",
                            trailColor: "#d3e6fc",

                            pathTransitionDuration: 1.5,
                        })}
                    >
                        <div className="progress-inner">
                            <span className="progress-text">Process</span>
                            <span className="progress-percentage">
                                {value}%
                            </span>
                            <span className="progress-text">Complete</span>
                        </div>
                    </CircularProgressbarWithChildren>
                )}
            </ProgressProvider>
        </div>
    );
};

export default ProgressCircle;
