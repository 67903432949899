import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getROI, submitROI } from "../features/releaseOfInformationSlice";
import { useNavigate } from "react-router-dom";
import "./ReleaseOfInformation.scss";

const ReleaseOfInformation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { content, loading } = useSelector((state) => state.roi);

    useEffect(() => {
        const fetchData = () => {
            dispatch(getROI());
        };

        fetchData();
    }, [dispatch, navigate]);

    const handleSubmit = (action) => {
        const submitData = async () => {
            const response = await dispatch(submitROI(action));

            if (response.payload?.redirect_url) {
                window.location.replace(response.payload.redirect_url)
            }
        };

        submitData();
    };

    if (loading) {
        return (
            <div id="releaseOfInformation">
                <div className="page-container">
                    <div className="page-middle">
                        <div className="page-content">
                            <div className="loading-spinner-container">
                                <div
                                    style={{
                                        height: "80px",
                                        width: "80px",
                                        borderWidth: "8px",
                                    }}
                                    className="loader"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div id="releaseOfInformation">
            <div className="page-container">
                <div className="page-middle">
                    <div className="page-content">
                        <div className="content-wrapper">
                            <div className="article-content">
                                <div className="instruction-container">
                                    <p>
                                        Please review our Release of
                                        Information:
                                    </p>
                                </div>
                                <div className="page-title-container">
                                    <h3 className="page-title">
                                        Release of Information
                                    </h3>
                                </div>
                                <p style={{ textAlign: "left" }}>{content}</p>
                            </div>
                            <div className="page-btn-container">
                                <button
                                    type="button"
                                    className="btn primary"
                                    onClick={() => {
                                        handleSubmit("Agree");
                                    }}
                                >
                                    I Agree
                                </button>
                                <button
                                    type="button"
                                    className="btn primary outline"
                                    onClick={() => {
                                        handleSubmit("Decline");
                                    }}
                                >
                                    Decline
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReleaseOfInformation;
