import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConfirmCheckModal from "../components/ConfirmCheckModal";
import Alert from "../../common/components/Alert";
import { formatDate } from "../utils";
import { getUnpaidOrders, PAYMENT_TYPE } from "../features/ordersSlice";
import { toggleShow } from "../features/modalSlice";
import ProductListModal from "../components/ProductListModal";
import "../common/styles/table_container.scss";
import "./check_payments.scss";
import "./medical_history.scss";
import Pagination from "../components/Pagination/Pagination";

const CheckPayments = () => {
    const user = useSelector((state) => state.user.user);
    const { orders, status, error, method, pagination } = useSelector(
        (state) => state.orders
    );
    const modalData = useSelector((state) => state.modal.data);
    const [modalName, setModalName] = useState(null);

    const dispatch = useDispatch();
    const [orderDetails, setOrderDetails] = useState(null);

    useEffect(() => {
        dispatch(getUnpaidOrders({ paymentType: PAYMENT_TYPE.Check }));
    }, []);

    const getOrderDetails = (orderItems, total) => {
        setOrderDetails({ order_items: orderItems, total });
        dispatch(toggleShow());
    };

    const getOrderTotal = (orderItems) => {
        var total = 0;
        for (let index = 0; index < orderItems.length; index++) {
            const element = orderItems[index];
            total += element.price;
        }
        return total;
    };

    const openModal = (modalName, id) => {
        setModalName(modalName);
        dispatch(toggleShow({ id: id }));
    };

    if (status === "failed") {
        return <Alert message={error} type={"error"} />;
    } else {
        return (
            <div id="orderHistory">
                <div className="page-title-container">
                    <h3>Unpaid Checks</h3>
                </div>
                {method.status === "succeeded" && (
                    <Alert
                        message={`Order marked as complete`}
                        type={"success"}
                    />
                )}
                <div className="card table-container">
                    <div className="table-wrapper scrollable">
                        {status === "succeeded" ? (
                            orders.length ? (
                                <table>
                                    <thead>
                                        <tr className="table-header">
                                            <th>ID</th>
                                            <th>Email</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Details</th>
                                            <th>Total</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders.map((order) => {
                                            const {
                                                id,
                                                timestamp,
                                                status,
                                                order_items,
                                                amount_paid,
                                                patient,
                                            } = order;
                                            return (
                                                <tr key={id}>
                                                    <td className="text-el">
                                                        {id}
                                                    </td>
                                                    <td className="text-el">
                                                        {patient}
                                                    </td>
                                                    <td className="text-el">
                                                        {formatDate(timestamp)}
                                                    </td>
                                                    <td>
                                                        <div className="btn-wrapper">
                                                            <div className="btn outline">
                                                                {
                                                                    status.display_text
                                                                }
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="btn-wrapper">
                                                            <button
                                                                type="button"
                                                                className="btn primary"
                                                                onClick={() =>
                                                                    getOrderDetails(
                                                                        order_items,
                                                                        getOrderTotal(
                                                                            order_items
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                View Details
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td className="text-el">
                                                        $
                                                        {parseFloat(
                                                            getOrderTotal(
                                                                order_items
                                                            )
                                                        ).toFixed(2)}
                                                    </td>
                                                    <td>
                                                        <div className="btn-wrapper">
                                                            <button
                                                                type="button"
                                                                className="btn primary"
                                                                onClick={() =>
                                                                    openModal(
                                                                        "confirm",
                                                                        id
                                                                    )
                                                                }
                                                            >
                                                                Payment Received
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="empty-list-container">
                                    <h6>No pending unpaid checks</h6>
                                </div>
                            )
                        ) : (
                            <div className="loading-spinner-container">
                                <div
                                    style={{
                                        height: "80px",
                                        width: "80px",
                                        borderWidth: "8px",
                                    }}
                                    className="loader"
                                ></div>
                            </div>
                        )}
                    </div>

                    {orders.length > 0 &&
                        pagination?.current_page &&
                        pagination?.total_pages &&
                        pagination?.page_size && (
                            <Pagination
                                currentPage={pagination?.current_page ?? 1}
                                total_pages={pagination?.total_pages ?? 1}
                                itemsPerPage={pagination?.page_size ?? 5}
                                // change current page
                                onPageChange={(page) => {
                                    dispatch(
                                        getUnpaidOrders({
                                            page: page,
                                            page_size:
                                                pagination?.page_size ?? 5,
                                            paymentType: PAYMENT_TYPE.Check,
                                        })
                                    );
                                }}
                                // change page size
                                onItemsPerPageChange={(value) => {
                                    dispatch(
                                        getUnpaidOrders({
                                            page: 1,
                                            page_size: value,
                                            paymentType: PAYMENT_TYPE.Check,
                                        })
                                    );
                                }}
                            />
                        )}
                </div>
                <ProductListModal
                    orderDetails={orderDetails}
                    setOrderDetails={setOrderDetails}
                />
                {modalData ? (
                    modalName === "confirm" ? (
                        <ConfirmCheckModal data={modalData} />
                    ) : (
                        <DeleteMedHistoryModal
                            title={"Delete Document"}
                            data={modalData}
                        />
                    )
                ) : null}
            </div>
        );
    }
};

export default CheckPayments;
