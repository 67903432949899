import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Alert from "../../common/components/Alert";
import { formatDate } from "../utils";
import { getPatientTranscripts } from "../features/transcriptsSlice";
import { getTranscriptDetail } from "../features/transcriptDetailSlice";
import { generatePDF } from "../utils/transcript";
import "../common/styles/table_container.scss";
import "./transcripts.scss";

const Transcripts = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const { transcripts, status, error } = useSelector(
        (state) => state.transcripts
    );
    const {
        transcript, // this can be used in a PDF viewer
        status: transcriptStatus,
        error: transcriptError,
    } = useSelector((state) => state.transcriptDetail);
    const [selectedBtn, setSelectedBtn] = useState(null);
    const [downloadStatus, setDownloadStatus] = useState(null);

    useEffect(() => {
        dispatch(getPatientTranscripts(user.id));
    }, []);

    const handleClick = (id, type = "transcript") => {
        setSelectedBtn({
            id: id,
            type: type,
        });
        // get transcript/summary data and generate a PDF file
        return new Promise((resolve, _) => {
            dispatch(getTranscriptDetail({ id, type })).then(async (res) => {
                if (!res.error) {
                    const data = res.payload;
                    const isDownloaded = await generatePDF(data, id, type);
                    setDownloadStatus(isDownloaded ? "succeeded" : "failed");
                }
                resolve();
            });
        });
    };

    if (status === "failed") {
        return <Alert message={error} type={"error"} />;
    } else {
        return (
            <div id="transcript">
                <div className="page-title-container">
                    <h3>Transcripts</h3>
                </div>
                {transcriptStatus === "failed" ? (
                    <Alert message={transcriptError} type={"error"} />
                ) : (
                    transcriptStatus === "succeeded" &&
                    downloadStatus &&
                    (downloadStatus === "succeeded" ? (
                        <Alert
                            message={
                                "PDF File has been downloaded successfully."
                            }
                            type={"success"}
                        />
                    ) : (
                        <Alert
                            message={
                                "Something went wrong while generating PDF file."
                            }
                            type={"error"}
                        />
                    ))
                )}
                <div className="card table-container">
                    <div className="table-wrapper scrollable">
                        {status === "succeeded" ? (
                            transcripts.length ? (
                                <table>
                                    <thead>
                                        <tr className="table-header">
                                            <th>Name of Event</th>
                                            <th>Date of Event</th>
                                            <th>Doctor</th>
                                            <th>Download</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transcripts.map((item) => {
                                            const {
                                                id,
                                                timestamp,
                                                doctor_name,
                                                exam,
                                            } = item;

                                            const name = `Condition Exam ${
                                                exam === 1
                                                    ? "One"
                                                    : exam === 2
                                                    ? "Two"
                                                    : "Three"
                                            }`;
                                            return (
                                                <tr key={id}>
                                                    <td className="text-el">
                                                        {name}
                                                    </td>
                                                    <td className="text-el">
                                                        {formatDate(timestamp)}
                                                    </td>
                                                    <td className="text-el">
                                                        {doctor_name}
                                                    </td>
                                                    <td>
                                                        <div className="btn-wrapper">
                                                            <button
                                                                id={id}
                                                                type="button"
                                                                className="btn primary"
                                                                onClick={() =>
                                                                    handleClick(
                                                                        id
                                                                    )
                                                                }
                                                                style={{
                                                                    marginRight:
                                                                        "1rem",
                                                                }}
                                                                disabled={
                                                                    transcriptStatus ==
                                                                    "pending"
                                                                }
                                                            >
                                                                {transcriptStatus ==
                                                                    "pending" &&
                                                                selectedBtn.id ===
                                                                    id &&
                                                                selectedBtn.type ===
                                                                    "transcript" ? (
                                                                    <div className="loader small-loader"></div>
                                                                ) : (
                                                                    "Transcript"
                                                                )}
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn primary"
                                                                onClick={() =>
                                                                    handleClick(
                                                                        id,
                                                                        "summary"
                                                                    )
                                                                }
                                                                disabled={
                                                                    transcriptStatus ==
                                                                    "pending"
                                                                }
                                                            >
                                                                {transcriptStatus ==
                                                                    "pending" &&
                                                                selectedBtn.id ===
                                                                    id &&
                                                                selectedBtn.type ===
                                                                    "summary" ? (
                                                                    <div className="loader small-loader"></div>
                                                                ) : (
                                                                    "Summary"
                                                                )}
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                <div className="empty-list-container">
                                    <h6>No Transcript Found.</h6>
                                </div>
                            )
                        ) : (
                            <div className="loading-spinner-container">
                                <div
                                    style={{
                                        height: "80px",
                                        width: "80px",
                                        borderWidth: "8px",
                                    }}
                                    className="loader"
                                ></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
};

export default Transcripts;
