import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, resetStatus } from "../../features/authSlice";
import "./Logout.scss";
import { eraseCookie } from "../../../common/utils/cookies";

const Logout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { status, error } = useSelector((state) => state.auth);

    const onLogout = () => {
        dispatch(logout());
        eraseCookie("user_tz")
        navigate("/");
        dispatch(resetStatus());
    };

    const isLoading = status === "pending";

    if (error) {
        return <Alert type="error" message={error} />;
    }

    return (
        <div id="Logout">
            <h2>Logout</h2>
            <p>Are you sure you want to Logout?</p>
            <div className="btn-container">
                <button
                    type="button"
                    className="btn outline"
                    onClick={() => navigate(-1)}
                    disabled={isLoading}
                >
                    Go Back
                </button>
                <button
                    type="button"
                    className="btn primary"
                    onClick={onLogout}
                    disabled={isLoading}
                >
                    {isLoading ? <div className="loader"></div> : "Logout"}
                </button>
            </div>
        </div>
    );
};

export default Logout;
