import React, { useRef, useEffect } from "react";
import "./ITFSkipModal.scss";

const ITFSkipModal = ({ isOpen, onClose, onDoLater, onStay }) => {
    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return (
        <div id="ITFSkipModal" className="itf-modal">
            <div className="modal-content" ref={modalRef}>
                <span className="close-btn" onClick={onClose}>
                    &times;
                </span>
                <h2 className="page-title">
                    Important Notice: Intent To File (ITF)
                </h2>
                <p className="modal-txt">
                    Filing your Intent To File (ITF) is crucial as it can
                    significantly increase your potential back pay if the VA
                    decides on an increase in your benefits. We highly recommend
                    completing your ITF as soon as possible to maximize your
                    benefits.
                </p>
                <p className="modal-txt">
                    While this step is currently optional, please be aware that
                    it will become a required step before you can proceed
                    further in the process.
                </p>
                <p className="modal-txt">
                    Do you wish to complete your Intent To File form at a later
                    time?
                </p>
                <div className="modal-btn-container">
                    <button className="btn outline" onClick={onDoLater}>
                        Yes, do it later
                    </button>
                    <button className="btn outline" onClick={onStay}>
                        No, stay on this page
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ITFSkipModal;
