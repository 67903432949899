import React from "react";
import { NavLink } from "react-router-dom";

const aboutSVG = (isActive) => (
    <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.5 20C15.7467 20 20 15.7467 20 10.5C20 5.25329 15.7467 1 10.5 1C5.25329 1 1 5.25329 1 10.5C1 15.7467 5.25329 20 10.5 20Z"
            stroke={isActive ? "#9A1F23" : "#585A5B"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.5 14.3V10.5"
            stroke={isActive ? "#9A1F23" : "#585A5B"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.5 6.7002H10.51"
            stroke={isActive ? "#9A1F23" : "#585A5B"}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const settingsSidebarItems = [
    {
        path: "account",
        label: "Account",
        icon: "profile",
        showArrow: true,
        isSetting: true,
    },
    {
        path: "security",
        label: "Privacy & Security",
        icon: "lock",
        showArrow: true,
        isSetting: true,
    },
    {
        path: "notifications",
        label: "Notification Settings",
        icon: "bell",
        showArrow: true,
        isSetting: true,
    },
    {
        path: "about",
        label: "About VetVantage",
        icon: "info",
        svg: aboutSVG,
        showArrow: true,
        isSetting: true,
    },
    {
        path: "support",
        label: "Support",
        icon: "help",
        showArrow: true,
        isSetting: true,
    },
    {
        path: "/products/refunds/tickets/",
        label: "Refunds",
        showArrow: false,
        isSetting: false,
        icon: "refund",
        svg: (isActive) => (
            <img
                src="/static/settings/img/refund.svg"
                alt="help"
                style={{ width: "23px", height: "23px" }}
            />
        ),
    },
    {
        path: "/accounts/logout/",
        label: "Logout",
        icon: "logout",
        showArrow: false,
        isSetting: false,
        svg: (isActive) => (
            <img
                src="/static/settings/img/logout.png"
                alt="help"
                style={{ width: "23px", height: "23px" }}
            />
        ),
    },
];
const SettingsSidebar = () => {
    return (
        <div className="sidebar-settings page-100">
            <div className="sidebar-wrapper">
                {settingsSidebarItems.map((item) => (
                    <div
                        key={item.path}
                        className="sidebar-settings-container sidebar-border-btn"
                    >
                        <NavLink
                            to={item.path}
                            className={({ isActive }) =>
                                `sidebar-settings-tab-btn ${
                                    isActive ? "active" : ""
                                }`
                            }
                        >
                            {({ isActive }) => (
                                <>
                                    {item.svg ? (
                                        item.svg(isActive)
                                    ) : (
                                        <img
                                            src={`/static/settings/img/${
                                                item.icon
                                            }-${isActive ? "red" : "grey"}.png`}
                                            alt={item.icon}
                                        />
                                    )}
                                    <p className={isActive ? "title-gold" : ""}>
                                        {item.label}
                                    </p>
                                    {item.showArrow && (
                                        <img
                                            src={`/static/settings/img/chevron-left-${
                                                isActive ? "red" : "grey"
                                            }.png`}
                                            alt="arrow left"
                                        />
                                    )}
                                </>
                            )}
                        </NavLink>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SettingsSidebar;
