import React, { useState, useRef, useEffect } from "react";

import "./ProgressBarAnim.scss";

const ProgressBarAnim = () => {
    const [progressValue, setProgressValue] = useState(0);
    const labelRef = useRef(null);
    const progressRef = useRef(null);

    useEffect(() => {
        const progressEl = progressRef.current;
        const labelEl = labelRef.current;
        setTimeout(() => {
            setProgressValue(100);
            const observer = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    labelEl.style.transform = `translateX(${entry.contentRect.width}px)`;
                }
            });
            observer.observe(progressEl);
            return () => observer.disconnect();
        }, 200);
        setTimeout(() => {
            labelEl.firstElementChild.innerHTML = "Background &nbsp; &#10004;";
            labelEl.firstElementChild.style.fontWeight = 600;
            labelEl.firstElementChild.style.color = "#3c6ca4";
        }, 2000);
    }, [progressValue]);
    return (
        <div id="progressBar">
            <div className="progress-label" ref={labelRef}>
                <span>Background - {progressValue}%</span>
            </div>
            <div className="progress">
                <div
                    className="progress-value"
                    ref={progressRef}
                    style={{
                        width: `${progressValue}%`,
                    }}
                ></div>
            </div>
        </div>
    );
};

export default ProgressBarAnim;
