import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";

import ChevronLeft from "@/common/images/icons/chevron-left.svg";
import ArrowRight from "../../images/icons/arrow-right.svg";
import { toggleShow } from "../../features/modalSlice";
import Modal from "../Modal";
import { stepMap } from "./StepMap";
import "./Tutorial.scss";

const Tutorial = () => {
    const dispatch = useDispatch();
    const [stepNumber, setStepNumber] = useState(0);
    const effectCalled = useRef(false);

    useEffect(() => {
        if (!effectCalled.current) {
            dispatch(toggleShow());
            effectCalled.current = true;
        }
    }, []);
    const changeStep = (value) => {
        if (
            (value >= 0 && value < stepMap.length) ||
            (value < 0 && value > stepMap.length)
        ) {
            setStepNumber(value);
        } else if (value >= stepMap.length) {
            markTutorialAsSeen();
        }
    };
    const markTutorialAsSeen = () => {
        localStorage.setItem("tutorial", 1);
        dispatch(toggleShow());
    };

    return (
        <Modal>
            <div id="tutorial">
                <div className="go-back-wrapper">
                    {stepNumber > 0 && (
                        <button
                            type="button"
                            onClick={() => changeStep(stepNumber - 1)}
                        >
                            <ChevronLeft
                                width={36}
                                height={36}
                                color={"#3c6ca4"}
                            />
                        </button>
                    )}
                </div>
                <div
                    className="container"
                    style={{ flexDirection: stepMap[stepNumber].direction }}
                >
                    <div
                        className={
                            stepMap[stepNumber].direction === "row-reverse"
                                ? "wrapper large-scr-only"
                                : "wrapper"
                        }
                    >
                        {stepMap[stepNumber].animation}
                    </div>
                    <div className="wrapper">
                        <div className="title-container">
                            <h5>{stepMap[stepNumber].title}</h5>
                        </div>
                        <div className="instruction-container">
                            <p>{stepMap[stepNumber].instruction}</p>
                        </div>
                        <div className="btn-container">
                            <button
                                type="button"
                                className="btn primary"
                                onClick={() => changeStep(stepNumber + 1)}
                            >
                                {stepMap[stepNumber].btnText}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="skip">
                <button type="button" onClick={() => markTutorialAsSeen()}>
                    <p>Skip Tutorial</p>
                    <ArrowRight width={16} height={16} />
                </button>
            </div>
        </Modal>
    );
};

export default Tutorial;
