import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { cachedAxiosInstance, axiosInstance } from "@/common/utils/axios";

export const getProgressData = createAsyncThunk(
    "progress/summary",
    async (_, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.get(
                "/api/progress-summary/"
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.error?.details ||
                        "Failed to get progress summary."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const progressSlice = createSlice({
    name: "progress",
    initialState: {
        progress: null,
        steps: null,
        status: "idle",
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProgressData.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getProgressData.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.progress = action.payload.data?.progress;
                state.steps = action.payload.data?.steps;
                state.error = null;
            })
            .addCase(getProgressData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            });
    },
});

export default progressSlice.reducer;
