import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { cachedAxiosInstance } from "@/common/utils/axios";

// Thunk to get the current user
export const getUser = createAsyncThunk("auth/me", async (_, thunkAPI) => {
    try {
        const response = await cachedAxiosInstance.get("/users/me/");
        return await response.data;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const errorData = error.response.data;
            return thunkAPI.rejectWithValue(
                errorData?.error?.details || "Failed to get user."
            );
        } else if (error.request) {
            // The request was made but no response was received
            return thunkAPI.rejectWithValue(error.message);
        } else {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
});

// User slice
export const userSlice = createSlice({
    name: "user",
    initialState: {
        user: null,
        status: "idle",
        error: null,
    },
    reducers: {
        updateUser: (state, action) => {
            if (state.user) {
                state.user = {
                    ...state.user,
                    ...action.payload,
                };
            } else {
                state.user = action.payload;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUser.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.user = action.payload.data;
                state.error = null;
            })
            .addCase(getUser.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            });
    },
});

export const { updateUser } = userSlice.actions;
export default userSlice.reducer;
