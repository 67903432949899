import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
    getRefundTickets,
    resetMethod,
    createRefund,
} from "../../features/refundTicketsSlice";
import { toggleShow } from "../../features/modalSlice";
import { formatDate } from "../../utils";
import Alert from "../../../common/components/Alert";
import ConfirmModal from "../../components/confirm_modal/ConfirmModal";
import "./RefundTickets.scss";
import Pagination from "../../components/Pagination/Pagination";

const Refunds = () => {
    const dispatch = useDispatch();

    const { tickets, status, error, method, pagination } = useSelector(
        (state) => state.refundTickets
    );

    const modalData = useSelector((state) => state.modal.data);

    const isLoading = useMemo(
        () => status == "pending" || status == "idle",
        [status]
    );

    useEffect(() => {
        dispatch(getRefundTickets({ status: "approved" }));
    }, []);

    const openModal = (id, name) => {
        dispatch(toggleShow({ id: id, name: name }));
    };
    if (status === "failed") {
        return <Alert message={error} type={"error"} />;
    } else {
        return (
            <div id="refund-tickets">
                <div className="page-title-container">
                    <h3>Refund Tickets</h3>
                    <Link
                        to="/products/refunds/history/"
                        className="refund-hist-link"
                    >
                        <h6>History</h6>
                    </Link>
                </div>
                {method.status === "succeeded" && (
                    <Alert
                        message={
                            "Your refund request has been successfully submitted. We will notify you once the process is complete."
                        }
                        type={"success"}
                    />
                )}
                <div className="card table-container">
                    <div className="table-wrapper scrollable">
                        {isLoading ? (
                            <div className="loading-spinner-container">
                                <div
                                    style={{
                                        height: "80px",
                                        width: "80px",
                                        borderWidth: "8px",
                                    }}
                                    className="loader"
                                ></div>
                            </div>
                        ) : tickets.length ? (
                            <>
                                {/* TODO: Add create refund ticket logic if needed, otherwise remove this div */}
                                <div className="create-refund-container">
                                    <button
                                        type="button"
                                        className="btn primary"
                                        onClick={() =>
                                            alert(
                                                "TODO: Create 'New Refund Ticket' modal."
                                            )
                                        }
                                    >
                                        New Ticket
                                    </button>
                                </div>
                                <table>
                                    <thead>
                                        <tr className="table-header">
                                            <th>ID</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Product</th>
                                            <th>Amount</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tickets.map(
                                            ({
                                                id,
                                                order_item,
                                                amount,
                                                status,
                                                timestamp,
                                            }) => {
                                                return (
                                                    <tr key={id}>
                                                        <td className="text-el">
                                                            {id}
                                                        </td>
                                                        <td className="text-el">
                                                            {formatDate(
                                                                timestamp
                                                            )}
                                                        </td>
                                                        <td>
                                                            <div className="btn-wrapper">
                                                                <div className="btn outline">
                                                                    {
                                                                        status.display_text
                                                                    }
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="text-el">
                                                            <div>
                                                                {
                                                                    order_item
                                                                        .product
                                                                        .name
                                                                }
                                                            </div>
                                                        </td>
                                                        <td className="text-el">
                                                            $
                                                            {parseFloat(
                                                                amount
                                                            ).toFixed(2)}
                                                        </td>
                                                        <td>
                                                            <div className="btn-wrapper">
                                                                {status.value ===
                                                                    1 && (
                                                                    <button
                                                                        type="button"
                                                                        className="btn primary"
                                                                        onClick={() =>
                                                                            openModal(
                                                                                id,
                                                                                order_item
                                                                                    .product
                                                                                    .name
                                                                            )
                                                                        }
                                                                    >
                                                                        Refund
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </>
                        ) : (
                            <div className="empty-list-container">
                                <h6>You do not have any Refund Ticket yet.</h6>
                            </div>
                        )}
                    </div>

                    {pagination?.refund_tickets?.length > 0 &&
                        pagination?.current_page &&
                        pagination?.total_pages &&
                        pagination?.page_size && (
                            <Pagination
                                currentPage={pagination?.current_page ?? 1}
                                total_pages={pagination?.total_pages ?? 1}
                                itemsPerPage={pagination?.page_size ?? 10}
                                // change current page
                                onPageChange={(page) => {
                                    dispatch(
                                        getRefundTickets({
                                            status: "approved",
                                            page: page,
                                            page_size:
                                                pagination?.page_size ?? 10,
                                        })
                                    );
                                }}
                                // change page size
                                onItemsPerPageChange={(value) => {
                                    dispatch(
                                        getRefundTickets({
                                            status: "approved",
                                            page_size: value,
                                            page: 1, // reset page number
                                        })
                                    );
                                }}
                            />
                        )}
                </div>

                {modalData ? (
                    <ConfirmModal
                        slice="refundTickets"
                        title={"Refund Product Amount"}
                        text={`Are you sure you want to refund ${modalData.name} amount?`}
                        data={modalData}
                        clickHandler={createRefund}
                        resetMethod={resetMethod}
                        action={"Refund"}
                    />
                ) : null}
            </div>
        );
    }
};

export default Refunds;
