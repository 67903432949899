import React from "react";
import { Link } from "react-router-dom";

const CancelApptSuccess = () => {
    return (
        <>
            <div className="page-title-container">
                <h4>Your Appointment has been canceled.</h4>
            </div>
            <div className="instruction-container">
                <p>
                    You can find a different doctor in our network or go back to
                    the Dashboard.
                </p>
            </div>
            <div className="page-btns-container">
                <Link to={"/dashboard/"} className="btn primary">
                    Go to Dashboard
                </Link>
                <Link
                    to={"/appointments/select-doctor/"}
                    className="btn primary"
                >
                    Find an Evaluator
                </Link>
            </div>
        </>
    );
};

export default CancelApptSuccess;
