import React, { useState } from "react";

const CalcPay = ({ orderItems, handleCalculateClick }) => {
    const [selectedItems, setSelectedItems] = useState([]);

    const handleItemSelect = (item) => {
        const isAlreadySelected = selectedItems.some(i => i.id === item.id);

        if (isAlreadySelected) {
            // Remove the item from the list if it's already selected
            setSelectedItems(selectedItems.filter(i => i.id !== item.id));
        } else {
            // Add the item to the list if it's not selected
            setSelectedItems([...selectedItems, item]);
        }
    };

    const isSelected = (item) => selectedItems.some(selectedItem => selectedItem.id === item.id);

    return (
            <div className="content-container">
                <div className="page-title-container">
                    <h3 className="page-title">Almost There!</h3>
                </div>
                <div className="cart-subtitle-container">
                    <h5 style={{ maxWidth: "418px" }}>
                        Select Products To Purchase:
                    </h5>
                </div>

                <div className={`added-cond-container ${orderItems.length > 1 ? "cond-grid" : "cond-flex"}`}>
                    {orderItems.map((item, index) => (
                        <button
                            key={index}
                            type="button"
                            name="product"
                            className={`added-cond-item ${isSelected(item) ? "selected" : ""}`}
                            title={item.product.name}
                            onClick={() => handleItemSelect(item)}
                        >
                            <div className="text-ellipsis">{item.product.name}</div>
                        </button>
                    ))}
                </div>

                <div className="cart-info-container">
                    <span style={{ maxWidth: "517px" }}>
                        We’ll calculate your payment based on your selection. You can select one or multiple products for purchase.
                    </span>
                </div>

                <div className="btn-container">
                    <button
                        type="button"
                        className="btn primary"
                        onClick={() => handleCalculateClick(selectedItems)}
                        disabled={selectedItems.length === 0}
                    >
                        Calculate Payment
                    </button>
                </div>
            </div>
    );
};

export default CalcPay;
