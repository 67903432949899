import React from "react";
import "./alert.scss";

const Alert = ({ message, type = "info" }) => {
    return (
        <div id="alertContainer">
            <div className={`alert alert-${type}`}>
                <p>{message}</p>
            </div>
        </div>
    );
};

export default Alert;
