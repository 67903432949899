import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NotificationBase from "./NotificationBase";
import Notification from "./Notification";
import {
    fetchNotifications,
    markNotificationViewed,
} from "../../features/notificationsSlice";
import Pagination from "../../components/Pagination/Pagination";

const ArchivedNotifications = () => {
    const dispatch = useDispatch();
    const { archivedNotifications, loading, pagination } = useSelector(
        (state) => state.notifications
    );

    useEffect(() => {
        dispatch(fetchNotifications({ type: "archived" }));
    }, [dispatch]);

    const handleViewNotification = (notificationId, isPast, notification) => {
        if (!isPast && !notification.archived) {
            dispatch(markNotificationViewed(notificationId));
        }
    };

    if (loading) {
        return (
            <div className="loading-spinner-container">
                <div
                    style={{
                        height: "80px",
                        width: "80px",
                        borderWidth: "8px",
                    }}
                    className="loader"
                ></div>
            </div>
        );
    }

    return (
        <NotificationBase isArchived={true}>
            <div className="notification-title-container">
                <h1>Archived Notifications</h1>
            </div>

            {archivedNotifications.length ? (
                archivedNotifications.map((notification) => (
                    <Notification
                        key={notification.id}
                        notification={notification}
                        isArchived
                        viewNotification={handleViewNotification}
                    />
                ))
            ) : (
                <div className="no-notifications">
                    No Archived Notifications!
                </div>
            )}

            {archivedNotifications.length > 0 &&
                pagination?.current_page &&
                pagination?.total_pages &&
                pagination?.page_size && (
                    <div className="pagination-container">
                        <Pagination
                            currentPage={pagination?.current_page ?? 1}
                            total_pages={pagination?.total_pages ?? 1}
                            itemsPerPage={pagination?.page_size ?? 5}
                            // change current page
                            onPageChange={(page) => {
                                dispatch(
                                    fetchNotifications({
                                        page: page,
                                        page_size: pagination?.page_size ?? 5,
                                        type: "archived",
                                    })
                                );
                            }}
                            // change page size
                            onItemsPerPageChange={(value) => {
                                dispatch(
                                    fetchNotifications({
                                        page_size: value,
                                        type: "archived",
                                        page: 1, // reset page number
                                    })
                                );
                            }}
                        />
                    </div>
                )}
        </NotificationBase>
    );
};

export default ArchivedNotifications;
