import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

const DropdownButton = ({ buttonElement, dropdownContent }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        // Close dropdown when route changes
        setIsOpen(false);
    }, [location]);

    useEffect(() => {
        // Only listen for 'mousedown' event when the dropdown is open
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    const handleClickOutside = (event) => {
        // Close dropdown after clicking outside the dropdown, ignore button clicks
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            buttonRef.current &&
            !buttonRef.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="dropdown">
            {React.isValidElement(buttonElement) &&
                React.cloneElement(buttonElement, {
                    onClick: toggleDropdown,
                    ref: buttonRef,
                    className: `${buttonElement?.props?.className} ${
                        isOpen ? "open" : ""
                    }`,
                })}
            {isOpen && (
                <div ref={dropdownRef} className="dropdown-content">
                    {dropdownContent}
                </div>
            )}
        </div>
    );
};

export default DropdownButton;
