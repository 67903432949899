import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";

import Alert from "@/common/components/Alert";
import {
    checkApptExistence,
    cancelAppointment,
    resetSubmission,
} from "../../features/appointmentSlice";
import CancelApptSuccess from "../../components/cancel_appt_success/CancelApptSuccess";
import { context } from "./context.js";
import "./CancelAppt.scss";

const CancelAppt = () => {
    const dispatch = useDispatch();
    const { appointment, status, error, submission } = useSelector(
        (state) => state.appointment
    );
    const isLoading = useMemo(
        () => status == "pending" || status == "idle",
        [status]
    );
    const [searchParams] = useSearchParams();
    // show different page context based on change_doctor url param existence
    const pageContext =
        searchParams.get("change_doctor") == "true"
            ? context.changeDoctor
            : context.cancelAppt;

    useEffect(() => {
        dispatch(checkApptExistence());
    }, []);

    const handleCancelAppt = () => {
        dispatch(resetSubmission());
        dispatch(cancelAppointment({ id: appointment.id }));
    };

    if (status === "failed" && submission.status === "idle") {
        return <Alert message={error} type={"error"} />;
    } else {
        return (
            <div id="CancelAppt">
                {isLoading ? (
                    <div className="loading-spinner-container">
                        <div className="loader"></div>
                    </div>
                ) : (
                    <div className="page-container">
                        {submission.status === "succeeded" ? (
                            <CancelApptSuccess />
                        ) : (
                            <>
                                {submission.status === "failed" && (
                                    <Alert
                                        message={submission.error}
                                        type={"error"}
                                    />
                                )}
                                <div className="page-title-container">
                                    <h4>{pageContext.title}</h4>
                                </div>
                                <div className="instruction-container">
                                    <p>{pageContext.instruction}</p>
                                </div>
                                <div className="page-btns-container">
                                    <button
                                        type="button"
                                        className="btn outline"
                                        onClick={handleCancelAppt}
                                        disabled={
                                            submission.status === "pending"
                                        }
                                    >
                                        {submission.status === "pending" ? (
                                            <div className="loader"></div>
                                        ) : (
                                            pageContext.yesButtonText
                                        )}
                                    </button>
                                    <Link
                                        to={"/dashboard/"}
                                        className="btn primary"
                                    >
                                        {pageContext.noButtonText}
                                    </Link>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        );
    }
};

export default CancelAppt;
