import React from "react";

function Spinner({ width = "80px", height = "80px", borderWidth = "8px" }) {
    return (
        <div className="loading-spinner-container">
            <div
                style={{
                    height: height,
                    width: width,
                    borderWidth: borderWidth,
                }}
                className="loader"
            ></div>
        </div>
    );
}

export default Spinner;
