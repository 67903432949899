import React, { useEffect, useState } from "react";
import MainUnlock from "../../components/MainUnlock";
import { useDispatch, useSelector } from "react-redux";
import { checkItfExistence } from "../../features/itf/itfSlice";
import ITFSkipModal from "./ITFSkipModal";

function ITFUnlocked() {
    const dispatch = useDispatch();
    const { isOptional, status } = useSelector((state) => state.itf);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleDoLater = () => {
        // TODO: use react router navigate once all routes added
        window.location.href = "/condition_exam/";
    };

    useEffect(() => {
        dispatch(checkItfExistence());
    }, [dispatch]);

    return (
        <>
            <MainUnlock
                is_optional={status === "loading" ? true : isOptional}
                step_title={"Intent To File (ITF)"}
                step_btn_text={"Start ITF"}
                message={
                    "An intent to file with the U.S. Department of Veterans Affairs (VA) is a way for veterans and survivors to start the filing process for benefits while they still gather information to support their claim. The intent to file form, VA Form 21-0966, establishes a potential start date, or effective date, for benefits. This date is the day the VA determines that service-connected benefits begin. If the claim is approved, the intent to file can help secure the earliest possible effective date for any retroactive payments that may be due."
                }
                btn_url={"/itf/"}
                onDoLater={() => setIsModalOpen(true)}
                btnSize="medium"
            />
            <ITFSkipModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onDoLater={handleDoLater}
                onStay={() => setIsModalOpen(false)}
            />
        </>
    );
}

export default ITFUnlocked;
