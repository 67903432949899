import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateUser } from "./userSlice";

const API_BASE_URL = "/settings/api/manage";

// Helper function for error handling
export const ERROR_MESSAGE =
    "An unexpected error occurred. Please try again later.";
const handleError = (error, customErrorMsg = ERROR_MESSAGE) => {
    if (error.response) {
        const errorData = error?.response?.data || {};
        return (
            errorData?.detail ||
            errorData?.message ||
            errorData?.error ||
            customErrorMsg
        );
    } else if (error.request) {
        return error?.message || customErrorMsg;
    } else {
        return error?.message || customErrorMsg;
    }
};

export const getUserData = createAsyncThunk(
    "settings/getUserData",
    async (_, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await axiosInstance.get(
                `${API_BASE_URL}/account/`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to fetch user data.")
            );
        }
    }
);

export const updateUserData = createAsyncThunk(
    "settings/updateUserData",
    async (data, { dispatch, rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await axiosInstance.post(
                `${API_BASE_URL}/account/`,
                data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            // Update user data in Redux store after setting account info is updated to reflect changes in navbar dropdown
            const user = response.data;
            dispatch(
                updateUser({
                    email: user.email,
                    full_name: `${user.first_name} ${user.last_name}`,
                    profile_pic_url: user.image,
                })
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to update user data.")
            );
        }
    }
);

export const getSecuritySettings = createAsyncThunk(
    "settings/getSecuritySettings",
    async (_, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await axiosInstance.get(
                `${API_BASE_URL}/security/`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to fetch security settings.")
            );
        }
    }
);

export const updateSecuritySettings = createAsyncThunk(
    "settings/updateSecuritySettings",
    async (data, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await axiosInstance.post(
                `${API_BASE_URL}/security/`,
                data
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to update security settings.")
            );
        }
    }
);

export const getNotificationSettings = createAsyncThunk(
    "settings/getNotificationSettings",
    async (_, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await axiosInstance.get(
                `${API_BASE_URL}/notifications/`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to fetch notification settings.")
            );
        }
    }
);

export const updateNotificationSettings = createAsyncThunk(
    "settings/updateNotificationSettings",
    async (data, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await axiosInstance.post(
                `${API_BASE_URL}/notifications/`,
                data
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to update notification settings.")
            );
        }
    }
);

export const contactSupport = createAsyncThunk(
    "settings/contactSupport",
    async (data, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await axiosInstance.post(
                `/settings/api/support/contact/`,
                data
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to contact support.")
            );
        }
    }
);
export const deleteInformationRequest = createAsyncThunk(
    "settings/deleteInformationRequest",
    async (data, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await axiosInstance.post(
                `/settings/api/delete-information/`,
                data
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to request deletion.")
            );
        }
    }
);

export const getHelpPortal = createAsyncThunk(
    "settings/getHelpPortal",
    async (_, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await axiosInstance.get(
                `${API_BASE_URL}/support/`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to fetch help portal.")
            );
        }
    }
);

const settingsSlice = createSlice({
    name: "settings",
    initialState: {
        userData: null,
        status: "idle",
        error: null,
        securitySettings: null,
        notificationSettings: null,
        helpPortal: null,
    },
    reducers: {
        resetStatus: (state) => {
            state.status = "idle";
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getUserData.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.userData = action.payload;
            })
            .addCase(getUserData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(updateUserData.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateUserData.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.userData = action.payload;
            })
            .addCase(updateUserData.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(getSecuritySettings.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getSecuritySettings.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.securitySettings = action.payload;
            })
            .addCase(getSecuritySettings.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(updateSecuritySettings.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateSecuritySettings.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.securitySettings = action.payload;
            })
            .addCase(updateSecuritySettings.rejected, (state, action) => {
                state.status = "failed";
            })

            .addCase(getNotificationSettings.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getNotificationSettings.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.notificationSettings = action.payload;
            })
            .addCase(getNotificationSettings.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(updateNotificationSettings.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateNotificationSettings.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.notificationSettings = action.payload;
            })
            .addCase(updateNotificationSettings.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(getHelpPortal.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getHelpPortal.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.helpPortal = action.payload;
            })
            .addCase(getHelpPortal.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(contactSupport.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(deleteInformationRequest.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            });
    },
});

export const { resetStatus } = settingsSlice.actions;
export default settingsSlice.reducer;
