import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getConditionExam } from "../../features/condition_exam/conditionExamSlice";
import "./condition_history.scss";
import { Link } from "react-router-dom";

const ConditionHistory = () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);

    const { condition_data, status, error } = useSelector(
        (state) => state.conditionExam
    );

    const isLoading = useMemo(
        () => status == "pending" || status == "idle",
        [status]
    );

    /* TODO: We can optionally move this into middleware */

    useEffect(() => {
        dispatch(getConditionExam(user.id));
    }, [dispatch]);

    if (isLoading) {
        return (
            <div className="loading-spinner-container">
                <div
                    style={{
                        height: "80px",
                        width: "80px",
                        borderWidth: "8px",
                    }}
                    className="loader"
                ></div>
            </div>
        );
    }

    return (
        <div className="condition-history-container">
            <h2 className="condition-history-title">Condition History</h2>
            <div className="btn-container">
                <Link to="/condition_exam/" className="go-back-btn">
                    <div className="go-back-text-container">
                        <p className="go-back-text">{"< Go Back"}</p>
                    </div>
                </Link>
                <div className="add-edit-container">
                    <Link
                        to="/condition_exam/add_conditions/"
                        className="add-edit-btn btn-primary btn"
                    >
                        <div className="add-edit-text-container">
                            <p className="add-edit-text btn-primary">Add</p>
                        </div>
                    </Link>
                    <Link
                        to="/condition_exam/current_conditions/"
                        className="add-edit-btn btn-primary btn"
                    >
                        <div className="add-edit-text-container">
                            <p className="add-edit-text">Edit</p>
                        </div>
                    </Link>
                </div>
            </div>
            <ul className="condition-history-list">
                {condition_data &&
                Object.entries(condition_data.mongo_data.condition_history)
                    .length ? (
                    Object.entries(
                        condition_data.mongo_data.condition_history
                    ).map(([timestamp, details], index) => (
                        <li
                            key={index}
                            className="card condition-history-item "
                        >
                            <ul className="card-details-list">
                                <p className="card-txt timestamp">
                                    <strong>Timestamp:</strong> {timestamp}
                                </p>
                                {Object.entries(details).map(
                                    ([conditionKey, conditionDetails], idx) => (
                                        <li key={idx} className="card-details">
                                            {conditionKey !== "action" && (
                                                <div>
                                                    <h5 className="card-title">
                                                        {conditionKey}
                                                    </h5>
                                                    <br />
                                                    <p className="card-txt">
                                                        <strong>Name:</strong>{" "}
                                                        {conditionDetails.name}
                                                    </p>
                                                    <br />
                                                    <p className="card-txt">
                                                        <strong>
                                                            Pain Scale:
                                                        </strong>{" "}
                                                        {
                                                            conditionDetails.pain_scale
                                                        }
                                                    </p>
                                                    <br />
                                                    <p className="card-txt">
                                                        <strong>Impact:</strong>{" "}
                                                        {
                                                            conditionDetails.impact
                                                        }
                                                    </p>
                                                </div>
                                            )}
                                            <br />
                                            {conditionKey === "action" && (
                                                <p className="card-txt action">
                                                    <strong>Action:</strong>{" "}
                                                    {conditionDetails}
                                                </p>
                                            )}
                                        </li>
                                    )
                                )}
                            </ul>
                        </li>
                    ))
                ) : (
                    <li>No condition history available.</li>
                )}
            </ul>
        </div>
    );
};

export default ConditionHistory;
