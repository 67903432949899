import {
    getCurrentCondition,
    updateCurrentCondition,
} from "../../features/condition_exam/conditionExamSlice";
import { useDispatch, useSelector } from "react-redux";

import React, { useEffect } from "react";
import SurveyComponent from "../../components/Survey/Survey";
import useAlert from "../../hooks/useAlert";
import { GoBack } from "../../components/MainUnlock";
import { replaceSpecialChars } from "../../../common/utils/helpers";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../common/utils/cookies";
import Alert from "../../../common/components/Alert";
import "./condition_exam.scss";

function CurrentCondition() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { alertType, alertMessage, alertVisible, showAlert, hideAlert } =
        useAlert();

    const { current_condition, status, error } = useSelector(
        (state) => state.conditionExam
    );

    useEffect(() => {
        dispatch(getCurrentCondition());
    }, [dispatch]);

    const onComplete = async (sender) => {
        const dataJSON = replaceSpecialChars(JSON.stringify(sender.data));
        const formData = new FormData();
        formData.append("csrfmiddlewaretoken", getCookie("csrftoken"));
        formData.append("resultData", dataJSON);

        dispatch(updateCurrentCondition(formData))
            .unwrap()
            .then((res) => {
                if (res.error) {
                    showAlert({
                        alertType: "error",
                        alertMessage: "Failed to complete survey " + res.error,
                    });
                } else {
                    navigate("/condition_exam/");
                }
            })
            .catch((error) => {
                showAlert({
                    alertType: "error",
                    alertMessage: "Failed to complete survey: " + error,
                });
            });
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (
        !current_condition ||
        !current_condition.conditions_json ||
        !current_condition.number_of_conditions ||
        !current_condition.current_conditions ||
        status === "pendingGetCurrentCondition" ||
        status === "pendingUpdateCurrentCondition"
    ) {
        return (
            <div className="loading-spinner-container">
                <div
                    style={{
                        height: "80px",
                        width: "80px",
                        borderWidth: "8px",
                    }}
                    className="loader"
                ></div>
            </div>
        );
    }

    return (
        <div id="current-conditions-survey">
            <GoBack />
            {alertVisible && <Alert type={alertType} message={alertMessage} />}
            <h3 className="blue-txt">Personal Condition Assessment</h3>
            <SurveyComponent
                currentDataJSON={current_condition.current_conditions}
                formDataJSON={structureSurveyJson(
                    current_condition.number_of_conditions,
                    JSON.parse(current_condition.conditions_json)
                )}
                lastPage={true}
                onComplete={onComplete}
                showProgressBar={false}
                addNavigationItem={(survey) => {
                    survey.addNavigationItem({
                        id: "sv-nav-finish-edit",
                        title: "Review",
                        action: () => {
                            survey.showPreview();

                            document.getElementById(
                                "sv-nav-finish-edit"
                            ).style.display = "none";
                        },
                        css: "nav-button",
                        innerCss: "sd-btn nav-input finish-edit-btn",
                    });
                }}
            />
        </div>
    );
}

//Grabs number of ratings from django form and creates a survey with that many text/ dropdown questions on one panel
function structureSurveyJson(numberOfConditions, conditions) {
    //Empty object to start
    let surveyData = {};
    //Set survey title
    surveyData.title = `Edit/ Review Current Conditions`;
    //Set to empty array of pages
    surveyData.pages = [];

    numberOfConditions = parseInt(numberOfConditions);
    for (let i = 1; i <= numberOfConditions; i++) {
        //Each new page is a panel that contains one text input for rating name
        //and one drop for laterality choices per rating needed for the paq
        let newPage = {
            name: `Page${i}`,
            elements: [
                {
                    type: "panel",
                    name: `panel${i}`,
                    elements: [
                        {
                            type: "dropdown",
                            name: `Condition #${i} Name`,
                            title: `Condition #${i} Title`,
                            isRequired: true,
                            choices: conditions,
                        },
                        {
                            type: "rating",
                            name: `Condition #${i} Pain Scale`,
                            rateCount: 10,
                            rateMax: 10,
                            rateType: "labels",
                            isRequired: true,
                        },
                        {
                            type: "comment",
                            name: `Condition #${i} Impact`,
                            description:
                                "Please share the ways in which your condition impacts your everyday life, such as work, social situations, mental and physical health issues and etc.",
                            isRequired: true,
                        },
                    ],
                },
            ],
        };
        //Push new page to the surveys page array
        surveyData.pages.push(newPage);
    }
    //Allow show summary
    surveyData.showPageNumbers = true;
    surveyData.widthMode = "responsive";
    return surveyData;
}

export default CurrentCondition;
