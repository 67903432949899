export const context = {
    changeDoctor: {
        title: "Are you sure you want to find another doctor?",
        instruction:
            "Changing the doctor will cancel the current appointment. Would you like to proceed?",
        yesButtonText: "Yes",
        noButtonText: "No",
    },
    cancelAppt: {
        title: "Are you sure you want to cancel your appointment?",
        instruction:
            "Selected time might no longer be available if you change your mind.",
        yesButtonText: "Cancel Appointment",
        noButtonText: "Go to Dashboard",
    },
};
