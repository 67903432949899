import React from "react";

import PeopleIcon from "@mui/icons-material/People";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SavingsIcon from "@mui/icons-material/Savings";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

export const AdminIconMap = [
    // TODO: get sidebar links from the backend
    {
        text: "Users",
        link: "/control-hub/users/",
        icon: <PeopleIcon />,
    },
    {
        text: "Medical History",
        link: "/control-hub/medical-history/",
        icon: <AssignmentIcon />,
    },
    {
        text: "Unpaid Check",
        link: "/control-hub/check-payments/",
        icon: <SavingsIcon />,
    },
    {
        text: "Refund Tickets",
        link: "/control-hub/refunds/tickets/",
        icon: <CurrencyExchangeIcon />,
    },
];
