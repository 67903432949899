import React from "react";
import Arrow from "../../images/ben-arrow-right.svg";
import Help from "../../images/help.svg";

const DisabilityInformation = ({ calculations }) => {
    return (
        <div className="card-sm">

            <div className="card-header">Disability Information</div>
            <div className="stat-container">
                <div className="stat-column-2">
                    <div className="stat-header stat-number-header">Your Current Rating</div>
                    <div className="stat-body">
                        <span className="stat-number">{calculations.current_rating}%</span>
                    </div>
                </div>
                <div className="stat-column-2 stat-column-end">
                    <div>
                        <Arrow />
                    </div>
                </div>
                <div className="stat-column-2 stat-column-end">
                    <div className="stat-header stat-number-header">Your Projected Rating</div>
                    <div className="stat-body">
                        <span className="stat-number">{calculations.projected_rating}%</span>
                    </div>
                </div>
            </div>
            <div className="stat-container">
                <div className="stat-column-2">
                    <div className="stat-header stat-number-header">Current Monthly Rate</div>
                    <div className="stat-body">
                        <span className="stat-txt">${calculations.current_monthly_benefit.toFixed(2)}</span>
                    </div>
                </div>
                <div className="stat-column-2 stat-column-end">
                    <div className="stat-header stat-number-header">Potential Monthly Rate</div>
                    <div className="stat-body">
                        <span className="stat-txt">${calculations.projected_monthly_benefit.toFixed(2)}</span>
                    </div>
                </div>
            </div>
            <div className="stat-container">
                <div className="diff-rate mx-auto">
                    <h1 className="diff-rate-txt">+ ${calculations.difference.toFixed(2)}</h1>
                    <span className="diff-rate-caption">Difference Rate</span>
                </div>
            </div>
            <footer>
                <div className="stat-container card-bottom">
                    <div className="stat-help">
                        <Help />
                        <a href="#">
                            <span className="stat-help-txt">How are these numbers calculated?</span>
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default DisabilityInformation;
