import React, { useEffect, useRef, useState } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { themeJson } from "../../../common/utils/survey_theme";
import "./Survey.scss";
import { createRoot } from "react-dom/client";

const SurveyComponent = ({
    currentDataJSON,
    formDataJSON,
    allData,
    onCurrentPageChanging,
    onComplete,
    onCurrentPageChanged,
    showProgressBar = true,
    addNavigationItem = null,
}) => {
    const [survey, setSurvey] = useState(null);
    const [progressValue, setProgressValue] = useState(0);
    const progressBarRootRef = useRef(null);

    useEffect(() => {
        const newSurvey = new Model(formDataJSON);
        newSurvey.focusFirstQuestionAutomatic = false;
        newSurvey.applyTheme(themeJson);
        newSurvey.css = {
            navigationButton: "btn primary"
        };
        const previousSurveyData = JSON.parse(currentDataJSON);

        if (previousSurveyData) {
            newSurvey.data = previousSurveyData;
            newSurvey.currentPageNo = previousSurveyData.pageNo;
        }

        // Add allData to the survey object
        newSurvey.allData = allData;

        onCurrentPageChanging &&
            newSurvey.onCurrentPageChanging.add(function (sender, options) {
                if (options.isNextPage) {
                    onCurrentPageChanging(sender);
                } else if (options.isPrevPage) {
                    sender.currentPage.questions.forEach(function (question) {
                        // reset the value of the question
                        question.value = undefined;
                    });
                }
            });

        onCurrentPageChanged &&
            newSurvey.onCurrentPageChanged.add(function (sender, options) {
                onCurrentPageChanged(sender, options);
                setProgressValue(sender.progressValue);
            });

        newSurvey.onComplete.add((survey) => {
            onComplete(survey);
        });
        // Set initial progress value
        setProgressValue(newSurvey.progressValue);
        setSurvey(newSurvey);
        addNavigationItem && addNavigationItem(newSurvey);
    }, [formDataJSON, currentDataJSON, allData]);

    useEffect(() => {
        const renderProgressBar = () => {
            const targetElement = document.querySelector(
                ".sv-components-column--expandable"
            );
            if (targetElement && !progressBarRootRef.current) {
                const progressBarContainer = document.createElement("div");
                progressBarContainer.className = "sv-progressbar-percentage";
                targetElement.insertBefore(
                    progressBarContainer,
                    targetElement.firstChild
                );

                progressBarRootRef.current = createRoot(progressBarContainer);
            }

            if (progressBarRootRef.current) {
                progressBarRootRef.current.render(
                    <ProgressBar value={progressValue} />
                );
            }
        };
        // Attempt to render immediately
        if (showProgressBar) {
            renderProgressBar();
        }
        // If the target element isn't available yet, try again after a short delay
        const timeoutId = showProgressBar
            ? setTimeout(renderProgressBar, 100)
            : null;

        return () => clearTimeout(timeoutId);
    }, [progressValue, showProgressBar]);

    return (
        <div className="survey-page-container">
            <div id="surveyContainer" className="survey-container">
                {survey && <Survey model={survey} />}
            </div>
        </div>
    );
};

const ProgressBar = ({ value }) => (
    <>
        <div
            className="sv-progressbar-percentage__indicator"
            style={{ marginRight: "1rem" }}
        >
            <div
                id="percentage-value-bar-el"
                className="sv-progressbar-percentage__value-bar"
                style={{ width: `${value}%` }}
            ></div>
        </div>
        <div className="sv-progressbar-percentage__value">
            <span id="percentage-value-el">{value}%</span>
        </div>
    </>
);

export default SurveyComponent;
