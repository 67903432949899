import React from "react";

const Notification = ({
    notification,
    isPast,
    archiveNotification,
    viewNotification,
}) => {
    return (
        <div className="single-notification-container">
            {!notification.archived && (
                <div className="notification-close">
                    <button
                        onClick={() =>
                            archiveNotification(notification.id, isPast)
                        }
                        className="close-notification"
                    >
                        <img
                            src="/static/notifications/img/close-btn.svg"
                            alt="red x button"
                        />
                    </button>
                </div>
            )}
            <div
                className={
                    isPast || notification.archived
                        ? "notif-icon-container-past"
                        : "notif-icon-container"
                }
            >
                <img
                    src={`/static/notifications/img/${notification.group}.svg`}
                    alt={`${notification.group} Icon`}
                />
            </div>
            <div
                className={
                    isPast || notification.archived
                        ? "notif-text-container-past"
                        : "notif-text-container"
                }
            >
                <div className="notification-title">
                    <b
                        className={
                            isPast || notification.archived ? "gray-txt" : ""
                        }
                    >
                        {notification.title}
                    </b>
                    <div className="timestamp-container">
                        <p>{notification.timestamp}</p>
                    </div>
                </div>
                <div className="notification-text-body">
                    {notification.text_body}
                </div>
                {notification.reference_link && (
                    <div className="notification-ref-link">
                        <a
                            href={notification.reference_link}
                            className={
                                isPast || notification.archived
                                    ? "gray-txt"
                                    : ""
                            }
                            onClick={() =>
                                viewNotification(
                                    notification.id,
                                    isPast,
                                    notification
                                )
                            }
                        >
                            View more details
                        </a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Notification;
