import React from "react";
import { Link } from "react-router-dom";

const About = () => {
    return (
        <>
            <div className="page-top-container">
                <img src="/static/settings/img/lock-icon-blue.png" alt="lock" />
                <h6 className="page-top-header">About VetVantage</h6>
            </div>

            <div className="page-middle">
                <div className="page-content settings-column">
                    <div className="content-container">
                        <ShieldCard />
                        <LogoCard />
                        <CopyrightCard />
                    </div>
                </div>
            </div>
        </>
    );
};

const ShieldCard = () => (
    <div className="card shield-card">
        <div className="card-item shield-item">
            <div className="shield-img-container">
                <img src="/static/settings/img/about_logo.svg" alt="Shield" />
            </div>
        </div>
        <div className="card-item text-item">
            <span className="grey-text">
                This site uses military-grade AES-256 encryption, and we
                regularly review our security practices to help ensure that your
                data is maintained securely. For more information click{" "}
                <Link to="/security-standards">here</Link>.
            </span>
        </div>
    </div>
);

const LogoCard = () => (
    <div className="card">
        <div className="card-item">
            <img
                src="/static/settings/img/about_logo.svg"
                alt="VetVantage Logo"
                className="vetvantage-logo"
            />
        </div>
        <div className="card-item card-column">
            <span className="grey-text">VetVantage is up to date</span>
            <span className="grey-text">Version 1.1 ( Official Build)</span>
        </div>
        <div className="card-item link">
            <Link to="/settings/manage/support/">
                <span>Contact support</span>&nbsp;&nbsp;
                <svg
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1 9L5 5L1 1"
                        stroke="#9A1F23"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </Link>
        </div>
    </div>
);

const CopyrightCard = () => (
    <div className="card">
        <div className="card-item card-column">
            <span className="grey-text">VetVantage</span>
            <span className="grey-text">
                Copyright 2023 VetVantage. All rights reserved.
            </span>
        </div>
        <div className="card-item link">
            <Link
                to="/release-of-information"
                target="_blank"
                rel="noopener noreferrer"
            >
                <span>Release Of Information</span>&nbsp;&nbsp;
                <svg
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1 9L5 5L1 1"
                        stroke="#9A1F23"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </Link>
        </div>
    </div>
);

export default About;
