import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { resetPassword, resetStatus, clearError } from "@/vetvantage/features/authSlice";
import FormField from "../../common/components/form/fields/FormField";
import Alert from "../../common/components/Alert";
import Logo from "../images/logo_white.svg";
import "./PasswordReset.scss";

const validationSchema = Yup.object({
    password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(/[!@#$%^&*(),.?":{}|<>]/, "Password must contain at least one special character")
        .required("Password is required"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
});

const PasswordResetForm = () => {
    const { token } = useParams();
    const dispatch = useDispatch();
    const error = useSelector((state) => state.auth.error);
    const status = useSelector((state) => state.auth.status);

    const [serverError, setServerError] = useState("");

    useEffect(() => {
        if (window.contextData?.errorMessage) {
            setServerError(window.contextData.errorMessage);
        }
        dispatch(resetStatus());
        dispatch(clearError());
    }, [dispatch]);

    if (serverError) {
        return (
            <div className="form-container">
                <header className="header">
                    <Link to={"/"} className="back-to-landing"><Logo /></Link>
                </header>
                <div className="body">
                    <h3>New Password</h3>
                    <p>
                        The password reset link was invalid, possibly because it has already been used. 
                        Please request <Link to={"/accounts/password/reset/"}>a new password reset.</Link>
                    </p>
                </div>
                <footer className="footer">
                </footer>
            </div>
        )
    }

    return (
        <div className="form-container">
            <header className="header">
                <Link to={"/"} className="back-to-landing"><Logo /></Link>
            </header>

            {status === "succeeded" ? (
                <>
                    <div className="body">
                        <h3>Password Reset</h3>
                        <p>
                            Password has been successfully changed.
                        </p>
                        <div className="button-row">
                            <Link to={"/accounts/login"} className="btn primary">
                                Return to Login
                            </Link>
                        </div>
                    </div>
                </>
            ) : status === "pending" ? (
                <div className="loading-spinner-container">
                    <div
                        style={{
                            height: '80px',
                            width: '80px',
                            borderWidth: '8px',
                        }}
                        className="loader"
                    ></div>
                </div>
            ) : (
                <>
                    {error && <Alert type="error" message={error} />}

                    <Formik
                        initialValues={{
                            password: "",
                            confirmPassword: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                            await dispatch(resetPassword({ values, token }));
                            setSubmitting(false);
                        }}
                    >
                        {({ isSubmitting, isValid, setTouched, validateForm }) => (
                            <div className="body">
                                <Form>
                                    <h3>Reset Password</h3>
                                    <div className="form-row">
                                        <div className="form-group">
                                            <FormField
                                                label="Password"
                                                name="password"
                                                type="password"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <FormField
                                                label="Confirm Password"
                                                name="confirmPassword"
                                                type="password"
                                            />
                                        </div>
                                    </div>
                                    <div className="button-row">
                                        <button
                                            type="submit"
                                            className="btn primary"
                                            disabled={isSubmitting || !isValid}
                                            onClick={async () => {
                                                setTouched({
                                                    password: true,
                                                    confirmPassword: true,
                                                });
                                                const errors = await validateForm();
                                                if (!errors.password && !errors.confirmPassword) {
                                                    // Proceed to submit
                                                }
                                            }}
                                        >
                                            Reset Password
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        )}
                    </Formik>
                </>
            )}
            <footer className="footer">
            </footer>
        </div>
    );
};

export default PasswordResetForm;
