import React from "react";
import { Link } from "react-router-dom";
import "./Notifications.scss";

const NotificationBase = ({ isArchived, children }) => (
    <div id="notifications-page">
    <div className="page-container">
        <div className="page-top">
            <div className="archived-btn-wrapper">
                <Link className="notification-title-container" to={isArchived ? "/notifications" : "/notifications/archived"}>
                    <h1>{isArchived ? "New Notifications >" : "Archived Notifications >"}</h1>
                </Link>
            </div>
        </div>
        <div className="page-middle">
            <div className="page-content">
                <div className="notifications-container">
                    {children}
                </div>
            </div>
        </div>
        <div className="page-end"></div>
    </div>
    </div>
);

export default NotificationBase;
