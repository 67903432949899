import { PDFDocument, StandardFonts } from "pdf-lib";
import FileSaver from "file-saver";

import { formatDate } from "../utils";

export const generatePDF = async (data, id, type = "transcript") => {
    try {
        const pdfDoc = await PDFDocument.create();
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
        var page = pdfDoc.addPage();
        const { width, height } = page.getSize();
        const { header, body, footer } = data;
        const margin = 35;
        const smallFontSize = 12;
        // create header title & details
        const lastItemY = createHeaderElements(
            header,
            page,
            width,
            height,
            margin,
            timesRomanFont,
            smallFontSize
        );
        // create body title & content
        createBodyElements(
            type,
            body,
            lastItemY,
            page,
            width,
            margin,
            timesRomanFont,
            smallFontSize,
            pdfDoc
        );
        const pdfBytes = await pdfDoc.save();
        return downloadPDF(pdfBytes, `${type}_${id}.pdf`);
    } catch (error) {
        console.error(error);
        return false;
    }
};

const createHeaderElements = (
    header,
    page,
    pageWidth,
    pageHeight,
    margin,
    font,
    smallFontSize
) => {
    // Define header title text and position
    const titleFontSize = 24;
    // Measure the text width of detail value
    const titleitleWidth = font.widthOfTextAtSize(header.title, titleFontSize);

    const title = {
        text: header.title,
        fontSize: titleFontSize,
        x: (pageWidth - titleitleWidth) / 2,
        y: pageHeight - 2 * titleFontSize,
    };

    // draw header title
    page.drawText(title.text, {
        x: title.x,
        y: title.y,
        size: title.fontSize,
        font: font,
    });

    var lastItemY;

    const headerDetails = JSON.parse(JSON.stringify(header.details));
    headerDetails.forEach((item, index) => {
        const marginY = (pageHeight - title.y) / 6 + index * 2;
        // format event date
        if (item.type === "date") {
            item.value = formatDate(item.value);
        }
        const detailName = {
            text: item.name,
            fontSize: smallFontSize,
            x: margin,
            y: pageHeight - marginY * smallFontSize,
        };
        // Measure the text width of detail name
        const nameWidth = font.widthOfTextAtSize(
            detailName.text,
            detailName.fontSize
        );
        const detailValue = {
            text: item.value,
            fontSize: smallFontSize,
            x: detailName.x + nameWidth + 5,
            y: pageHeight - marginY * smallFontSize,
        };
        page.drawText(detailName.text, {
            x: detailName.x,
            y: detailName.y,
            size: detailName.fontSize,
            font: font,
        });
        page.drawText(detailValue.text, {
            x: detailValue.x,
            y: detailValue.y,
            size: detailValue.fontSize,
            font: font,
        });
        // Measure the text width of detail value
        const valueWidth = font.widthOfTextAtSize(
            detailValue.text,
            detailValue.fontSize
        );
        // Draw the underline
        page.drawLine({
            start: { x: detailValue.x, y: detailValue.y - 2 },
            end: {
                x: detailValue.x + valueWidth,
                y: detailValue.y - 2,
            },
            thickness: 0.5,
        });
        // get last detail item height
        if (index + 1 === header.details.length) {
            lastItemY = detailValue.y;
        }
    });
    return lastItemY;
};

const createBodyElements = (
    type,
    body,
    lastItemY,
    page,
    pageWidth,
    margin,
    font,
    fontSize,
    pdfDoc
) => {
    // Define event body title text and position
    const bodyTitleFontSize = 18;
    // Measure the text width of detail value
    const bodyTitleWidth = font.widthOfTextAtSize(
        body.title,
        bodyTitleFontSize
    );
    const bodyTitle = {
        text: body.title,
        fontSize: bodyTitleFontSize,
        x: (pageWidth - bodyTitleWidth) / 2,
        y: lastItemY - 40,
    };
    // draw body title
    page.drawText(bodyTitle.text, {
        x: bodyTitle.x,
        y: bodyTitle.y,
        size: bodyTitle.fontSize,
        font: font,
    });
    // Define event body content text and position
    const contentArr =
        type === "summary" ? [body.content] : body.content.transcript;
    var nextItemY = bodyTitle.y - margin;
    contentArr.forEach((obj) => {
        const bodyContent = {
            text: type === "summary" ? obj : `${obj.displayName}: ${obj.text}`,
            fontSize: fontSize,
            x: margin,
            y: nextItemY,
        };
        const lineHeight = fontSize * 1.2;
        const maxWidth = pageWidth - 2 * margin;

        // Split the text into lines
        const lines = splitTextIntoLines(
            bodyContent.text,
            font,
            fontSize,
            maxWidth
        );
        // Draw each line of text
        let y = bodyContent.y;
        lines.forEach((line) => {
            if (y < margin) {
                page = pdfDoc.addPage();
                y = page.getHeight() - margin;
            }
            page.drawText(line, {
                x: bodyContent.x,
                y: y,
                size: fontSize,
                font: font,
            });
            y -= lineHeight;
        });
        nextItemY = y - 5;
    });
};

const downloadPDF = (pdfBytes, fileName) => {
    const byteArray = new Uint8Array(pdfBytes);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    FileSaver(url, fileName);
    return true;
};

const splitTextIntoLines = (text, font, fontSize, maxWidth) => {
    const words = text.split(" ");
    const lines = [];
    let line = "";

    words.forEach((word) => {
        const testLine = line + word + " ";
        const testWidth = font.widthOfTextAtSize(testLine, fontSize);
        if (testWidth > maxWidth && line !== "") {
            lines.push(line.trim());
            line = word + " ";
        } else {
            line = testLine;
        }
    });

    lines.push(line.trim());
    return lines;
};
